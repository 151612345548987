import { combineReducers } from 'redux';
import { HomeReducer } from './HomeReducer';
import { AboutUsReducer } from './AboutUsReducer';
import { PrivacyPolicyReducer } from './PrivacypolicyReducer';
import { ReturnPolicyReducer } from './ReturnpolicyReducer';
import { TermsConditionReducer } from './TermsConditionReducer';
// import {ProductReducer,LastProductReducer} from './ProductReducer';
import CombineProductReducer from './Product/CombineProductReducer';
import { HeaderReducer } from './HeaderReducer'
import { productdetailReducer } from './productdetailReducer'
import { HelpSupportReducer } from './HelpSupportReducer';
import { CategoriesReducer } from './CategoriesReducer';
import { cartReducer } from './CartReducer';
import { OurTeamReducer } from './OurTeamReducer';
import { LoginReducer } from './LoginReducer';
import { UserValidateAccountReducer } from './UserValidateAccountReducer';
import { RegisterReducer } from './RegisterReducer';
import { ForgotpasswordReducer } from './ForgotpasswordReducer';
import { RequestForQuotationReducer } from './RequestForQuotationReducer';
import  CombineProjectReducer  from './OurProjects/CombineProjectsReducer';
import { FavoriteProductReducer } from './FavoriteProductReducer';
import { ProfileReducer } from './ProfileReducer';
import { MyQuotationReducer } from './MyQuotationReducer';
import { DocumentRequestReducer } from './DocumentRequestReducer';
const reducer = combineReducers({
    allHomes: HomeReducer,
    allAboutus: AboutUsReducer,
    allPrivacyPolicy: PrivacyPolicyReducer,
    allReturnpolicy: ReturnPolicyReducer,
    allTermscondition: TermsConditionReducer,
    allProductReducer: CombineProductReducer,
    allHeader: HeaderReducer,
    allProductdetailReducer: productdetailReducer,
    allHelpsupport: HelpSupportReducer,
    allCategories: CategoriesReducer,
    allOurTeam: OurTeamReducer,
    cart: cartReducer,
    Login: LoginReducer,
    userValidate: UserValidateAccountReducer,
    register: RegisterReducer,
    forgotpassword: ForgotpasswordReducer,
    requestQuotation: RequestForQuotationReducer,
    ourProjectReducer: CombineProjectReducer,
    favoriteProduct:FavoriteProductReducer,
    profile:ProfileReducer,
    myQuotation:MyQuotationReducer,
    documentRequest:DocumentRequestReducer
    // LastProductReducer :LastProductReducer
});
export default reducer;

