export const resources = {
    PRODUCT_IMG_URL: "Images/Products/",
    CATEGORIES_IMG_URL: "Images/categories/",
    USER_IMG_URL: "Images/Users/",
    PROJECT_IMG_URL: "Images/Projects/",
    HOMESLIDER_IMG_URL: "Images/homesliders/",
    CLIENT_IMG_URL: "Images/clients/",
    OURTEAM_IMG_URL: "Images/ourTeam/",
    IMAGEVERSIONS: {
        LARGEIMAGERESIZER: "L_",
        MEDIUMIMAGERESIZER: "M_",
        SMALLIMAGERESIZER: "S_",
        THUMBNAILIMAGERESIZER: "T_"
    }
}
