import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

export const fetchHomePageData = (

) => async dispatch => {
    try {
        dispatch({ type: ActionTypes.HOME_LOADER_START });
        const resultObject = await api.get("HomeSlider/GetHomeSliderWeb")
        dispatch({
            type: ActionTypes.FETCH_HOMEDATA,
            homeData: resultObject.data.ResultObject
        })
    }
    catch (e) {
        // console.error('Error in get all exception log for home', e, e.errorObject)
        dispatch({
            type: ActionTypes.FETCH_HOMEDATA,
            homeExceptionLogObject: []
        })
    }
}