import React, { useEffect } from 'react';
import $ from 'jquery';

const StatisticsCounterComponent = () => {
    var viewed = false;
    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
    function CounterScroll() {
        if (isScrolledIntoView($(".stat-counter-wrap")) && !viewed) {
            viewed = true;
            $('.counter-number').each(function () {
                $(this).prop('Counter', 0).animate({
                    Counter: $(this).text()
                }, {
                    duration: 4000,
                    easing: 'swing',
                    step: function (now) {
                        $(this).text(Math.ceil(now));
                    }
                });
            });
        }
    }

    useEffect(()=>{
        window.removeEventListener('scroll', CounterScroll);
        window.addEventListener('scroll',CounterScroll, { passive: true });
        return () => window.removeEventListener('scroll', CounterScroll);
    },[]);

    return (
        <>
            <section class="stat-counter-wrap">
                <div class="stat-counter wrapper">
                    <div class="counter counter-border" data-aos="zoom-in">
                        <p class="counter-number">10</p>
                        <p class="counter-text">Years of Experience</p>
                        <img src="/assets/images/icons8-chainsaw-50.png" alt="" height="50" width="50" />
                    </div>
                    <div class="counter counter-border" data-aos="zoom-in" data-aos-delay="50">
                        <p class="counter-number">485</p>
                        <p class="counter-text">Satisfied clients</p>
                        <img src="/assets/images/users-solid.svg" alt="" height="50" width="50" />
                    </div>
                    <div class="counter counter-border" data-aos="zoom-in" data-aos-delay="100">
                        <p class="counter-number">200</p>
                        <p class="counter-text">Project Completed</p>
                        <img src="/assets/images/couch-solid.svg" alt="" height="50" width="50" />
                    </div>
                    <div class="counter" data-aos="zoom-in" data-aos-delay="150">
                        <p class="counter-number">45</p>
                        <p class="counter-text">Awards Recieved</p>
                        <img src="/assets/images/certificate-solid.svg" alt="" height="50" width="50" />
                    </div>
                </div>
            </section>
        </>
    )
}
export default StatisticsCounterComponent;