import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { fetchHome,fetchHomePageData } from '../../redux/actions/homeActions'
import { fetchHomePageData } from '../../redux/actions/homeActions';
import HomeComponet from './HomeComponet'
import ProductComponet from './ProductComponets';
import CategoryComponet from './CategoryComponet';
import AboutComponent from './AboutComponent';
// import ClientComponet from './ClientComponent';
import TestimonialComponent from './TestimonialComponent';
import StatisticsCounterComponent from './StatisticsCounterComponent';
import AOS from 'aos';
import "aos/dist/aos.css";


import slider from '../Shared/home/home'
const HomeListing = ({
    fetchHomePageData,
    fetchingHomeData,
    sliderData,
    Productdata,
    Categorydata,
    AboutUsData,
    ClientData,
    TestimonialsData,
    loader
}) => {
    console.log(Categorydata);
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchHomePageData();
        slider();
        AOS.init({
            once: true,
        });
        AOS.refresh();
    }, []);

    return (
        <div>
            <HomeComponet sliderData={sliderData} loader={loader} />
            <AboutComponent AboutUsData={AboutUsData} />
            {/* <ProductComponet Productdata={Productdata} loader={loader} /> */}
            <CategoryComponet Categorydata={Categorydata} loader={loader} />
            <StatisticsCounterComponent></StatisticsCounterComponent>
            <TestimonialComponent TestimonialsData={TestimonialsData} />
            {/* <ClientComponet ClientData={ClientData}/> */}
        </div>
    )
}
export default connect((state, props) => (
    {
        fetchingHomeData: state.allHomes.homeData != null ? state.allHomes.homeData : null,
        sliderData: state.allHomes.homeData != null ? state.allHomes.homeData.HomeSliderWeb : null,
        Productdata: state.allHomes.homeData != null ? state.allHomes.homeData.ProductForWeb : null,
        Categorydata: state.allHomes.homeData != null ? state.allHomes.homeData.CategoryForWeb : null,
        AboutUsData: state.allHomes.homeData != null ? state.allHomes.homeData.CMSForWeb.Content.substring(0, 1500) : null,
        // ClientData: state.allHomes.homeData  != null ? state.allHomes.homeData.ClientForWeb : null,
        TestimonialsData: state.allHomes.homeData != null ? state.allHomes.homeData.TestimonialForWeb : null,
        loader: state.allHomes.loading
    }),
    { fetchHomePageData }
)(HomeListing)
