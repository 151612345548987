import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';
export const fetchTeams = (

) => async dispatch => {
    try {
        const resultObject = await api.get("OurTeam/GetTeamsForWeb");
        dispatch({
            type: ActionTypes.FETCH_TEAM,
            payload: resultObject.data.ResultObject
        })
    }
    catch (e) {
        dispatch({
            type: ActionTypes.FETCH_TEAM,
            homeExceptionLogObject: []
        })
    }
}