import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchHelpsupport } from '../../redux/actions/helpsupportActions';
import HelpSupportComponet from './HelpSupportComponet';

const Helpsupportdata = (
    {
        fetchHelpsupport,
        fetchHelpsupportPageData,
    }) => {
    useEffect(() => {
        window.scrollTo(0,0);
        fetchHelpsupport();
    },[])
    return (
        fetchHelpsupportPageData != null ? <HelpSupportComponet fetchHelpsupportPageData={fetchHelpsupportPageData} /> : null
    )
}
export default connect((state, props) => (
    {
        fetchHelpsupportPageData: state.allHelpsupport.payload != null ? state.allHelpsupport.payload : null,
    }),
    { fetchHelpsupport }
)(Helpsupportdata)