import { ActionTypes } from '../constants/action-types';
import { isEmpty } from 'lodash';
export const addToCart = (product, customizeDetailJson) => (dispatch, getState) => {
  let alreadyExists = false;
  let rowNumber = 0;
  // const cartItems = getState().allProductdetailReducer.productdetail[0].ProductsForWeb.slice();
  const cartItems = getState().cart.cartItems.slice();
  if (cartItems.length > 0)
    rowNumber = Object.values(cartItems)[Object.values(cartItems).length - 1].rowNumber
  const cartListTrue = cartItems.filter(x => x.isCustomize == true && x.ProductID == product.ProductID && JSON.stringify(x.customizeData) == JSON.stringify(customizeDetailJson));
  const cartListfalse = cartItems.filter(x => x.isCustomize == false && x.ProductID == product.ProductID && JSON.stringify(x.customizeData) == JSON.stringify(customizeDetailJson));
  if (cartItems.length === 0) {
    if (isEmpty(customizeDetailJson)) {
      product["isCustomize"] = false;
    } else {
      product["isCustomize"] = true;
    }
    product["rowNumber"] = rowNumber + 1;
    product["customizeData"] = customizeDetailJson;
    cartItems.push({ ...product, count: 1 });
  }
  else if (cartListTrue.length > 0) {
    cartListTrue[0].count = cartListTrue[0].count + 1;
  } else if (cartListfalse.length > 0) {
    cartListfalse[0].count = cartListfalse[0].count + 1;
  }
  else if (cartListTrue.length == 0) {
    product["rowNumber"] = rowNumber + 1;
    product["isCustomize"] = true;
    product["customizeData"] = customizeDetailJson;
    cartItems.push({ ...product, count: 1 });
  }
  else if (cartListfalse.length == 0) {
    product["rowNumber"] = rowNumber + 1;
    product["isCustomize"] = false;
    product["customizeData"] = customizeDetailJson;
    cartItems.push({ ...product, count: 1 });
  }

  // cartItems.forEach((x) => {
  //   if (x.ProductID === product.ProductID) {
  //     // if (!isEmpty(customizeDetailJson)) {
  //     if (isEqual(x.customizeData, customizeDetailJson)) {
  //       alreadyExists = true;
  //       x.count++;
  //       return false;
  //     }
  //     else if (isEmpty(x.customizeData) && isEmpty(customizeDetailJson)) {
  //       alreadyExists = true;
  //       x.count++;
  //       return false;
  //       // cartItems.push({ ...product, count: 1 });
  //     }
  //     else if (!isEmpty(x.customizeData) && !isEmpty(customizeDetailJson)) {
  //       product["customizeData"] = customizeDetailJson;
  //       product["RowNumber"] = 1;
  //       cartItems.push({ ...product, count: 1 });
  //       return false;

  //       // x.count++;
  //     }
  //     // else if (!isEmpty(x.customizeData) && isEmpty(customizeDetailJson)) {
  //     //   product["customizeData"] = customizeDetailJson;
  //     //   product["RowNumber"] = 1;
  //     //   cartItems.push({ ...product, count: 1 });
  //     //   return true;
  //     //   // x.count++;
  //     // }
  //     // else if (!isEmpty(x.customizeData) && !isEmpty(customizeDetailJson)) {
  //     //   x.count = x.count + 1;
  //     // }
  //     else {
  //       // alreadyExists = false;
  //       // product["customizeData"] = customizeDetailJson;
  //       // product["RowNumber"] = 1;
  //       // cartItems.push({ ...product, count: 1 });
  //       x.count++;
  //       return false;
  //     }
  //     // } else {
  //     //   cartItems[x.ProductID]["customizeData"] = {}
  //     // }
  //   }
  //   else {
  //     // product["customizeData"] = customizeDetailJson;
  //     // cartItems.push({ ...product, count: 1 });
  //     alreadyExists = true;
  //     x.count++;

  //     return false;
  //   }
  // }
  // );

  dispatch({ type: ActionTypes.ADD_TO_CART_LOADER });
  dispatch({
    type: ActionTypes.ADD_TO_CART,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

export const AddQuantity = (ProductID) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  cartItems.forEach((x) => {
    if (x.rowNumber === ProductID) {
      x.count++;
    }
  });
  dispatch({
    type: ActionTypes.ADD_QUANTITY,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
}
export const OnTextChangeUpdateQuantity = (ProductID, quantity) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  cartItems.forEach((x) => {
    if (x.rowNumber === ProductID) {
      x.count = quantity;
    }
  });
  dispatch({
    type: ActionTypes.ADD_QUANTITY,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
}

export const SubtractQuantity = (ProductID) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  cartItems.forEach((x) => {
    if (x.rowNumber === ProductID) {
      if (x.count === 1) {

      } else {
        x.count--;
      }
    }
  });
  dispatch({
    type: ActionTypes.ADD_QUANTITY,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
}

export const removeFromCart = (ProductID) => (dispatch, getState) => {
  const cartItems = getState()
    .cart.cartItems.slice()
    .filter((x) => x.rowNumber !== ProductID);
  dispatch({ type: ActionTypes.REMOVE_FROM_CART, payload: { cartItems } });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

export const ResetCartItem = () => (dispatch) => {
  dispatch({ type: ActionTypes.RESET_CARTITEM });
};
export const AddToCartResetSuccess = () => (dispatch) => {
  dispatch({ type: ActionTypes.ADD_TO_CART_RESET_SUCCESS });
};