import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { MyQuotaionListByUserID, GetPartialDetailByID } from '../../redux/actions/myQuotationAction';
import moment from 'moment';
import { Link } from "react-router-dom";
import { IMAGE_ROOT } from '../../api-config';
import MyRequestQuotationDetail from './MyRequestQuotationDetailComponent';
import { QuotationDetailToggle } from '../Shared/custom';
import DocumentRequestComponent from './DocumentRequestComponent';
import { GetByQuotationRequestID } from '../../redux/actions/documentRequestAction';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
import { resources } from '../../resource';

const MyQuotationHistory = (
    {
        isLoggedIn, MyQuotaionListByUserID, MyQuotaionByUserIDList, GetPartialDetailByID, QuotationPartialDetail, DocumentRequestList, GetByQuotationRequestID, loading

    }) => {
    const history = useHistory();
    let param = useParams();
    const user = JSON.parse(localStorage.getItem("user"));
    const [isCurrentActiveList, setIsCurrentActiveList] = useState();
    const [isRequestQuotationToggle, setIsRequestQuotationToggle] = useState(false);
    const [isDocumentRequestToggle, setIsDocumentRequestToggle] = useState(false);
    let isExpanded = false;
    let DocumentData;
    // useEffect(() => {
    //     MyQuotaionListByUserID(user.UserID);
    // }, []);
    // useEffect(() => {
    //     if (isLoggedIn === false) {
    
    //         history.push("/")
    //     }
    // }, [isLoggedIn])
    useEffect(() => {
        if (!isLoggedIn) {
            history.push("/");
        } else if (isLoggedIn) {
            MyQuotaionListByUserID(user.UserID, param != null || param != undefined ? param.slug : null);
        }

    }, [isLoggedIn, history])
    useEffect(() => {
        if (MyQuotaionByUserIDList != null && MyQuotaionByUserIDList.length > 0) {
            setIsCurrentActiveList(MyQuotaionByUserIDList[0].QuotationRequestID);
            GetPartialDetailByID(MyQuotaionByUserIDList[0].QuotationRequestID);
            GetByQuotationRequestID(MyQuotaionByUserIDList[0].QuotationRequestID, user.UserID);
        }
    }, [MyQuotaionByUserIDList]);

    const quoteDetailToggle = (quotationRequestId) => {
        GetPartialDetailByID(quotationRequestId)
        setIsCurrentActiveList(quotationRequestId);
        GetByQuotationRequestID(quotationRequestId, user.UserID);
    }
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
        if (isReadMore === false) {
            window.scrollTo(0, 0)
        }
    };

    const downloadDocumentFile = (pdfFilePath) => {
        window.open(
            pdfFilePath,
            '_blank' // <- This is what makes it open in a new window.
        );
    }
    const showMoreAndless = (e) => {
        if (e.currentTarget.parentElement.querySelector('.expand').style.display == "none") {
            e.currentTarget.parentElement.querySelector('.collapse').style.display = "none";
            e.currentTarget.parentElement.querySelector('.expand').style.display = "block";
            e.currentTarget.parentElement.querySelector('.read-or-hide').text = "show less";
        } else if (e.currentTarget.parentElement.querySelector('.expand').style.display == "block") {
            e.currentTarget.parentElement.querySelector('.collapse').style.display = "block";
            e.currentTarget.parentElement.querySelector('.expand').style.display = "none";
            e.currentTarget.parentElement.querySelector('.read-or-hide').text = "read more";
        }
    }
    
    var DocumentFileurls = [];
    const download = url => {
        return fetch(url).then(resp => resp.blob());
    }
    const downloadMany = urls => {
        return Promise.all(urls.map(url => download(url)))
    }
    const exportZip = blobs => {
        const zip = JSZip();
        blobs.forEach((blob, i) => {
            zip.file(DocumentFileurls[i].split('/')[DocumentFileurls[i].split('/').length -1], blob);
        });
        zip.generateAsync({ type: 'blob' }).then(zipFile => {
            const fileName = 'quotationRequest-attachment.zip';
            return saveAs(zipFile, fileName);
        });
    }
    const downloadAndZipDocumentFile = () => {
        DocumentData.map((data, key) => {

            DocumentFileurls.push(IMAGE_ROOT + "Documents/DocumentRequest/" + data);
        })
        return downloadMany(DocumentFileurls).then(exportZip);
    }
   
    return (
        <>

            {isRequestQuotationToggle && (<MyRequestQuotationDetail isRequestQuotationToggle={isRequestQuotationToggle} setIsRequestQuotationToggle={setIsRequestQuotationToggle} RequestQuotationID={isCurrentActiveList} />)}
            {isDocumentRequestToggle && (<DocumentRequestComponent isDocumentRequestToggle={isDocumentRequestToggle} setIsDocumentRequestToggle={setIsDocumentRequestToggle} quotationRequestID={isCurrentActiveList} userID={user.UserID} />)}
            <section className="myQuote">
                {(() => {
                    if (MyQuotaionByUserIDList != null && MyQuotaionByUserIDList.length > 0 && !loading) {


                        return (

                            <section className="wrapper">


                                <section className="quotes" id="quoteListing">

                                    <h1>
                                        <span>Your Quote</span>
                                        {/* <a href="#">Add Quote</a> */}
                                    </h1>
                                    <ul style={{ display: 'block' }}>
                                        {(() => {
                                            if (MyQuotaionByUserIDList != null && MyQuotaionByUserIDList.length > 0) {
                                                return (
                                                    <>
                                                        {MyQuotaionByUserIDList.map((data, key) => {
                                                            return (
                                                                <li key={Math.random()} className={isCurrentActiveList == data.QuotationRequestID ? 'currentActive' : ''}>
                                                                    <Link onClick={() => { quoteDetailToggle(data.QuotationRequestID); QuotationDetailToggle() }} ></Link>
                                                                    {/* <a onclick={()=>{quoteDetailToggle(data.QuotationRequestID)}}></a> */}
                                                                    <h3>
                                                                        <strong>{moment(data.CreatedDate).format('DD, MMM YYYY h:mm a')}</strong>
                                                                        {data.StatusType === "Pending" &&
                                                                            <h2>
                                                                                <span className="quoteStatus" style={{ background: '#FFF6A6' }} >Pending</span>
                                                                            </h2>
                                                                        }
                                                                        {data.StatusType === "Replied" &&
                                                                            <h2>
                                                                                <span className="quoteStatus" style={{ background: '#B7CFFF' }} >Replied</span>
                                                                            </h2>
                                                                        }
                                                                        {data.StatusType === "Ordered" &&
                                                                            <h2>
                                                                                <span className="quoteStatus" style={{ background: '#A3FBD1' }} >Ordered</span>
                                                                            </h2>
                                                                        }
                                                                        {data.StatusType === "Rejected" &&
                                                                            <h2>
                                                                                <span className="quoteStatus" style={{ background: '#FFC5C5' }} >Rejected</span>
                                                                            </h2>
                                                                        }
                                                                    </h3>
                                                                    <div>
                                                                        <span>{data.ProductCount} Products</span>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })

                                                        }
                                                    </>
                                                )
                                            }

                                        })()}

                                    </ul>

                                </section>


                                <section className="quoteDetail" id="quoteDetail">


                                    {(() => {
                                        if (QuotationPartialDetail != null && user != null) {
                                            return (
                                                <>
                                                    <section className="currentQuote">
                                                        <div className="avtar">
                                                            <img src={IMAGE_ROOT + resources.USER_IMG_URL + user.ProfileImage} className="pic" onError={(e) => (e.target.onerror = null, e.target.src = "/assets/images/user-default-image.jpg")} />
                                                        </div>

                                                        <div className="info">
                                                            <h3>
                                                                <span>{moment(QuotationPartialDetail.CreatedDate).format('DD, MMM YYYY h:mm a')}</span>

                                                                <Link onClick={() => { QuotationDetailToggle() }} style={{ display: 'none' }}>Back</Link>

                                                            </h3>
                                                            {QuotationPartialDetail.StatusType === "Pending" &&

                                                                <span className="quoteStatus" style={{ background: '#FFF6A6' }} >Pending</span>

                                                            }
                                                            {QuotationPartialDetail.StatusType === "Replied" &&

                                                                <span className="quoteStatus" style={{ background: '#B7CFFF' }} >Replied</span>

                                                            }
                                                            {QuotationPartialDetail.StatusType === "Ordered" &&

                                                                <span className="quoteStatus" style={{ background: '#A3FBD1' }} >Ordered</span>

                                                            }
                                                            {QuotationPartialDetail.StatusType === "Rejected" &&

                                                                <span className="quoteStatus" style={{ background: '#FFC5C5' }} >Rejected</span>

                                                            }
                                                            <label>{QuotationPartialDetail.ProductCount} Products</label>
                                                            {/* <label>$175.00</label> */}
                                                        </div>

                                                        <div className="btn-group">
                                                            <a className="btn"></a>
                                                            <div className="dropdown">
                                                                <a onClick={() => { setIsRequestQuotationToggle(true); quoteDetailToggle(QuotationPartialDetail.QuotationRequestID) }} href="javascript:void(0);"><i className="edit"><img src="/assets/images/view_icon.svg" /></i>View Detail</a>

                                                                {QuotationPartialDetail && (QuotationPartialDetail.StatusType != 'Pending' && QuotationPartialDetail.StatusType != 'Rejected'
                                                                    ? <a onClick={() => { downloadAndZipDocumentFile() }} href="javascript:void(0);"><i className="edit"><img src="/assets/images/download-file.svg" /></i>Download Documents</a> : ''

                                                                )}

                                                            </div>
                                                        </div>
                                                    </section>



                                                    <section className='showAdminReplayMsg' >
                                                        {(() => {
                                                            if (QuotationPartialDetail.ReplyMessage != null) {
                                                                return (
                                                                    <>
                                                                        <div className='card'>
                                                                            <div className="from"><img src="/assets/images/unimaple_sales_team_user_logo.png" /></div>
                                                                            <div className="message">
                                                                                <h4>
                                                                                    <strong>Administration Reply</strong>
                                                                                    <span>{QuotationPartialDetail.ReplyDate != null || QuotationPartialDetail.ReplyDate != undefined ? moment(QuotationPartialDetail.ReplyDate).format('DD, MMM YYYY h:mm a') : ' '}</span>
                                                                                </h4>
                                                                                <p
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: QuotationPartialDetail.ReplyMessage != null ? isReadMore ? QuotationPartialDetail.ReplyMessage.slice(0, 350) : QuotationPartialDetail.ReplyMessage : 'Wait For Administration Reply',
                                                                                    }}
                                                                                ></p>
                                                                                {/* <p>{QuotationPartialDetail.ReplyMessage != null ? isReadMore ? QuotationPartialDetail.ReplyMessage.slice(0, 150) : QuotationPartialDetail.ReplyMessage : 'Wait For Administere Reply'} </p> */}
                                                                                <a onClick={toggleReadMore} className="read-or-hide">
                                                                                    {isReadMore ? "read more" : " show less"}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            } else if (QuotationPartialDetail.ReplyMessage == null) {
                                                                return (
                                                                    <>
                                                                        <div className="card" style={{ backgroundColor: "#e5e5e5", height: '95px', alignItems: 'center', fontSize: '15px' }}>
                                                                            <div className="from"></div>
                                                                            <div className="message">
                                                                                <h4>
                                                                                    <strong style={{ color: '#5a5656' }}>Waiting For Administration Reply</strong>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                        })()}

                                                    </section>
                                                </>
                                            )
                                        } else if (QuotationPartialDetail == null) {
                                            // loader 
                                            return (
                                                <>
                                                    <section className="currentQuote">
                                                        <div className="avtar">
                                                            <Skeleton style={{ display: 'block', width: '45px', height: '45px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} >
                                                            </Skeleton>
                                                        </div>

                                                        <div className="info">
                                                            <h3>
                                                                <Skeleton height={20} width={500} />
                                                            </h3>
                                                            <Skeleton height={10} width={150} />
                                                            <Skeleton height={10} width={150} style={{ marginLeft: '10px' }} />
                                                        </div>
                                                        <div class="btn-group">
                                                            <Skeleton style={{ display: 'block', width: '30px', height: '30px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} />
                                                        </div>
                                                    </section>
                                                    <section className='showAdminReplayMsg' >
                                                        <div className='card'>
                                                            <div className="from">
                                                                <Skeleton style={{ display: 'block', width: '45px', height: '45px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} >
                                                                </Skeleton>
                                                            </div>
                                                            <div className="message">
                                                                <h4>
                                                                    <strong><Skeleton height={20} width={500} /></strong>
                                                                    <Skeleton height={10} width={150} />
                                                                </h4>
                                                                <Skeleton count={2} height={10} width={150} />


                                                            </div>
                                                        </div>
                                                    </section>
                                                </>
                                            )
                                        }
                                    })()}





                                    <section className="quoteHistory" style={{ display: 'block' }}>

                                        {(() => {
                                            if (DocumentRequestList != null && DocumentRequestList.length > 0 && user != null) {

                                                return (
                                                    <>
                                                        <h3>Your quote history</h3>
                                                        <ul>
                                                            {
                                                                DocumentRequestList.map((data, key) => {
                                                                    DocumentData = data.Document != null ? data.Document.split(',') : 0;

                                                                    return (
                                                                        <>
                                                                            <li key={key}>

                                                                                <div className="card">
                                                                                    <div className="from"><img src={IMAGE_ROOT + resources.USER_IMG_URL + user.ProfileImage} className="pic" onError={(e) => (e.target.onerror = null, e.target.src = "/assets/images/user-default-image.jpg")} /></div>
                                                                                    <div className="message">
                                                                                        <h4>
                                                                                            <strong>Request Submitted</strong>
                                                                                            <span>{moment(data.QuestionReplyDate).format('DD, MMM YYYY h:mm a')}</span>
                                                                                        </h4>
                                                                                        <p>{data.Message}</p>
                                                                                    </div>
                                                                                </div>

                                                                                <ul>
                                                                                    <li>

                                                                                        {(() => {
                                                                                            if (data.MessageReply != null) {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div className="card">
                                                                                                            <div className="from"><img src="/assets/images/unimaple_sales_team_user_logo.png" /></div>
                                                                                                            <div className="message">
                                                                                                                <h4>
                                                                                                                    <strong>Reply From Sales Team</strong>
                                                                                                                    <span>{data.AnswerReplyDate != null || data.AnswerReplyDate != undefined ? moment(data.AnswerReplyDate).format('DD, MMM YYYY h:mm a') : ' '}</span>
                                                                                                                </h4>

                                                                                                                <p className='collapse' dangerouslySetInnerHTML={{
                                                                                                                    __html: data.MessageReply.slice(0, 350)
                                                                                                                }}></p>

                                                                                                                <p className='expand' style={{ display: 'none' }} dangerouslySetInnerHTML={{
                                                                                                                    __html: data.MessageReply
                                                                                                                }}></p>

                                                                                                                <a onClick={(e) => {
                                                                                                                    showMoreAndless(e)
                                                                                                                }} className="read-or-hide">
                                                                                                                    {!isExpanded ? "read more" : " show less"}
                                                                                                                </a>

                                                                                                                {/* <p dangerouslySetInnerHTML={{
                                                                                                                __html: expanded === key ? data.MessageReply.slice(0, 350) : data.MessageReply,
                                                                                                            }}></p>
                                                                                                            <a onClick={(e) => { setExpanded(expanded === key ? -1 : key) }} className="read-or-hide">
                                                                                                                {expanded === key ? "read more" : " show less"}
                                                                                                            </a> */}
                                                                                                                <div className="attachments">

                                                                                                                    {
                                                                                                                        (DocumentData != null && DocumentData.length > 0) &&
                                                                                                                        DocumentData.map((documentFileName, key) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <a onClick={() => { downloadDocumentFile(IMAGE_ROOT + "Documents/DocumentRequest/" + documentFileName) }} target="_blank"><img src="/assets/images/document.svg" style={{ width: '50px' }} /></a>

                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            } else if (data.MessageReply == null) {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div className="card" style={{ backgroundColor: "#e5e5e5" }}>
                                                                                                            <div className="from"></div>
                                                                                                            <div className="message">
                                                                                                                <h4>
                                                                                                                    <strong style={{ color: '#5a5656' }}>Waiting For Administration Reply</strong>
                                                                                                                </h4>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        })()}



                                                                                    </li>
                                                                                </ul>

                                                                            </li>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </ul>
                                                    </>
                                                )
                                            }

                                        })()}

                                        {QuotationPartialDetail && (QuotationPartialDetail.StatusType != 'Pending' && QuotationPartialDetail.StatusType != 'Rejected'
                                            ? <h3>
                                                <div className="submitBtn">
                                                    <div className="btnLoader" style={{ display: 'none' }}>
                                                        <span className="spinLoader"></span>
                                                    </div>
                                                    {/* <input value="Request for document" className="btn" type="button" /> */}
                                                    <a href='javascript:void(0);' className='btn' onClick={() => { setIsDocumentRequestToggle(true); window.scrollTo(0, 0) }}>Request for document</a>
                                                </div>
                                            </h3> : ''

                                        )}


                                    </section>


                                </section>


                            </section>
                        )



                    } else if (MyQuotaionByUserIDList != null && MyQuotaionByUserIDList.length == 0 && !loading) {
                        return (
                            <>
                                <section className="wrapper" style={{ minHeight: '0px' }}>
                                    <div className="noRecord" style={{ display: "block" }}>
                                    </div>
                                </section>
                                <div className="noRecord" style={{ display: "block" }}>
                                    <picture>
                                        <source srcset="/assets/images/no_search_result.webp" type="image/webp" />
                                        <img src="/assets/images/no_search_result.png" />
                                    </picture>
                                    <label>No result found!</label>
                                    <p>We couldn't find any result.</p>
                                </div>
                            </>
                        )
                    } else if (loading) {
                        return (
                            <section className="wrapper">
                                <section class="quotes" id="quoteListing">
                                    <div class="listingLoader" >
                                        <ul>
                                            <li>
                                                <h3>
                                                    <strong><Skeleton height={10} width={130} /></strong>
                                                    <h2><span><Skeleton height={30} width={70} /></span></h2>
                                                </h3>
                                                <div>
                                                    <span><Skeleton height={10} width={100} /></span>
                                                </div>
                                            </li>
                                            <li>
                                                <h3>
                                                    <strong><Skeleton height={10} width={130} /></strong>
                                                    <h2><span><Skeleton height={30} width={70} /></span></h2>
                                                </h3>
                                                <div>
                                                    <span><Skeleton height={10} width={100} /></span>
                                                </div>
                                            </li>
                                            <li>
                                                <h3>
                                                    <strong><Skeleton height={10} width={130} /></strong>
                                                    <h2><span><Skeleton height={30} width={70} /></span></h2>
                                                </h3>
                                                <div>
                                                    <span><Skeleton height={10} width={100} /></span>
                                                </div>
                                            </li>
                                            <li>
                                                <h3>
                                                    <strong><Skeleton height={10} width={130} /></strong>
                                                    <h2><span><Skeleton height={30} width={70} /></span></h2>
                                                </h3>
                                                <div>
                                                    <span><Skeleton height={10} width={100} /></span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </section>
                                <section className="quoteDetail" id="quoteDetail">
                                    <section className="currentQuote">
                                        <div className="avtar">
                                            <Skeleton style={{ display: 'block', width: '45px', height: '45px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} >
                                            </Skeleton>
                                        </div>

                                        <div className="info">
                                            <h3>
                                                <Skeleton height={20} width={500} />
                                            </h3>
                                            <Skeleton height={10} width={150} />
                                            <Skeleton height={10} width={150} style={{ marginLeft: '10px' }} />
                                        </div>
                                        <div class="btn-group">
                                            <Skeleton style={{ display: 'block', width: '30px', height: '30px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} />
                                        </div>
                                    </section>
                                    <section className='showAdminReplayMsg' >
                                        <div className='card'>
                                            <div className="from">
                                                <Skeleton style={{ display: 'block', width: '45px', height: '45px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} >
                                                </Skeleton>
                                            </div>
                                            <div className="message">
                                                <h4>
                                                    <strong><Skeleton height={20} width={500} /></strong>
                                                    <Skeleton height={10} width={150} />
                                                </h4>
                                                <Skeleton count={2} height={10} width={150} />


                                            </div>
                                        </div>
                                    </section>
                                    <section className="quoteHistory" style={{ display: 'block' }}>
                                        <h3>Please wait we are getting your history...</h3>
                                        <ul>
                                            <li>
                                                <div className='card'>
                                                    <div className="from">
                                                        <Skeleton style={{ display: 'block', width: '45px', height: '45px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} >
                                                        </Skeleton>
                                                    </div>
                                                    <div className="message">
                                                        <h4>
                                                            <strong><Skeleton height={20} width={500} /></strong>
                                                            <Skeleton height={10} width={150} />
                                                        </h4>
                                                        <Skeleton count={2} height={10} width={150} />


                                                    </div>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div className='card'>
                                                            <div className="from">
                                                                <Skeleton style={{ display: 'block', width: '45px', height: '45px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} >
                                                                </Skeleton>
                                                            </div>
                                                            <div className="message">
                                                                <h4>
                                                                    <strong><Skeleton height={20} width={500} /></strong>
                                                                    <Skeleton height={10} width={150} />
                                                                </h4>
                                                                <Skeleton count={2} height={10} width={150} />


                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className='card'>
                                                    <div className="from">
                                                        <Skeleton style={{ display: 'block', width: '45px', height: '45px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} >
                                                        </Skeleton>
                                                    </div>
                                                    <div className="message">
                                                        <h4>
                                                            <strong><Skeleton height={20} width={500} /></strong>
                                                            <Skeleton height={10} width={150} />
                                                        </h4>
                                                        <Skeleton count={2} height={10} width={150} />


                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='card'>
                                                    <div className="from">
                                                        <Skeleton style={{ display: 'block', width: '45px', height: '45px', border: 'none', objectFit: 'cover', borderRadius: '100%' }} >
                                                        </Skeleton>
                                                    </div>
                                                    <div className="message">
                                                        <h4>
                                                            <strong><Skeleton height={20} width={500} /></strong>
                                                            <Skeleton height={10} width={150} />
                                                        </h4>
                                                        <Skeleton count={2} height={10} width={150} />


                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </section>
                                </section>
                            </section>
                        )
                    }
                })()}
            </section>
        </>
    )
}
export default connect((state, props) => (
    {
        isLoggedIn: state.Login.isLoggedIn,
        MyQuotaionByUserIDList: state.myQuotation.MyQuotationListByUserID != null ? state.myQuotation.MyQuotationListByUserID : null,
        QuotationPartialDetail: state.myQuotation.QuotationPartialDetail != null ? state.myQuotation.QuotationPartialDetail : null,
        DocumentRequestList: state.documentRequest.payload != null ? state.documentRequest.payload : null,
        loading: state.myQuotation.loading
        // QuotationRequestId : state.myQuotation.MyQuotationListByUserID != null ? state.myQuotation.MyQuotationListByUserID[0].QuotationRequestID : null
    }),
    { MyQuotaionListByUserID, GetPartialDetailByID, GetByQuotationRequestID }
)(MyQuotationHistory)
