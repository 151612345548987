import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IMAGE_ROOT } from '../../api-config';
import { ResetUpdatePasswordState, UpdatePassword } from '../../redux/actions/profileAction';
import { Link } from 'react-router-dom';
import { resources } from '../../resource';
const ChangePassword = (
    {
        isLoggedIn, UpdatePassword, ErrorMessage, SuccessMessage, isSuccess, loading, ResetUpdatePasswordState
    }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem("user"));
    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [values, setValues] = useState({
        oldPassword: '',
        newPassword: '',
        confirmpassword: '',
    })
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!isLoggedIn) {
            history.push("/")
        }
    }, [isLoggedIn, history])


    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };


    const ChangePassowrdValidateInfo = (values) => {

        let errors = {};
        let formIsValid = true;

        //oldpassword
        if (!values.oldPassword) {
            errors.oldPassword = 'Old Password is required';
            formIsValid = false;
        } else if (values.oldPassword.length < 6) {
            errors.oldPassword = 'Old Password needs to be 6 characters or more';
            formIsValid = false;
        }

        //newpassword
        if (!values.newPassword) {
            errors.newPassword = 'New Password is required';
            formIsValid = false;
        } else if (values.newPassword.length < 6) {
            errors.newPassword = 'New Password needs to be 6 characters or more';
            formIsValid = false;
        }

        //confirmpassword
        if (!values.confirmpassword) {
            errors.confirmpassword = 'Confirm Password is required';
            formIsValid = false;
        } else if (values.confirmpassword !== values.newPassword) {
            errors.confirmpassword = 'Passwords do not match';
            formIsValid = false;
        }
        setErrors(errors);
        return formIsValid;
    }


    const toggleOldPassword = () => {
        setOldPasswordShown(!oldPasswordShown);
    };
    const toggleNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };
    const handleSubmit = e => {
        e.preventDefault();
        if (ChangePassowrdValidateInfo(values)) {
            UpdatePassword(user.UserID, values.oldPassword, values.newPassword);
        }
    }
    useEffect(() => {
        if (isSuccess === true) {
            setValues({
                oldPassword: '',
                newPassword: '',
                confirmpassword: '',
            });
            setOldPasswordShown(false);
            setConfirmPasswordShown(false);
            setNewPasswordShown(false);
            setTimeout(() => {
                dispatch(ResetUpdatePasswordState())
            }, 3000);
        }
    }, [isSuccess]);
    return (
        <>
            {(() => {
                if (user != null || user != undefined) {
                    return (
                        <>
                            <section class="innerPageBanner">
                                <section class="wrapper">

                                    <h6>Change Password</h6>
                                    <h1>{user.FullName}</h1>
                                    <div class="spacer" style={{ height: '40px' }}></div>


                                </section>
                            </section>
                            <section class="myProfilePage">
                                <section class="wrapper">

                                    <section class="profileWapper">

                                        <div class="userAvatar">
                                            <div class="avtar"><img src={IMAGE_ROOT + resources.USER_IMG_URL + user.ProfileImage} class="pic" onError={(e) => (e.target.onerror = null, e.target.src = "/assets/images/user-default-image.jpg")} /></div>
                                        </div>

                                        <section class="form">
                                            <form onSubmit={handleSubmit}>

                                                {ErrorMessage && (
                                                    <div class="fromRowWithLabel">
                                                        <div class="lbl"></div>
                                                        <div class="val">
                                                            <div className="alert alert-error" style={{ display: 'flex', width: '80%' }} id='ErrorMessage'>
                                                                <img src="/assets/images/error_x.svg" />
                                                                {ErrorMessage}
                                                                <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {SuccessMessage && (
                                                    <div class="fromRowWithLabel">
                                                        <div class="lbl"></div>
                                                        <div class="val">
                                                            <div className="alert alert-success" style={{ display: 'flex', width: '80%' }} id="isSuccessfullyUpdatePassword">
                                                                <img src="/assets/images/success-tik.svg" />
                                                                {SuccessMessage}
                                                                <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div class="fromRowWithLabel">
                                                    <div class="lbl">Old Password<span>*</span></div>
                                                    <div class="val">

                                                        <div class="passwordField" style={{ width: "80%" }}>
                                                            <input type={oldPasswordShown ? "text" : "password"} class="input imp" placeholder="Enter Old Password" name='oldPassword' value={values.oldPassword} onChange={handleChange} />
                                                            <a href="javascript:;" id="eye">
                                                                <span id="showPass" style={{ display: oldPasswordShown === true ? "block" : "none" }} onClick={toggleOldPassword}><img src="/assets/images/eye_active.png" /></span>
                                                                <span id="hidePass" onClick={toggleOldPassword} style={{ display: oldPasswordShown === false ? "block" : "none" }}><img src="/assets/images/eye_inactive.png" /></span>
                                                            </a>
                                                        </div>

                                                        <div class="validation"> {errors.oldPassword && <span style={{ display: 'block' }}>{errors.oldPassword}</span>} </div>
                                                    </div>
                                                </div>

                                                <div class="fromRowWithLabel">
                                                    <div class="lbl">New Password<span>*</span></div>
                                                    <div class="val">

                                                        <div class="passwordField" style={{ width: "80%" }}>
                                                            <input type={newPasswordShown ? "text" : "password"} class="input imp" placeholder="Enter New Password" name='newPassword' value={values.newPassword} onChange={handleChange} />
                                                            <a href="javascript:;" id="eye">
                                                                <span id="showPass" style={{ display: newPasswordShown === true ? "block" : "none" }} onClick={toggleNewPassword}><img src="/assets/images/eye_active.png" /></span>
                                                                <span id="hidePass" onClick={toggleNewPassword} style={{ display: newPasswordShown === false ? "block" : "none" }}><img src="/assets/images/eye_inactive.png" /></span>
                                                            </a>
                                                        </div>

                                                        <div class="validation"> {errors.newPassword && <span style={{ display: 'block' }}>{errors.newPassword}</span>} </div>
                                                    </div>
                                                </div>

                                                <div class="fromRowWithLabel">
                                                    <div class="lbl">Confirm Password<span>*</span></div>
                                                    <div class="val">

                                                        <div class="passwordField" style={{ width: "80%" }}>
                                                            <input type={confirmPasswordShown ? "text" : "password"} class="input imp" placeholder="Enter Confirm Password" name='confirmpassword' value={values.confirmpassword} onChange={handleChange} />
                                                            <a href="javascript:;" id="eye">
                                                                <span id="showPass" style={{ display: confirmPasswordShown === true ? "block" : "none" }} onClick={toggleConfirmPassword}><img src="/assets/images/eye_active.png" /></span>
                                                                <span id="hidePass" onClick={toggleConfirmPassword} style={{ display: confirmPasswordShown === false ? "block" : "none" }}><img src="/assets/images/eye_inactive.png" /></span>
                                                            </a>
                                                        </div>

                                                        <div class="validation"> {errors.confirmpassword && <span style={{ display: 'block' }}>{errors.confirmpassword}</span>} </div>
                                                    </div>
                                                </div>


                                                <br />
                                                <div class="fromRowWithLabel">
                                                    <div class="lbl"></div>
                                                    <div class="val">

                                                        <span class="" style={{ marginRight: "25px" }}>
                                                            <div class="btnLoader" style={{ display: loading === true ? 'block' : 'none' }}>
                                                                <span class="spinLoader small"></span>
                                                            </div>
                                                            <button class='product-btn' type="submit">Update Password</button>
                                                        </span>

                                                        <Link to='javascript:void(0);' onClick={() => { history.goBack() }}>Cancel</Link>

                                                    </div>
                                                </div>

                                            </form>
                                        </section>


                                    </section>

                                    <div class="profileAssets">
                                        {/* <img src="/assets/images/model_chair.png" /> */}
                                        </div>

                                </section>
                            </section>
                        </>
                    )
                }
            })()
            }

        </>
    )
}
export default connect((state, props) => (
    {
        isLoggedIn: state.Login.isLoggedIn,
        ErrorMessage: state.profile.FailMessage != null ? state.profile.FailMessage : null,
        SuccessMessage: state.profile.SuccessMessage != null ? state.profile.SuccessMessage : null,
        isSuccess: state.profile.isSuccess,
        loading: state.profile.loading
    }),
    { UpdatePassword, ResetUpdatePasswordState }
)(ChangePassword)
