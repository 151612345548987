import React from 'react';
import { useLocation, Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const SubHeader = ({ CurrentPageName }) => {
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);
    const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    const cartCount = useSelector((state) => state.cart.cartItems != null ? state.cart.cartItems.length: 0);
    return (
        <>
            <section className="innerPageBanner">
                <section className="wrapper">

                    {(() => {
                        if (CurrentPageName == 'My Cart') {
                            return (
                                <>
                                    <h1>My Cart</h1>
                                    <div class="itemInYourCart">
                                        {cartCount} Item in your cart
                                    </div>
                                </>
                            )

                        }else if(CurrentPageName == 'Product Quotation'){
                            return(
                                <>
                                <h1>{CurrentPageName}</h1>
                                    <div class="itemInYourCart">
                                        {cartCount} Item in your cart
                                    </div>
                                </>
                            )
                        }
                         else {
                            return (
                                <>
                                    <h1>{CurrentPageName.toUpperCase()}</h1>
                                    <div className="breadcrumbs">
                                        {
                                            pathnames.length > 0 ? (
                                                <Link to="/">Home</Link>

                                            ) : (
                                                <span>Home</span>
                                            )
                                        }
                                        {
                                            pathnames.map((name, index) => {
                                                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                                                const isLast = index == pathnames.length - 1;
                                                return isLast ? (

                                                    <span>{CurrentPageName}</span>
                                                ) : (
                                                    <Link to={`${routeTo}`}>{capatilize(name)}</Link>
                                                )
                                            })
                                            
                                            // pathnames.map((name, index) => {
                                            //     const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                                            //     const isLast = index == pathnames.length - 1;
                                            //     return isLast ? (

                                            //         <span>{capatilize(name)}</span>
                                            //     ) : (
                                            //         <Link to={`${routeTo}`}>{capatilize(name)}</Link>
                                            //     )
                                            // })
                                        }
                                    </div>
                                </>
                            )
                        }

                    })()}

                    {/* <img src="/assets/images/assets-2.png" className="assets" /> */}
                </section>
            </section>

        </>
    )
};
export default SubHeader;