import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';


export const Login = (
    UserName,
    OTP
) => async dispatch => {
    
    var formData = new FormData();
    formData.append("UserName", UserName);
    formData.append("OTP", OTP);
    return api.post('Account/UserValidateAccountOTP/', formData).then(
        (response) => {
            if (response.data.ResultObject != null && response.data.ResultObject.UserID === -1) {
                dispatch({
                    type: ActionTypes.LOGIN_FAIL,
                    payload: response.data.ResultMessage,
                });
                dispatch({
                    type: ActionTypes.OTP_EXPIRED_MESSAGE,
                    payload: response.data.ResultMessage,
                });
            }
            else if (response.data.ResultObject) {
                const user = {
                    UserID: response.data.ResultObject.EncryptedUserID,
                    // FullName: response.data.ResultObject.FullName,
                    token: response.data.ResultNotes,
                    FullName: response.data.ResultObject.FullName,
                    ProfileImage: response.data.ResultObject.ProfileImage,
                    ContactNo: response.data.ResultObject.ContactNo,
                    Email: response.data.ResultObject.Email
                }
                localStorage.setItem("user", JSON.stringify(user));
                dispatch({
                    type: ActionTypes.LOGIN_SUCCESS,
                    payload: { user: response.data.ResultObject },
                });
            }


        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: ActionTypes.LOGIN_FAIL,
            });
            dispatch({
                type: ActionTypes.OTP_EXPIRED_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
}

export const UserValidateAccount = (
    UserName
) => async dispatch => {
    
    var formData = new FormData();
    formData.append("UserName", UserName);
    return api.post('Account/UserValidateAccount/', formData).then(
        (response) => {
            if (response.data.ResultObject.UserID === -1) {
                dispatch({
                    type: ActionTypes.USER_VALIDATE_FAIL,
                    payload: response.data.ResultMessage,
                });
                dispatch({
                    type: ActionTypes.SET_MESSAGE,
                    payload: response.data.ResultMessage,
                });
            }
            else if (response.data.ResultObject) {
                // localStorage.setItem("user", JSON.stringify(response.data.ResultObject.UserID, response.data.ResultObject.FullName));
                dispatch({
                    type: ActionTypes.USER_VALIDATE_SUCCESS,
                    payload: { user: response.data.ResultObject },
                });
            }
        });
}


export const LoginCredentials = (
    UserName,
    Password
) => async dispatch => {
    
    var formData = new FormData();
    formData.append("UserName", UserName);
    formData.append("Password", Password);
    return api.post('Account/UserValidateCredentials/', formData).then(
        (response) => {
            if (response.data.ResultObject != null && response.data.ResultObject.UserID === -1) {
                dispatch({
                    type: ActionTypes.LOGIN_FAIL,
                    payload: response.data.ResultMessage,
                });
                dispatch({
                    type: ActionTypes.OTP_EXPIRED_MESSAGE,
                    payload: response.data.ResultMessage,
                });
            }
            else if (response.data.ResultObject) {
                const user = {
                    UserID: response.data.ResultObject.EncryptedUserID,
                    token: response.data.ResultNotes,
                    FullName: response.data.ResultObject.FullName,
                    ProfileImage: response.data.ResultObject.ProfileImage,
                    ContactNo: response.data.ResultObject.ContactNo,
                    Email: response.data.ResultObject.Email
                    // FullName: response.data.ResultObject.FullName,
                }
                localStorage.setItem("user", JSON.stringify(user));
                dispatch({
                    type: ActionTypes.LOGIN_SUCCESS,
                    payload: { user: response.data.ResultObject },
                });
            }


        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: ActionTypes.LOGIN_FAIL,
            });
            dispatch({
                type: ActionTypes.OTP_EXPIRED_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
}

export const AccountRegister = (
    UserName, ConfirmPassword, ContactNo, Email, FirstName, LastName,CountryCode
) => async dispatch => {
    var formData = new FormData();
    formData.append("UserName", UserName);
    formData.append("ConfirmPassword", ConfirmPassword);
    formData.append("ContactNo", ContactNo);
    formData.append("Email", Email);
    formData.append("FirstName", FirstName);
    formData.append("LastName", LastName);
    formData.append("CountryCode", CountryCode);
    return api.post('Account/UserAccountRegister/', formData).then(
        (response) => {
            if (response.data.ResultCode === 'ERROR') {
                dispatch({
                    type: ActionTypes.REGISTER_FAILURE,
                    payload: response.data.ResultMessage,
                });

            } else if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.REGISTER_SUCCESS,
                    payload: response.data.ResultMessage,
                });
            } else {
                dispatch({
                    type: ActionTypes.REGISTER_FAILURE,
                    payload: response.data.ResultMessage,
                });
            }
        }
    )
}

export const logout = () => (dispatch) => {
    localStorage.removeItem("user");
    dispatch({
        type: ActionTypes.LOGOUT,
    });
    dispatch({
        type: ActionTypes.RESET_PROFILE_STATE
    });
    dispatch({
        type: ActionTypes.USER_LOGIN_STATE_RESET
    })
};

export const RegisterStateReset = () => (dispatch) => {
    dispatch({ type: ActionTypes.REGISTER_STATE_RESET });
};