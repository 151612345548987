import { ActionTypes } from '../constants/action-types';
const initialState = {
    isSendOTP: false,
    encryptedOTP: null,
    isSuccefullySave: false,
    loading: false,
}
export const RequestForQuotationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.LOADER_FOR_REQUESTFORQUOTATION_START:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.OTP_EXPIRED_FOR_REQUEST_QUOTATION:
            return {
                ...state,
                encryptedOTP: null,
                isSendOTP: true,
                isSuccefullySave: false,
                loading: false
            }
        case ActionTypes.SEND_OTP_SUCCESFULLY_FOR_REQUEST_QUOTATION:
            return {
                ...state,
                encryptedOTP: payload,
                isSendOTP: true,
                isSuccefullySave: false,
                loading: false
            };
        case ActionTypes.OTP_VERFIED_AND_SAVE_SUCCESFULLY_FOR_REQUEST_QUOTATION:
            return {
                ...state,
                isSendOTP: true,
                isOTPVerfiedAndSave: true,
                isSuccefullySave: true,
                loading: false
            };
        case ActionTypes.OTP_VERFIED_AND_SAVE_FAIL_FOR_REQUEST_QUOTATION:
            return {
                ...state,
                isSendOTP: true,
                isOTPVerfiedAndSave: false,
                isSuccefullySave: false,
                loading: false
            };
        case ActionTypes.RESET_REQUEST_QUOTATION_STATE:
            return {
                ...initialState,
                isOTPVerfiedAndSave: false,
                isSuccefullySave: true,
                loading: false
            }
         case ActionTypes.RESET_ALL_REQUEST_QUOTATION_FOR_CART:{
             return{
                 ...initialState,
                 isOTPVerfiedAndSave: true,
                 loading: false
             }
         }   
        default:
            return state;
    }
}