import React, { useEffect, useState } from "react"
import { chain } from 'lodash';
const ProductSketchfabDemo = () => {

    var containerSD = React.createRef();

    var DEFAULT_URLID = 'e374a66c99694501b7877585d9c79dd7';
    // var DEFAULT_PREFIX = "Dining Table Base";

    // var api = null;
    const [api, setApi] = useState(null);
    const [parameter, setParameter] = useState(null);
    var config = null;
    var options = [];
    const [dimension, setDimension] = useState(new Map());
    const [nodevalue, setNodes] = useState();
    const [heightRangeValue, setHeightRangeValue] = useState();
    const [depthRangeValue, setDepthRangeValue] = useState();
    const [lengthRangeValue, setLengthRangeValue] = useState();

    let textureid;
    let material;
    const [RangeHeight, setRangeHeight] = useState(new Map());
    const [RangeLength, setRangeLength] = useState(new Map());
    const [RangeDepth, setRangeDepth] = useState(new Map());



    useEffect(() => {

        if (dimension.size > 0) {


            setDimension(dimension);

            let data = dimension;
            let keys = Array.from(data.keys());
            let MinRangeValue = chain(keys).sort().first().value();
            setLengthRangeValue(MinRangeValue.split('*')[0]);
            setDepthRangeValue(MinRangeValue.split('*')[1]);
            setHeightRangeValue(MinRangeValue.split('*')[2])

        }

    }, [dimension.size])
    useEffect(() => {
        if (options.length > 0) {

            setParameter(options);

        }
    }, [parameter]);
    useEffect(() => {

        setHeightRangeValue(heightRangeValue);


    }, [heightRangeValue]);
    useEffect(() => {
        setLengthRangeValue(lengthRangeValue);
    }, [lengthRangeValue]);
    useEffect(() => {

        setDepthRangeValue(depthRangeValue);
    }, [depthRangeValue]);
    useEffect(() => {
        if (RangeHeight.length > 0)
            setRangeHeight(RangeHeight)

    }, [RangeHeight]);

    function InitSketchfabDemo(config) {
        config = config;
        var client = new window.Sketchfab(containerSD.current);
        client.init(DEFAULT_URLID, {
            ui_infos: 0,
            ui_controls: 0,
            graph_optimizer: 0,
            // autospin: 0.1,
            continuousRender: 1,
            preload: 1,
            ui_stop: 0,
            ui_watermark: 0,

            success: function onSuccess(api) {

                setApi(api);
                api.start();
                api.addEventListener('viewerready', function () {

                    // api.getMaterialList((err, materials) => {

                    //     material = materials[0]; // There's only one material in this case

                    //     var textureElement = document.getElementsByClassName('btnChangeValue');
                    //     // api.addTexture('https://engrossinfotech.com/shadecard/texture2.jpg', (err, uid) => {

                    //     //     if (!err) {
                    //     //         textureid = uid;
                    //     Array.from(textureElement).forEach(function (element) {
                    //         element.addEventListener('click', (e) => {

                    //             api.addTexture(element.src, (err, textureid) => {
                    //                 let materialToUpdate = material;
                    //                 materialToUpdate.channels.AlbedoPBR.texture.uid = textureid;
                    //                 materialToUpdate.channels.AlbedoPBR.enable = true;
                    //                 api.setMaterial(materialToUpdate, () => {
                    //                     window.console.log('Updated material.');
                    //                 });
                    //             });
                    //         });
                    //     })
                    //     // document.getElementsByClassName('btnChangeValue').addEventListener('click', () => {
                    //     //     
                    //     //     let materialToUpdate = material;
                    //     //     materialToUpdate.channels.AlbedoPBR.texture.uid = textureid;
                    //     //     materialToUpdate.channels.AlbedoPBR.enable = true;
                    //     //     api.setMaterial(materialToUpdate, () => {
                    //     //         window.console.log('Updated material.');
                    //     //     });
                    //     // });
                    //     // }
                    //     // });

                    // });

                    api.getNodeMap(function (err, nodes) {
                        if (err) {
                            console.error(err);
                            return;
                        }
                        setNodes(nodes);
                        var node;
                        var isOptionObject = false;
                        var keys = Object.keys(nodes);
                        var isDefaultShawn = false;
                        for (var i = 0; i < keys.length; i++) {

                            node = nodes[keys[i]];
                            if (node.name != undefined && node.name.includes('LDH') && node.type === 'Group') {

                                if (!isDefaultShawn) {
                                    isDefaultShawn = true;
                                    // setHeightRangeValue(node.name.split('_')[1])
                                    api.show(node.children[0].instanceID);
                                } else {
                                    api.hide(node.children[0].instanceID);
                                }

                                var key = node.name.split('_')[1];
                                var value = node.children[0].instanceID;

                                setDimension(dimension.set(key, value));
                            }
                            // if (node.name != undefined && node.name.includes('Length') && node.type === 'Group') {
                            //     var key = node.name.split('_')[1];
                            //     var value = node.children[0].instanceID;
                            //     RangeLength.set(key, value);
                            //     setRangeLength(RangeLength);
                            //     setLengthRangeValue(1)
                            //     api.hide(node.children[0].instanceID);
                            // }
                            // if (node.name != undefined && node.name.includes('Depth') && node.type === 'Group') {
                            //     var key = node.name.split('_')[1];
                            //     var value = node.children[0].instanceID;
                            //     RangeDepth.set(key, value);
                            //     setRangeDepth(RangeDepth);
                            //     setDepthRangeValue(1);
                            //     api.hide(node.children[0].instanceID);
                            // }


                            isOptionObject =
                                // node.name.indexOf(DEFAULT_PREFIX) !== -1 &&
                                node.name != 'Scene - RootModel' && node.name != 'Scene - PolygonNode' && node.name != undefined && node.name != 'root' &&
                                (node.type === "Group");
                            if (isOptionObject) {
                                // childrenData = node.children;
                                // for (let index = 0; index < childrenData.length; index++) {

                                // } 
                                options.push({
                                    id: node.instanceID,
                                    // name: node.name,
                                    selected: false
                                });
                                api.show(node.instanceID)

                            }
                        }
                        setParameter(options);
                        // selectOption(0)

                    });


                    // initializeOptions(function () {
                    //     console.log('Found the following options:', options);
                    //     selectOption(0);
                    // }.bind(this));
                }.bind(this));
            }.bind(this),
            error: function onError() {
                console.log('Viewer error');
            }
        });
    }
    function addTextured(e) {
        let imagesrcValue = e.currentTarget.src;
        // 
        // api.getMatrix(nodevalue[414], function (err, matrix) {
        //     
        //     if (!err) {
        //         window.console.log('Matrix:', matrix);
        //     } else { console.log('dd') }
        //     console.log('asdasd')
        // });

        // api.getMatrix(nodevalue[426].instanceID, function (err, matrix) {
        //     if (!err) {
        //         window.console.log('Matrix:', matrix);
        //     }
        // });

        // api.setMatrix(412, [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1], function (err) {
        //     if (!err) {
        //         window.console.log('Matrix set');
        //     }
        // });

        api.getMaterialList((err, materials) => {

            material = materials[0]; // There's only one material in this case

            var textureElement = document.getElementsByClassName('btnChangeValue');
            // api.addTexture('https://engrossinfotech.com/shadecard/texture2.jpg', (err, uid) => {

            //     if (!err) {
            //         textureid = uid;
            // Array.from(textureElement).forEach(function (element) {
            //     element.addEventListener('click', (e) => {

            api.addTexture(imagesrcValue, (err, textureid) => {
                let materialToUpdate = material;
                materialToUpdate.channels.AlbedoPBR.texture.uid = textureid;
                materialToUpdate.channels.AlbedoPBR.enable = true;
                api.setMaterial(materialToUpdate, () => {
                    window.console.log('Updated material.');
                });
            });
            //     });
            // })
            // document.getElementsByClassName('btnChangeValue').addEventListener('click', () => {
            //     
            //     let materialToUpdate = material;
            //     materialToUpdate.channels.AlbedoPBR.texture.uid = textureid;
            //     materialToUpdate.channels.AlbedoPBR.enable = true;
            //     api.setMaterial(materialToUpdate, () => {
            //         window.console.log('Updated material.');
            //     });
            // });
            // }
            // });

        });

    }

    function selectOption(index) {

        var options = parameter;
        for (var i = 0, l = options.length; i < l; i++) {
            if (i === index) {
                options[i].selected = true;
                api.show(options[i].id);
            }
            else {
                options[i].selected = false;
                api.hide(options[i].id);
            }
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.sketchfab.com/api/sketchfab-viewer-1.1.0.js";

        // document.head.appendChild(script);
        document.head.insertBefore(script, document.querySelector('title'));

        setTimeout(function () {
            InitSketchfabDemo(config);
        }, 200);

        return () => {
            document.head.removeChild(script);
        }
    }, [])

    return (
        <>
            <iframe
                // We feed the ref to the iframe component to get the underlying DOM object
                ref={containerSD}
                title="sketchfab-viewer"
                style={{ height: 500, width: 600, border: 0 }}
            />
            {(() => {
                if (dimension.size > 0) {
                    let data = dimension;
                    let keys = Array.from(data.keys());
                    let MaxRangeValue = chain(keys).sort().last().value();
                    let MinRangeValue = chain(keys).sort().first().value();
                    return (
                        <>

                            <div> <h4>Top Wood</h4>
                                <img src="https://engrossinfotech.com/shadecard/texture2.jpg" style={{ width: "100px", marginRight: "10px", objectPosition: '50% 50%', objectFit: 'none' }} className='btnChangeValue' onClick={(e) => { addTextured(e) }} />
                                <img src="https://engrossinfotech.com/shadecard/texture3.jpg" style={{ width: "100px", objectPosition: '50% 50%', objectFit: 'none' }} className='btnChangeValue' onClick={(e) => { addTextured(e) }} />
                            </div>

                            Height <input type='range' min={MinRangeValue.split('*')[2]} max={MaxRangeValue.split('*')[2]} value={heightRangeValue} step={5}
                                onChange={(e) => {

                                    for (let [key, value] of dimension.entries()) {
                                        if (key == lengthRangeValue + "*" + depthRangeValue + "*" + e.currentTarget.value.toString()) { // 30*30*35 = 30*30*35
                                            setHeightRangeValue(e.currentTarget.value);
                                            api.show(value);
                                        } else {
                                            // for (let [key, value] of RangeLength.entries()) {
                                            //     api.hide(value);
                                            // }
                                            // for (let [key, value] of RangeDepth.entries()) {
                                            //     api.hide(value);
                                            // }
                                            api.hide(value);
                                        }
                                    }

                                }} />
                            Length<input type='range' min={MinRangeValue.split('*')[0]} max={MaxRangeValue.split('*')[0]} value={lengthRangeValue} step={5}
                                onChange={(e) => {

                                    for (let [key, value] of dimension.entries()) {
                                        if (key == e.currentTarget.value.toString() + "*" + depthRangeValue + "*" + heightRangeValue) {
                                            setLengthRangeValue(e.currentTarget.value);
                                            api.show(value);
                                        } else {
                                            // for (let [key, value] of RangeHeight.entries()) {
                                            //     api.hide(value);
                                            // }
                                            // for (let [key, value] of RangeDepth.entries()) {
                                            //     api.hide(value);
                                            // }
                                            api.hide(value);
                                        }
                                    }

                                }}
                            />

                            Depth<input type='range' min={MinRangeValue.split('*')[1]} max={MaxRangeValue.split('*')[1]} value={depthRangeValue} step={5}
                                onChange={(e) => {

                                    for (let [key, value] of dimension.entries()) {
                                        if (key == lengthRangeValue + "*" + e.currentTarget.value.toString() + "*" + heightRangeValue) {
                                            setDepthRangeValue(e.currentTarget.value);
                                            api.show(value);
                                        } else {
                                            api.hide(value);
                                        }
                                    }

                                }}
                            />
                            <br />
                        </>
                    )
                }
            })()}


            <form className="options" style={{ padding: "60px 20px 20px 200px", width: "35%" }}>
                {/* <div> <h4>Top Wood</h4>
                    <img src="https://engrossinfotech.com/shadecard/texture2.jpg" style={{ width: "100px", marginRight: "10px", objectPosition: '50% 50%', objectFit: 'none' }} className='btnChangeValue' />
                    <img src="https://engrossinfotech.com/shadecard/texture3.jpg" style={{ width: "100px", objectPosition: '50% 50%', objectFit: 'none' }} className='btnChangeValue' />
                </div>

                Height <input type='range' min={[...dimension.keys()][0].split('*')[2]} max={[...dimension.keys()][dimension.size - 1].split('*')[2]} value={heightRangeValue} step={1}
                    onChange={(e) => {
                        for (let [key, value] of RangeHeight.entries()) {
                            if (key == e.currentTarget.value.toString() + "*" + lengthRangeValue + "*" + depthRangeValue) { // 30*30*35 = 30*30*35
                                setHeightRangeValue(e.currentTarget.value);
                                api.show(value);
                            } else {
                                for (let [key, value] of RangeLength.entries()) {
                                    api.hide(value);
                                }
                                for (let [key, value] of RangeDepth.entries()) {
                                    api.hide(value);
                                }
                                api.hide(value);
                            }
                        }

                    }} />
                Length<input type='range' min={[...dimension.keys()][0].split('*')[0]} max={[...dimension.keys()][dimension.size - 1].split('*')[0]} value={lengthRangeValue} step={1}
                    onChange={(e) => {
                        
                        for (let [key, value] of RangeLength.entries()) {
                            if (key == e.currentTarget.value.toString()) {
                                setLengthRangeValue(e.currentTarget.value);
                                api.show(value);
                            } else {
                                for (let [key, value] of RangeHeight.entries()) {
                                    api.hide(value);
                                }
                                for (let [key, value] of RangeDepth.entries()) {
                                    api.hide(value);
                                }
                                api.hide(value);
                            }
                        }

                    }}
                />
                Depth<input type='range' min={[...dimension.keys()][0].split('*')[1]} max={[...dimension.keys()][dimension.size - 1].split('*')[1]} value={depthRangeValue} step={1}
                    onChange={(e) => {
                        
                        for (let [key, value] of RangeDepth.entries()) {
                            if (key == e.currentTarget.value.toString()) {
                                setDepthRangeValue(e.currentTarget.value);
                                api.show(value);
                            } else {
                                for (let [key, value] of RangeHeight.entries()) {
                                    api.hide(value);
                                }
                                for (let [key, value] of RangeLength.entries()) {
                                    api.hide(value);
                                }
                                api.hide(value);
                            }
                        }

                    }}
                />
                <br /> */}




            </form>
        </>
    )
}
export default ProductSketchfabDemo