import { ActionTypes } from '../constants/action-types';
const initialState = {
    Header: []
}
export const HeaderReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case ActionTypes.FETCH_HEADERDATA:
            return { ...state, Header: payload };
        case ActionTypes.GLOBAL_SEARCH_BY_PRODUCTNAME_SUCCESSFULLY:
            return {
                ...state, payload
            };
            case ActionTypes.RESET_GLOBAL_SEARCH_STATE:
                return {
                    ...state, payload:null
                };
        default:
            return state;
    }

}