import { ActionTypes } from '../constants/action-types';
const initialState = {
}
export const AboutUsReducer = (state = initialState, { type, payload }) => {
    
    switch (type) {
        case ActionTypes.FETCH_ABOUT_DATA:
            return { ...state, payload };
        default:
            return state;
    }
}