import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IMAGE_ROOT } from '../../api-config';
import SubHeader from '../Shared/subHeader';
import { AddQuantity, SubtractQuantity, removeFromCart, OnTextChangeUpdateQuantity } from '../../redux/actions/addToCartAction';
import { Link } from 'react-router-dom';
import { ResetALLRequestQuotationStateForCart } from '../../redux/actions/requestForQuotationAction';
import { resources } from '../../resource';
const CartComponent = (
    {
        AddQuantity, SubtractQuantity, cartItems, removeFromCart, ResetALLRequestQuotationStateForCart, OnTextChangeUpdateQuantity
    }
) => {
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        ResetALLRequestQuotationStateForCart();
    }, []);

    let QuantityCount;
    const [quantityInput, setQuantityInput] = useState();
    const incrementQuantityCount = (ProductID) => {
        setQuantityInput(quantityInput + 1)
        AddQuantity(ProductID);
    }
    const decrementQuantityCount = (ProductID) => {
        if (quantityInput > 1) {
            setQuantityInput(quantityInput - 1)
        }
        SubtractQuantity(ProductID);
    }
    // useEffect(() => {
    //     setQuantityInput(QuantityCount)
    // }, [cartItems]);
    return (
        <div>
            <SubHeader CurrentPageName={'My Cart'} />
            <section className="myCartPage">
                <section className="wrapper">
                    <div className="cartItems">
                        <ul className="purchasedItems">
                            {(() => {
                                if (cartItems != null && cartItems.length > 0 && cartItems != undefined) {
                                    return (
                                        cartItems.map((data, key) => {
                                            var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ImageName;
                                            QuantityCount = data.count;
                                            return (
                                                <li>
                                                    <div className="remove">
                                                        <a onClick={() => { removeFromCart(data.rowNumber) }} href="javascript:void(0);"><img src="/assets/images/cross.svg" /></a>
                                                    </div>

                                                    <div className="image">
                                                        <img src={imgpath} style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                                                    </div>

                                                    <div className="productInfo">
                                                        <h4>{data.ProductName}</h4>
                                                        <p>Wood: Mapple, Size: 12ft, Finishing: Glass Touch</p>
                                                    </div>

                                                    <div className="clr"></div>

                                                    <div className="qtyControl">
                                                        <a href="javascript:void(0);" onClick={() => { decrementQuantityCount(data.rowNumber) }}>-</a>
                                                        <span><input type="number" className="input" onChange={(e) => {
                                                            if (e.target.value <= 0 || e.target.value == "") {
                                                                setQuantityInput(1);
                                                            } else if (e.target.value.length == 6) {
                                                                return false;
                                                            }
                                                            else {
                                                                setQuantityInput(e.target.value); OnTextChangeUpdateQuantity(data.rowNumber, e.target.value);
                                                            }
                                                            // e.target.value == 0 ? setQuantityInput(1) : setQuantityInput(e.target.value);OnTextChangeUpdateQuantity(data.rowNumber,e.target.value)
                                                        }} value={data.count} id="quantity" /></span>
                                                        <a href="javascript:void(0);" onClick={() => { incrementQuantityCount(data.rowNumber) }}>+</a>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    )
                                }
                                else if (cartItems != null && cartItems.length == 0) {
                                    return (
                                        <div class="noRecord" style={{ display: 'block' }}>
                                            <picture>
                                                <img src="/assets/images/emptry_cart.gif" style={{ maxWidth: '100%' }} />
                                            </picture>
                                            <label>Cart is Empty.</label>
                                            <p>Add item in your cart.</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="cartLoader">

                                            <ul className="purchasedItems">
                                                {
                                                    [...Array(3)].map((ignore, index) => {
                                                        return (
                                                            <li key={index}><img className="skeletonLoader" src="/assets/images/my_cart_listing_loader.svg" /></li>

                                                        )
                                                    })
                                                }
                                            </ul>

                                        </div>
                                    )
                                }

                            })()}

                            <li style={{ display: cartItems != null && cartItems.length == 0 ? 'none' : 'block', boxShadow: 'none' }}>
                                <section className="checkOutBtn">
                                    <div className="">
                                        <div className="btnLoader" style={{ display: 'none' }}>
                                            <span className="spinLoader"></span>
                                        </div>
                                        <a href='javascript:void(0);'><Link to="/quotation"><input value="Proceed to Checkout" className="cart-btn btn" type="button" /></Link></a>

                                    </div>
                                </section>
                            </li>

                        </ul>

                    </div>

                </section>
            </section>
        </div>
    );
};
export default connect((state, props) => (
    {

        cartItems: state.cart.cartItems,
    }),
    { AddQuantity, SubtractQuantity, removeFromCart, ResetALLRequestQuotationStateForCart, OnTextChangeUpdateQuantity }
)(CartComponent)
