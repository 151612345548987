import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

export const AddOrRemoveFavoriteProduct = (
    ProductID,
    UserID
) => async dispatch => {

    var formData = new FormData();
    formData.append("ProductID", ProductID);
    formData.append("UserID", UserID);
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    return api.post('FavoriteProduct/AddFavoriteProduct', formData, { headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'ERROR' || response.data.ResultCode === 'EXISTS') {
                dispatch({
                    type: ActionTypes.FAVORITE_PRODUCTS_FAIL
                });

            } else if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.FAVORITE_PRODUCTS_SUCCESFULLY,
                    payload: response.data.ResultObject
                });
            } else {
                dispatch({
                    type: ActionTypes.FAVORITE_PRODUCTS_FAIL,
                });
            }
        }
    ).catch((error) => {
        dispatch({
            type: ActionTypes.FAVORITE_PRODUCTS_FAIL,
            payload: error.response
        });
    })
}

export const FavoriteProductsByUserID = (
    UserID,
    loader = true
) => async dispatch => {
    var formData = new FormData();
    formData.append("UserID", UserID);
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    if (loader)
        dispatch({ type: ActionTypes.LOADER_START });
    return api.post('FavoriteProduct/Get', formData, { headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'ERROR' || response.data.ResultCode === 'EXISTS') {
                dispatch({
                    type: ActionTypes.FAVORITE_PRODUCTS_FAIL,
                });

            } else if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.FAVORITE_PRODUCTS_SELECT,
                    payload: response.data.ResultObject
                });
            } else {
                dispatch({
                    type: ActionTypes.FAVORITE_PRODUCTS_FAIL,
                });
            }
        }
    )
}