import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';


export const fetchHeader = (

) => async dispatch => {
    try {
        const resultObject = await api.get("Category/GetCategoryWeb");
        dispatch({
            type: ActionTypes.FETCH_HEADERDATA,
            payload: resultObject.data.ResultObject
        })
    }
    catch (e) {
        dispatch({
            type: ActionTypes.FETCH_HEADERDATA,
            homeExceptionLogObject: []
        })
    }
}

export const GlobalSearchByProductName = (
    ProductName
) => async dispatch => {
    return api.get('Product/SearchProductByName/' + ProductName).then(
        (response) => {
            if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.GLOBAL_SEARCH_BY_PRODUCTNAME_SUCCESSFULLY,
                    payload: response.data.ResultObject
                });
            } else {
                dispatch({
                    type: ActionTypes.GLOBAL_SEARCH_BY_PRODUCTNAME_FAIL,
                });
            }
        }
    )
}
export const ResetGlobalSearchState = () => (dispatch) => {
    dispatch({ type: ActionTypes.RESET_GLOBAL_SEARCH_STATE });
};