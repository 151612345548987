export const ActionTypes = {
    SET_HOME: "SET_HOME",
    FETCH_DATA: "FETCH_DATA",
    FETCH_AllPRODUCTSPAGEDATA: "FETCH_AllPRODUCTSPAGEDATA",
    FETCH_PRODUCTDETAIL: "FETCH_PRODUCTDETAIL",
    LOADER_START: "LOADER_START",
    FETCH_HEADERDATA: "FETCH_HEADERDATA",
    FETCH_ABOUT_DATA: "FETCH_ABOUT_DATA",
    FETCH_HOMEDATA: "FETCH_HOMEDATA",
    FETCH_HELPSUPPORT_DATA: "FETCH_HELPSUPPORT_DATA",
    FETCH_CATEGORIES_DATA: "FETCH_CATEGORIES_DATA",
    HOME_LOADER_START: "HOME_LOADER_START",
    ADD_TO_CART: "ADD_TO_CART",
    ADD_QUANTITY: "ADD_QUANTITY",
    SUBTRACT_QUANTITY: "SUBTRACT_QUANTITY",
    FETCH_TEAM: "FETCH_TEAM",
    REMOVE_FROM_CART: "REMOVE_FROM_CART",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",
    SET_MESSAGE: "SET_MESSAGE",
    USER_VALIDATE_FAIL: "USER_VALIDATE_FAIL",
    USER_VALIDATE_SUCCESS: "USER_VALIDATE_SUCCESS",
    OTP_EXPIRED_MESSAGE: "OTP_EXPIRED_MESSAGE",
    USER_LOGIN_STATE_RESET: "USER_LOGIN_STATE_RESET",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAILURE: "REGISTER_FAILURE",
    REGISTER_STATE_RESET: "REGISTER_STATE_RESET",
    FORGOTPASSWORD_SUCCESS: "FORGOTPASSWORD_SUCCESS",
    FORGOTPASSWORD_FAIL: "FORGOTPASSWORD_FAIL",
    FORGOTPASSWORD_STATE_RESET: "FORGOTPASSWORD_STATE_RESET",
    VALIDATE_RESET_CODE_SUCCESS: "VALIDATE_RESET_CODE_SUCCESS",
    VALIDATE_RESET_CODE_FAIL: "VALIDATE_RESET_CODE_FAIL",
    UPDATE_NEW_PASSWORD_SUCCESS: "UPDATE_NEW_PASSWORD_SUCCESS",
    UPDATE_NEW_PASSWORD_FAIL: "UPDATE_NEW_PASSWORD_FAIL",
    SEND_FORGOTPASSWORD_LINK_SUCCESSFULLY: "SEND_FORGOTPASSWORD_LINK_SUCCESSFULLY",
    SEND_OTP_SUCCESFULLY_FOR_REQUEST_QUOTATION: "SEND_OTP_SUCCESFULLY_FOR_REQUEST_QUOTATION",
    SEND_OTP_FAIL_FOR_REQUEST_QUOTATION: "SEND_OTP_FAIL_FOR_REQUEST_QUOTATION",
    OTP_VERFIED_AND_SAVE_SUCCESFULLY_FOR_REQUEST_QUOTATION: "OTP_VERFIED_AND_SAVE_SUCCESFULLY_FOR_REQUEST_QUOTATION",
    OTP_VERFIED_AND_SAVE_FAIL_FOR_REQUEST_QUOTATION: "OTP_VERFIED_AND_SAVE_FAIL_FOR_REQUEST_QUOTATION",
    OTP_EXPIRED_FOR_REQUEST_QUOTATION: "OTP_EXPIRED_FOR_REQUEST_QUOTATION",
    FETCH_PROJECTS: "FETCH_PROJECTS",
    RESET_CARTITEM: "RESET_CARTITEM",
    RESET_REQUEST_QUOTATION_STATE: "RESET_REQUEST_QUOTATION_STATE",
    RESET_ALL_REQUEST_QUOTATION_FOR_CART: "RESET_ALL_REQUEST_QUOTATION_FOR_CART",
    FETCH_PROJECTS_IMAGE: "FETCH_PROJECTS_IMAGE",
    FAVORITE_PRODUCTS_SUCCESFULLY: "FAVORITE_PRODUCTS_SUCCESFULLY",
    FAVORITE_PRODUCTS_FAIL: "FAVORITE_PRODUCTS_FAIL",
    FAVORITE_PRODUCTS_SELECT: "FAVORITE_PRODUCTS_SELECT",
    LOGOUT: "LOGOUT",
    PROFILESTATISTICS_SUCCESFULLY: "PROFILESTATISTICS_SUCCESFULLY",
    PROFILESTATISTICS_FAIL: "PROFILESTATISTICS_FAIL",
    UPDATE_PASSWORD_FAIL: "UPDATE_PASSWORD_FAIL",
    UPDATE_PASSWORD_SUCCESSFULLY: "UPDATE_PASSWORD_SUCCESSFULLY",
    INVALID_UPDATE_PASSWORD: "INVALID_UPDATE_PASSWORD",
    UPDATE_PASSWORD_STATE_RESET: "UPDATE_PASSWORD_STATE_RESET",
    MYQUOTATIONLISTBYUSERID_SUCCESSFULLY: "MYQUOTATIONLISTBYUSERID_SUCCESSFULLY",
    MYQUOTATIONLISTBYUSERID_FAIL: "MYQUOTATIONLISTBYUSERID_FAIL",
    QUOTATIONPARTIALDETAILBYID_SUCCESSFULLY: "QUOTATIONPARTIALDETAILBYID_SUCCESSFULLY",
    QUOTATIONPARTIALDETAILBYID_FAIL: "QUOTATIONPARTIALDETAILBYID_FAIL",
    MYREQUESTQUOTATIONDETAIL_SUCCESSFULLY: "MYREQUESTQUOTATIONDETAIL_SUCCESSFULLY",
    MYREQUESTQUOTATIONDETAIL_FAIL: "MYREQUESTQUOTATIONDETAIL_FAIL",
    GET_PROFILE_BY_ID: "GET_PROFILE_BY_ID",
    REQUESTFORDOCUMENT_SUCCESSFULLY:"REQUESTFORDOCUMENT_SUCCESSFULLY",
    REQUESTFORDOCUMENT_FAIL:"REQUESTFORDOCUMENT_FAIL",
    GET_DOCUMENTREQUEST_BY_QUOTATIONID_SUCCESSFULLY: "GET_DOCUMENTREQUEST_BY_QUOTATIONID_SUCCESSFULLY",
    GET_DOCUMENTREQUEST_BY_QUOTATIONID_FAIL: "GET_DOCUMENTREQUEST_BY_QUOTATIONID_FAIL",
    UPDATE_PROFILE_RESPONSE: "UPDATE_PROFILE_RESPONSE",
    LOADER_FOR_REQUESTFORDOCUMENT_START:"LOADER_FOR_REQUESTFORDOCUMENT_START",
    RESET_DOCUMENT_REQUEST_STATE:"RESET_DOCUMENT_REQUEST_STATE",
    GLOBAL_SEARCH_BY_PRODUCTNAME_SUCCESSFULLY:"GLOBAL_SEARCH_BY_PRODUCTNAME_SUCCESSFULLY",
    GLOBAL_SEARCH_BY_PRODUCTNAME_FAIL:"GLOBAL_SEARCH_BY_PRODUCTNAME_FAIL",
    RESET_GLOBAL_SEARCH_STATE:"RESET_GLOBAL_SEARCH_STATE",
    LOADER_FOR_REQUESTFORQUOTATION_START:"LOADER_FOR_REQUESTFORQUOTATION_START",
    RESET_PROFILE_STATE:"RESET_PROFILE_STATE",
    ADD_TO_CART_LOADER:"ADD_TO_CART_LOADER",
    ADD_TO_CART_RESET_SUCCESS:"ADD_TO_CART_RESET_SUCCESS"

}