import React, { useState, useEffect } from 'react';
import HandleFloatingInputBlur from '../Shared/custom';
import { Forgotpassword, ResetForgotPasswordState } from '../../redux/actions/forgotpasswordAction';
import { useDispatch, connect } from "react-redux";
import { ActionTypes } from '../../redux/constants/action-types';
const ForgotPasswordComponet = (props) => {
    const dispatch = useDispatch();
    const [Email, setEmail] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [btnLoader, setBtnLoader] = useState(false);
    const handleChange = (e) => {
        const Email = e.target.value;
        setEmail(Email);
    };

    const handleEmailValidation = () => {
        let Error;
        let formIsValid = true;
        if (!Email) {
            Error = "Please enter an email"
            formIsValid = false;
        }
        setBtnLoader(false);
        setEmailError(Error);
        return formIsValid;

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleEmailValidation()) {
            setBtnLoader(true);

            dispatch(Forgotpassword(Email))
                .then(() => {
                    setBtnLoader(false);
                });
        }

    }
    useEffect(() => {
        if (props.isSendLinkSuccess === false) {
            setBtnLoader(false);

        } else {
            let forgotPasswordModal = document.getElementById('forgotPasswordModel')
            forgotPasswordModal.classList.add('modal-close');
            setTimeout(() => {
                props.setOpenForgotPassword(false);
                dispatch(ResetForgotPasswordState());
            }, 1000);

            let body = document.getElementsByTagName("body")[0];
            body.classList.add("overflowauto");
            body.classList.remove('overflowhidden');
        }
    }, [props.isSendLinkSuccess]);
    return (
        <>
            {(() => {
                if (props.openForgotPassword === true) {
                    return (
                        <>
                            <form className='form' onSubmit={handleSubmit}>
                                <div class="overlay" id="accessModelOverlay" style={{ display: 'block' }}></div>
                                <div class="model userAccessModel" id="forgotPasswordModel" style={{ display: 'block' }}>
                                    <div class="modelWrapper" style={{ maxWidth: '800px' }}>

                                        <div class="accessImage changeBackground">
                                            {/* <img src="../assets/images/model_chair.png" /> */}
                                        </div>

                                        <div class="accessForm">
                                            <a href="javascript:void(0)" className="close" onClick={() => {
                                                let body = document.getElementsByTagName("body")[0];
                                                body.classList.add("overflowauto");
                                                let forgotPasswordModal = document.getElementById('forgotPasswordModel')
                                                forgotPasswordModal.classList.add('modal-close');
                                                setTimeout(() => {
                                                    props.setOpenForgotPassword(false)
                                                }, 400);
                                            }}></a>

                                            <div id="forgotPasswordFrom" style={{ display: 'block' }}>
                                                <h3>Forgot Password</h3>
                                                <p>Enter your email to recover password.</p>

                                                <div className="alert alert-success" style={{ display: props.isSendLinkSuccess === true ? 'block' : 'none' }}>
                                                    <img src="/assets/images/success-tik.svg" />
                                                    Reset password email link has been sent to your email addrees.
                                                    <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                </div>

                                                {props.errorMsg && (
                                                    <div className="alert alert-error" style={{ display: 'block' }} id='ErrorMessage'>
                                                        <img src="/assets/images/error_x.svg" />
                                                        <strong>Error!</strong>
                                                        {props.errorMsg}
                                                        <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                    </div>
                                                )}

                                                <div className="fromRow">
                                                    <div className="floatingLabelInput hasIcon">
                                                        <input type="text" className="input" value={Email} onBlur={HandleFloatingInputBlur.bind(this)} onChange={handleChange} />
                                                        <label>Enter Your Email</label>
                                                        <i className="ico email"></i>
                                                    </div>
                                                    <div className="validation"><span style={{ display: 'block' }}>{EmailError}</span> </div>
                                                </div>

                                                <div className="formButton flex BacktoLogin">
                                                    <div>
                                                        <a href="javascript:void(0);" onClick={() => {
                                                            props.setOpenForgotPassword(false)
                                                            props.openLoginModal(true);
                                                        }}>Back to Login</a>
                                                    </div>

                                                    <div className="submitBtn">
                                                        <div className="btnLoader" style={{ display: btnLoader === true ? 'block' : 'none' }}>
                                                            <span className="spinLoader"></span>
                                                        </div>
                                                        <button className='btn'>Submit</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </>
                    )
                } else {
                    return (
                        <></>
                    )
                }
            })()}
        </>
    )

}

export default connect((state, props) => (
    {
        errorMsg: state.forgotpassword.message != null ? state.forgotpassword.message : null,
        isSendLinkSuccess: state.forgotpassword.isSendLinkSuccess,
    }),
    {}
)(ForgotPasswordComponet)
// export default ForgotPasswordComponet;
