import { ActionTypes } from '../constants/action-types';
const initialState = {
    MyQuotationListByUserID: [],
    QuotationPartialDetail: null,
    RequestQuotationDetail: null,
    loading: false,
}
export const MyQuotationReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case ActionTypes.LOADER_START:
            return { ...state, loading: true };
        case ActionTypes.MYQUOTATIONLISTBYUSERID_SUCCESSFULLY:
            return { ...state, MyQuotationListByUserID: payload, loading: false };
        case ActionTypes.QUOTATIONPARTIALDETAILBYID_SUCCESSFULLY:
            return { ...state, QuotationPartialDetail: payload,loading: false };
        case ActionTypes.MYREQUESTQUOTATIONDETAIL_SUCCESSFULLY:
            return { ...state, RequestQuotationDetail: payload,loading: false };
        default:
            return state;
    }
}