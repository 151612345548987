import { ActionTypes } from '../constants/action-types';
const initialState = {
    productdetail: [],
    loading: false
}
export const productdetailReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.FETCH_PRODUCTDETAIL:
            return { ...state, productdetail: payload,loading:false };
        case ActionTypes.LOADER_START:
            return { ...state, loading: true };
        default:
            return state;
    }

}