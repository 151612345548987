import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchHeader, GlobalSearchByProductName } from '../../redux/actions/HeaderActions';

import Header from './header';

const Headerdata = (
    {
        fetchHeader,
        fetchHeaderPageData,
        cartCount, isLoggedIn, GlobalSearchByProductName, ProductByNameSearchList
    }) => {
    // const [SearchListByProductName, setSearchListByProductName] = useState(ProductByNameSearchList);
    useEffect(() => {
        fetchHeader();

    }, []);
    const SerachProduct = (value) => {
        GlobalSearchByProductName(value);
    }
    return (
        fetchHeaderPageData != null ? <Header fetchHeaderPageData={fetchHeaderPageData} cartCount={cartCount} isLoggedIn={isLoggedIn} SerachProduct={SerachProduct} ProductByNameSearchList={ProductByNameSearchList} /> : null
    )


}
export default connect((state, props) => (
    {
        fetchHeaderPageData: state.allHeader != null ? state.allHeader.Header : null,
        cartCount: state.cart.cartItems != null || state.cart.cartItems != undefined || state.cart.cartItems.length > 0 ? state.cart.cartItems.length : 0,
        //fetchHeaderPageData: state.allHeader.payload != null ? state.allHeader.payload : null,
        isLoggedIn: state.Login.isLoggedIn,
        ProductByNameSearchList: state.allHeader != null ? state.allHeader.payload : null
    }),
    { fetchHeader, GlobalSearchByProductName }
)(Headerdata)
