import '../home/css/lightslider.css';
import $ from "jquery";
import '../home/js/lightslider.js';
function slider() {
  // setTimeout(function () {
  //   $("#clientLogoSlider").lightSlider({
  //     item: 6,
  //     auto: true,
  //     loop: true,
  //     keyPress: true,
  //     controls: false,
  //     pager: false,
  //     responsive: [
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           item: 3,
  //           slideMove: 1,
  //           slideMargin: 1,
  //         }
  //       }
  //     ]
  //   });
  // }, 900);
  // // ======== Hero Banner Slider 
  // setTimeout(function () {
  //   $("#heroBannerSlider").lightSlider({
  //     item: 1,
  //     auto: false,
  //     loop: true,
  //     keyPress: true,
  //     controls: false,
  //     pager: true,
  //   });

  // }, 900);
  // // ======== Testimonial Slider

  // setTimeout(function () {
  //   $("#testimonialSlider").lightSlider({
  //     loop: true,
  //     keyPress: true,
  //     slideMargin: 50,
  //     responsive: [
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           item: 1,
  //           slideMove: 1,
  //           slideMargin: 6,
  //         }
  //       }
  //     ]
  //   });
  // }, 900);

  setTimeout(function () {
    $('#productImageGallery').lightSlider({
      gallery: true,
      item: 1,
      loop: true,
      auto: true,
      thumbItem: 5,
      slideMargin: 0,
      enableDrag: true,
      currentPagerPosition: 'left',
      onSliderLoad: function (el) {
        el.lightGallery({
          selector: '#imageGallery .lslide'
        });
      }
    });
  }, 900)
}
export default slider;