import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

// export const fetchPrivacypolicy=() => async (dispatch) => {
//     const response = await api.get("CMS/GetCMSWeb?slug=privacy-Policy")
//     console.log(response);
//     dispatch({type:ActionTypes.FETCH_DATA,payload:response.data.ResultObject});
// };


export const fetchPrivacypolicy = (
    
    ) => async dispatch => {
        try {
            const resultObject = await api.get("CMS/GetCMSWeb?slug=privacy-Policy");
            dispatch({
                type: ActionTypes.FETCH_DATA,
                payload: resultObject.data.ResultObject
            })
        }
        catch (e) {
            dispatch({
                type: ActionTypes.FETCH_DATA,
                homeExceptionLogObject: []
            })
        }
    }