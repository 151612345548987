import { ActionTypes } from '../../constants/action-types';
const initial = {
    lastProjectID: 0  
}
export const ProjectsByLastProjectID = (state = initial, { type, allProjects }) => {
    switch (type) {    
      case ActionTypes.FETCH_PROJECTS:
        // alert(allProductData.lastproductID +" ac")
        return {
          ...state,
          lastProjectID:  allProjects.lastProjectID
        }
      default:
        return state
    }
  }