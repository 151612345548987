import React, { useEffect } from 'react';
import { fetchPrivacypolicy } from '../../redux/actions/privacypolicyActions';
import PrivacypolicyComponet from './PrivacypolicyComponet';
import { connect } from 'react-redux';

const Privacypolicydata = (
    {
        fetchPrivacypolicy,
        fetchPrivacypolicyPageData,

    }) => {
    useEffect(() => {
        window.scrollTo(0,0);
        fetchPrivacypolicy();
    }, [])
    return (
        fetchPrivacypolicyPageData != null ? <PrivacypolicyComponet fetchPrivacypolicyPageData={fetchPrivacypolicyPageData} /> : null
    )
}
export default connect((state, props) => (
    {
        fetchPrivacypolicyPageData: state.allPrivacyPolicy.PrivacyPolicy!= null ? state.allPrivacyPolicy.PrivacyPolicy : null,

    }),
    { fetchPrivacypolicy }
)(Privacypolicydata)