import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';
export const RequestForDocument = (
    QuotationRequestID,Message,UserID
) => async dispatch => {
    var formData = new FormData();
    formData.append("QuotationRequestID", QuotationRequestID);
    formData.append("Message", Message);
    formData.append("UserID", UserID);  
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    dispatch({ type: ActionTypes.LOADER_FOR_REQUESTFORDOCUMENT_START });
    return api.post('DocumentRequests/RequestDocument_Insert', formData,{ headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.REQUESTFORDOCUMENT_SUCCESSFULLY,
                });
            } else {
                dispatch({
                    type: ActionTypes.REQUESTFORDOCUMENT_FAIL,
                });
            }
        }
    )
}
export const GetByQuotationRequestID = (
    QuotationRequestID,UserID
) => async dispatch => {
    var formData = new FormData();
    formData.append("QuotationRequestID", QuotationRequestID);
    formData.append("UserID", UserID);
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    return api.post('DocumentRequests/GetByQuotationRequestID', formData,{ headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.GET_DOCUMENTREQUEST_BY_QUOTATIONID_SUCCESSFULLY,
                    payload:response.data.ResultObject
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_DOCUMENTREQUEST_BY_QUOTATIONID_FAIL,
                });
            }
        }
    )
}
export function ResetDocumentRequestState() {
    return { type: ActionTypes.RESET_DOCUMENT_REQUEST_STATE }
}

