import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

export const SendOTPForRequestQuotation = (
    PhoneNo
) => async dispatch => {
    var formData = new FormData();
    formData.append("PhoneNo", PhoneNo);
    dispatch({ type: ActionTypes.LOADER_FOR_REQUESTFORQUOTATION_START });
    return api.post('Quotation/SendOTP/', formData).then(
        (response) => {
            dispatch({
                type: ActionTypes.SEND_OTP_SUCCESFULLY_FOR_REQUEST_QUOTATION,
                payload: response.data.ResultObject,
            });

        });
}

export const OTPVerfiedAndSave = (
    oldEncryptedOTPNumber,
    OTPCode, ContactName, FirmName, Email, PhoneNumber, Country, State,City, Zipcode, Address, orderMessage, quotationRequestProducts,UserID

) => async dispatch => {
    var formData = new FormData();
    formData.append("OTPCode", OTPCode);
    formData.append("oldEncryptedOTPNumber", oldEncryptedOTPNumber);
    formData.append("FirmName", FirmName);
    formData.append("ContactName", ContactName);
    formData.append("Email", Email);
    formData.append("PhoneNumber", PhoneNumber);
    formData.append("State", State);
    formData.append("City", City);
    formData.append("Country", Country);
    formData.append("Zipcode", Zipcode);
    formData.append("quotationRequestProducts", quotationRequestProducts);
    formData.append("Address", Address);
    formData.append("Message", orderMessage);
    formData.append("UserID", UserID);
    dispatch({ type: ActionTypes.LOADER_FOR_REQUESTFORQUOTATION_START });
    return api.post('Quotation/OTPVerfiedAndSave/', formData).then(
        (response) => {
            if (response.data.ResultCode === 'ERROR') {
                dispatch({
                    type: ActionTypes.OTP_VERFIED_AND_SAVE_FAIL_FOR_REQUEST_QUOTATION,
                });

            } else if (response.data.ResultCode === 'SUCCESS') {
                localStorage.removeItem("cartItems");
                dispatch({
                    type: ActionTypes.OTP_VERFIED_AND_SAVE_SUCCESFULLY_FOR_REQUEST_QUOTATION,
                });
                dispatch({ type: ActionTypes.RESET_CARTITEM });
            } else {
                dispatch({
                    type: ActionTypes.OTP_VERFIED_AND_SAVE_FAIL_FOR_REQUEST_QUOTATION,
                });
            }

        });
}
export function otpExpired() {
    return {
        type: ActionTypes.OTP_EXPIRED_FOR_REQUEST_QUOTATION,
    };
}
export const ResetRequestQuotationState = () => (dispatch) => {
    dispatch({ type: ActionTypes.RESET_REQUEST_QUOTATION_STATE });
};
export const ResetALLRequestQuotationStateForCart = () => (dispatch) => {
    dispatch({ type: ActionTypes.RESET_ALL_REQUEST_QUOTATION_FOR_CART });
};