import { ActionTypes } from '../constants/action-types';
const initialState = {
    message: null,
    isOTPSend: false,
}
export const UserValidateAccountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.USER_VALIDATE_SUCCESS:
            return {
                ...state,
                payload,
                message: null,
                isOTPSend: true
            };
        case ActionTypes.USER_VALIDATE_FAIL:
            return {
                message: null,
                isOTPSend: false

            };
        case ActionTypes.SET_MESSAGE:
            return {
                isOTPSend: false,
                message: payload
            };
        case ActionTypes.USER_LOGIN_STATE_RESET:
            return {
                state: undefined,
                message: null,
                isOTPSend: false
            };
        default:
            return state;
    }
}