import React from 'react';
import { useSelector } from "react-redux";

const ReturnpolicyComponet = () => {
    const returnpolicy = useSelector((state) => state.allReturnpolicy.ReturnPolicy);
    return (
        <div>
           <section className="wrapper">
            <div style={{paddingTop:"10px"}}>
                  
            <h2 style={{fontSize:'30px',fontWeight:'600',color: '#6E0010',textAlign:'left',marginBottom:'15px',}}>{returnpolicy.Title}</h2>
                <br/>
                <div className="box" dangerouslySetInnerHTML={{
                    __html: returnpolicy.Content
                }}>

                </div>



            </div>
            </section>
        </div>
    )
};
export default ReturnpolicyComponet;