import { connect } from "react-redux";
import SubHeader from '../Shared/subHeader';
import { fetchProjects, fetchProjectImagesByID } from "../../redux/actions/ourProjectActions";
import { IMAGE_ROOT } from '../../api-config';
import { useEffect, useState } from "react";
import { getLastProjectID } from "../product/selector";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import ImgsViewer from "react-images-viewer";
import { resources } from "../../resource";

const ProjectData = ({
    fetchProjects,
    fetchOurProjectsData,
    lastProjectID,
    loading,
    fetchProjectImagesByID,
    projectImages
}

) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currImg, setCurrImg] = useState(0);

    function CloseImgsViewer() {
        setCurrImg(0);
        setIsOpen(false);
    }
    function GotoPrev() {
        setCurrImg(currImg - 1);
    }

    function GotoNext() {
        setCurrImg(currImg + 1);
    }

    function GotoImg(index) {
        setCurrImg(index);
    }

    const pageSize = 10;
    const [goingUp, setGoingUp] = useState(false);
    const [sliderClose, setSliderClose] = useState(false);

    const ImageClick = (projectID) => {
        setSliderClose(false);
        fetchProjectImagesByID(projectID);
        setCurrImg(0);
        setIsOpen(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchProjects(0, pageSize);

    }, [])

    useEffect(() => {
        const handleScroll = (e) => {
            if ((e.target.documentElement.scrollTop >= e.target.documentElement.scrollHeight - window.innerHeight - 600) && !goingUp) {
                setGoingUp(true);

                if (lastProjectID > 0 && !loading) {
                    fetchProjects(lastProjectID, pageSize);
                }
            }
            else {
                setGoingUp(false);
            }
        };
        window.addEventListener("scroll", handleScroll, { behavior: "smooth" });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [goingUp]);

    return (
        <>
            <SubHeader CurrentPageName={'Projects'} ></SubHeader>
            {(() => {
                return (
                    <section className="projectListingPage wrapper">

                        <section className="projectShowcase">
                            {(() => {
                                if (fetchOurProjectsData != null && fetchOurProjectsData.length > 0 && !loading) {
                                    return (
                                        <>
                                            <ul className="projectList">
                                                {
                                                    fetchOurProjectsData.map((data, key) => {
                                                        // var imagePath = IMAGE_ROOT + resources.PROJECT_IMG_URL + data.ImageName

                                                        return (

                                                            <li key={Math.random()} id={key}>
                                                                <Link onClick={() => { ImageClick(data.ProjectID) }} >
                                                                    <div className="thumb">
                                                                        {/* <img src={imagePath}></img> */}
                                                                        <img
                                                                            sizes="(max-width: 320px) 315px,(max-width: 425px) 215px,(max-width: 768px) 215px,(max-width: 1024px) 215px,(max-width: 1440px) 315px"
                                                                            srcset={`${IMAGE_ROOT}${resources.PROJECT_IMG_URL}${resources.IMAGEVERSIONS.THUMBNAILIMAGERESIZER + data.ImageName} 80w,
                                                                            ${IMAGE_ROOT}${resources.PROJECT_IMG_URL}${resources.IMAGEVERSIONS.SMALLIMAGERESIZER + data.ImageName} 215w,
                                                                            ${IMAGE_ROOT}${resources.PROJECT_IMG_URL}${resources.IMAGEVERSIONS.MEDIUMIMAGERESIZER + data.ImageName} 315w`}
                                                                            src={`${IMAGE_ROOT}${resources.PROJECT_IMG_URL}${data.ImageName}`}
                                                                            alt="Hospitality Image" />
                                                                    </div>
                                                                    <span className='firmName'>
                                                                        <label title={data.FirmName}>{data.FirmName}</label>
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    )
                                }
                                else if (loading) {
                                    return (
                                        <ul className="projectList">
                                            {
                                                [...Array(4)].map((ignore, index) => {
                                                    return (
                                                        <li key={Math.random()}>
                                                            <Skeleton width={408} height={415}>

                                                            </Skeleton>
                                                        </li>
                                                    )

                                                })
                                            }
                                        </ul>
                                    )
                                }
                            })()}
                        </section>
                        {(() => {

                            return (
                                <div className="project-image-wrapper">
                                    {(() => {
                                        if (projectImages != null && !sliderClose) {
                                            const IMG_SET = [];
                                            return (
                                                <>
                                                    {
                                                        projectImages.map((data, key) => {
                                                            var projectImgPath = IMAGE_ROOT + resources.PROJECT_IMG_URL + data.ImageName;
                                                            var item = {};
                                                            item["src"] = projectImgPath;
                                                            IMG_SET.push(item);
                                                        })
                                                    }
                                                    <ImgsViewer
                                                        imgs={IMG_SET}
                                                        currImg={currImg}
                                                        isOpen={isOpen}
                                                        onClickPrev={GotoPrev}
                                                        onClickNext={GotoNext}
                                                        onClose={CloseImgsViewer}
                                                        showThumbnails={true}
                                                        onClickThumbnail={GotoImg}
                                                    />

                                                </>
                                            )
                                        }
                                    })
                                        ()}

                                </div>
                            )

                        })
                            ()}

                    </section>
                )
            })()}
        </>
    )

}
export default connect((state, props) => (
    {
        fetchOurProjectsData: state.ourProjectReducer.ProjectsReducer.allProjects != null ? state.ourProjectReducer.ProjectsReducer.allProjects.projects : null,
        lastProjectID: getLastProjectID(state, props),
        loading: state.ourProjectReducer.ProjectsReducer.loading,
        projectImages: state.ourProjectReducer.ProjectsReducer.projectImages != null ? state.ourProjectReducer.ProjectsReducer.projectImages : null
    }), { fetchProjects, fetchProjectImagesByID }
)(ProjectData)