import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

export const FETCH_PRODUCTDETAIL = 'FETCH_PRODUCTDETAIL'

export const fetchproductdetail = (
    Slug,
    UserID,
    isClickedOnFavorite
) => async dispatch => {
    try {
        if (isClickedOnFavorite) {
            const resultObject = await api.get(`Product/ProductDetailForWeb/${Slug}/${UserID}`);
            dispatch({
                type: ActionTypes.FETCH_PRODUCTDETAIL,
                payload: resultObject.data.ResultObject
            })
        } else {
            dispatch({ type: ActionTypes.LOADER_START });
            const resultObject = await api.get(`Product/ProductDetailForWeb/${Slug}/${UserID}`);
            dispatch({
                type: ActionTypes.FETCH_PRODUCTDETAIL,
                payload: resultObject.data.ResultObject
            })
        }
    }
    catch (e) {
        dispatch({
            type: ActionTypes.FETCH_PRODUCTDETAIL,
            homeExceptionLogObject: [],
        })
    }
}