import React from 'react';
import { Link } from 'react-router-dom';
const AbountComponet = ({ AboutUsData }) => {
    return (
        <>
            <section class="quickAboutUs-wrap">
                <section class="wrapper quickAboutUs">
                    <div class="info" data-aos="zoom-in-right" data-aos-easing="ease-in-sine">
                        {/* <label>About Us</label>
                        <h4 class="info-head">Unimaple Furniture</h4> */}
                        <p dangerouslySetInnerHTML={{ __html: AboutUsData }}></p>
                        <Link className="hospitality-btn" to="/aboutus">Learn More</Link>
                    </div>
                    <div class="img" data-aos="zoom-in-left" data-aos-easing="ease-in-sine">
                        <img src="/assets/images/about-us-img.webp" alt=""></img>
                    </div>
                </section>
            </section>
        </>
    )

};
export default AbountComponet;