import apiConfig from "../../api-config";
import { ActionTypes } from "../constants/action-types"

export const fetchProjects = (
    lastProjectID,
    pageSize,
    isNew = true
) => async dispatch => {
    try {
        dispatch({ type: ActionTypes.LOADER_START });
        await apiConfig.get(`Project/GetProjectsForWeb/${lastProjectID}/${pageSize}`).then((resultObject) => {

            if (resultObject.data.ResultObject.length > pageSize) {
                resultObject.data.ResultObject = resultObject.data.ResultObject.slice(0, -1)
            }

            dispatch({
                type: ActionTypes.FETCH_PROJECTS,
                allProjects: {
                    projects: resultObject.data.ResultObject,
                    lastProjectID: resultObject.data.ResultObject.length > 0 && resultObject.data.ResultObject.length >= pageSize ? resultObject.data.ResultObject[resultObject.data.ResultObject.length - 1].ProjectID : 0,
                    // isNew: isNew,
                    projectCount: resultObject.data.ResultObject.length
                },
                projectImages: null
            })
        })
    }
    catch (e) {
        dispatch({
            type: ActionTypes.FETCH_PROJECTS,
        })
    }
}

export const fetchProjectImagesByID = (
    projectIDForImage
) => async dispatch => {
    try {
        if (projectIDForImage == 0) {
            dispatch({
                type: ActionTypes.FETCH_PROJECTS_IMAGE,
                projectImages: null
            })
        }
        else {
            await apiConfig.get(`Project/GetProjectImagesByID/${projectIDForImage}`).then((resultObject) => {
                dispatch({
                    type: ActionTypes.FETCH_PROJECTS_IMAGE,
                    projectImages: resultObject.data.ResultObject
                })
            })
        }
    }
    catch (e) {
        dispatch({
            type: ActionTypes.FETCH_PROJECTS_IMAGE,
        })
    }
}
