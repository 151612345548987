import React, { useEffect } from 'react';
import { IMAGE_ROOT } from '../../api-config';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { resources } from '../../resource';

const HomeComponet = ({ sliderData, loader }) => {
    var settings = {
        dots: false,
        infinite: true,
        // speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        fade: true,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    };

    

    return (
        <section className="heroSlider" id='homeSlider'>

            <Slider {...settings}>
                {(() => {
                    if (sliderData != null && sliderData.length > 0 && loader != true) {
                        return (
                            sliderData.map((data, key) => {
                                var imgpath = IMAGE_ROOT + resources.HOMESLIDER_IMG_URL + data.FileName;
                                return (
                                    <div>
                                        {/* <img src={imgpath}></img> */}
                                        <img
                                            sizes="(max-width: 2000px) 100vw, 2000px"
                                            srcset={`${IMAGE_ROOT}${resources.HOMESLIDER_IMG_URL}${resources.IMAGEVERSIONS.THUMBNAILIMAGERESIZER + data.FileName} 320w,
                                                    ${IMAGE_ROOT}${resources.HOMESLIDER_IMG_URL}${resources.IMAGEVERSIONS.SMALLIMAGERESIZER + data.FileName} 768w,
                                                    ${IMAGE_ROOT}${resources.HOMESLIDER_IMG_URL}${resources.IMAGEVERSIONS.MEDIUMIMAGERESIZER + data.FileName} 1440w,
                                                    ${IMAGE_ROOT}${resources.HOMESLIDER_IMG_URL}${resources.IMAGEVERSIONS.LARGEIMAGERESIZER + data.FileName} 2000w`}
                                            src={`${IMAGE_ROOT}${resources.HOMESLIDER_IMG_URL}${data.FileName}`}
                                            alt="">
                                        </img>
                                        <div className='sliderOverlay'>
                                            <div className="box">
                                                <div className='header-content'>
                                                    <div className='line'></div>
                                                    <h3 className="slick-slide-title">{data.Title}</h3>
                                                    <h4 className="slick-slide-label">{data.SubTitle}</h4>
                                                    <Link to={'/products'} href='javascript:void(0);' className='slick-shop-now'>
                                                        Shop Now
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )
                    } else {
                        return (
                            <div className='skeleton-box'>
                            </div>
                        )

                    }
                })()}

            </Slider>
        </section>
    )

};
export default HomeComponet;