import { ActionTypes } from '../constants/action-types';
const initialState = {
    message: null,
    isSendLinkSuccess: false,
    isValiadteResetcode: false,
    isUpdateNewPassword: false
}
export const ForgotpasswordReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.FORGOTPASSWORD_SUCCESS:
            return {
                message: payload,
                isSendLinkSuccess: true,
                isValiadteResetcode: false,
                isUpdateNewPassword: false
            };
        case ActionTypes.FORGOTPASSWORD_FAIL:
            return {
                ...state, message: payload, isSendLinkSuccess: false, isValiadteResetcode: false, isUpdateNewPassword: false
            };
        case ActionTypes.VALIDATE_RESET_CODE_SUCCESS:
            return {
                ...state, isSendLinkSuccess: false, isValiadteResetcode: true, message: null, isUpdateNewPassword: false
            };
        case ActionTypes.VALIDATE_RESET_CODE_FAIL:
            return {
                ...state, isSendLinkSuccess: false, isValiadteResetcode: false, message: payload, isUpdateNewPassword: false
            };
        case ActionTypes.UPDATE_NEW_PASSWORD_FAIL:
            return {
                ...state, isSendLinkSuccess: false, isValiadteResetcode: false, message: null, isUpdateNewPassword: false
            };
        case ActionTypes.UPDATE_NEW_PASSWORD_SUCCESS:
            return {
                ...state, isSendLinkSuccess: false, isValiadteResetcode: true, message: null, isUpdateNewPassword: true
            };
        case ActionTypes.SEND_FORGOTPASSWORD_LINK_SUCCESSFULLY:
            return {
                ...state, isSendLinkSuccess: false
            };

        case ActionTypes.FORGOTPASSWORD_STATE_RESET:
            return {
                state: undefined,
                message: null,
                isSendLinkSuccess: false,
                isValiadteResetcode: false,
                isUpdateNewPassword: false
            };
        default:
            return state;
    }

}