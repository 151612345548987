import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import SubHeader from '../Shared/subHeader';
import { IMAGE_ROOT } from '../../api-config';
import HandleFloatingInputBlur from '../Shared/custom';
import { SendOTPForRequestQuotation, OTPVerfiedAndSave, otpExpired, ResetRequestQuotationState } from '../../redux/actions/requestForQuotationAction';
import { Link } from 'react-router-dom';
import { ResetCartItem } from '../../redux/actions/addToCartAction';
import { Countries } from "../Shared/countries";
import { ProfileDetails } from '../../redux/actions/profileAction';
import { resources } from '../../resource';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const RequestForQuotation = (
    {
        cartItems, isSendOTP, encryptedOTP, isOTPVerfiedAndSave, loading,
        SendOTPForRequestQuotation, OTPVerfiedAndSave, otpExpired, isSuccefullySave, ResetCartItem, ResetRequestQuotationState, ProfileDetails, isLoggedIn, profile
    }
) => {

    var initialState = {
        firstName: profile != null ? profile.FullName.split(" ")[0] : '',
        lastName: profile != null ? profile.FullName.split(" ")[1] : '',
        companyName: '',
        phone: profile != null ? profile.ContactNo : '',
        email: profile != null ? profile.Email : '',
        country: profile != null ? profile.Country : '',
        state: profile != null ? profile.State : '',
        city: profile != null ? profile.City : '',
        pincode: profile != null ? profile.Zipcode : '',
        address1: profile != null ? profile.Address : '',
        address2: '',
        orderMessage: null,

    }

    const user = JSON.parse(localStorage.getItem("user"));
    const [values, setValues] = useState(
        initialState
    );
    const quotationRequestProductsData = []
    const history = useHistory();
    const dispatch = useDispatch();
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(false);
    const [errors, setErrors] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const [isOTPPage, setIsOTPPage] = useState(false);
    // const [encryptedOTPCode, setEncryptedOTPCode] = useState(null);
    const [OTP, setOTP] = useState("");
    const [OTPError, setOTPError] = useState("");
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const [contactNo, setContactNo] = useState();
    const [countryCode, setCountryCode] = useState();

    const RequestForQuotationValidateInfo = (values) => {

        let errors = {};
        let formIsValid = true;
        setBtnLoader(true);


        //name

        if (!values.lastName.trim()) {
            errors.lastName = 'Last Name is required';
            formIsValid = false
        }
        if (!values.firstName.trim()) {
            errors.firstName = 'First Name is required';
            formIsValid = false
        }

        // if (!values.companyName.trim()) {
        //     errors.companyName = 'Firm Name is required';
        //     formIsValid = false;
        // }

        //Contact No
        if (!contactNo) {
            errors.phone = 'Phone No is required';
            formIsValid = false;
        } else if (!/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm.test(contactNo)) {
            errors.phone = 'Phone No is invalid';
            formIsValid = false;
        }

        //email
        if (!values.email) {
            errors.email = 'Email is required';
            formIsValid = false;
        } else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
            errors.email = 'Email address is invalid';
            formIsValid = false;
        }

        // country
        if (!values.country.trim()) {
            errors.country = 'Country is required';
            formIsValid = false;
        }

        // State

        if (!values.city.trim()) {
            errors.city = 'City is required';
            formIsValid = false
        }
        if (!values.state.trim()) {
            errors.state = 'State is required';
            formIsValid = false
        }

        //Address
        if (!values.address1.trim()) {
            errors.address1 = 'Address is required';
            formIsValid = false;
        }
        //pincode
        if (!values.pincode) {
            errors.pincode = 'Pincode is required';
            formIsValid = false;
        } else if (values.pincode.length > 5) {
            errors.pincode = 'Pincode needs to be 5 characters';
            formIsValid = false;
        } else if (!/^[0-9]{5}(?:-[0-9]{4})?$/i.test(values.pincode)) {
            errors.pincode = 'Pincode is invalid';
            formIsValid = false;
        }

        setErrors(errors);
        setBtnLoader(false);
        return formIsValid;
    }
    const handleSubmit = e => {
        console.log(quotationRequestProductsData)
        e.preventDefault();
        // SendOTPForRequestQuotation(values.phone)
        if (RequestForQuotationValidateInfo(values, contactNo)) {
            SendOTPForRequestQuotation(contactNo)
        }
    }
    useEffect(() => {
        setValues(initialState)
        setContactNo(profile != null ? profile.ContactNo : '');
    }, [profile])

    useEffect(() => {
        if (isLoggedIn)
            ProfileDetails();

    }, [isLoggedIn]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Timer start
    useEffect(() => {
        let timerId;

        if (runTimer) {
            // setEncryptedOTPCode(encryptedOTP);
            setCountDown(60 * 2);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer]);
    useEffect(() => {
        if (countDown < 0 && runTimer) {
            dispatch(otpExpired());
            console.log("expired");
            document.getElementById("TimerCounter").style.display = "none"
            document.getElementById("Resendotp").style.display = "block"
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);
    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);
    // Timer end

    useEffect(() => {
        if (isSendOTP === true) {
            setRunTimer((t) => !t);
            setIsOTPPage(true);
        }
    }, [isSendOTP]);
    const onResendOtpClick = () => {
        SendOTPForRequestQuotation(values.phone)
        setRunTimer((t) => !t);
        setIsOTPPage(true);
        document.getElementById("TimerCounter").style.display = "block";
        document.getElementById("Resendotp").style.display = "none";
    }

    const handleOTPValidation = () => {
        let Error;
        let formIsValid = true;
        if (!OTP) {
            Error = "Please enter OTP"
            formIsValid = false;
        }
        setOTPError(Error);
        setBtnLoader(false);
        return formIsValid;

    }
    const onChangeOTP = (e) => {
        const OTP = e.target.value;
        setOTP(OTP);
    };
    const handleOTPSubmit = e => {
        e.preventDefault();
        if (handleOTPValidation()) {
            cartItems.map((data, key) => {
                quotationRequestProductsData.push({
                    ProductID: data.ProductID,
                    Quantity: data.count,
                    customizeData: Object.keys(data.customizeData).length > 0 ? data.customizeData.toString() : ""
                })
            })
            let fullName = values.firstName + " " + values.lastName;
            OTPVerfiedAndSave(encryptedOTP, OTP, fullName, values.companyName, values.email, contactNo, values.country, values.state, values.city, values.pincode, values.address1 + values.address2, values.orderMessage, JSON.stringify(quotationRequestProductsData), user != null ? user.UserID : null);
        }
    }
    useEffect(() => {
        if (isSuccefullySave === true) {
            setCountDown(0);
            setRunTimer(false);
            ResetRequestQuotationState();
            setTimeout(() => {
                localStorage.removeItem("cartItems");
                ResetRequestQuotationState();
            }, 3000);
        }

    }, [isSuccefullySave]);


    const selectElement = document.querySelector('.PhoneInputCountrySelect');
    let countryCodeOnChange = () => {
        selectElement.addEventListener('change', (e) => {
            e.stopImmediatePropagation();
            setCountryCode(e.target.value.toString())
            setContactNo('');

        });
        selectElement.removeEventListener("change", countryCodeOnChange);
    }
    if (selectElement != null) {
        countryCodeOnChange();
    }

    return (
        <>
            {(() => {
                if (cartItems.length > 0 && isOTPPage != true && isSuccefullySave != true) {
                    return (
                        <>
                            <SubHeader CurrentPageName={'Product Quotation'} />

                            <section className="addQuotation">
                                <section className="wrapper">

                                    <section className="quoteInfo">
                                        <h3>Your Quote Items</h3>
                                        <div id='productList'>
                                            <ul>
                                                {
                                                    cartItems.map((data, key) => {
                                                        var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ImageName;
                                                        return (
                                                            <>
                                                                <li>
                                                                    <div className="title">
                                                                        <h4><a href="#">{data.ProductName}</a></h4>
                                                                    </div>
                                                                    <div className="info">
                                                                        <div className="image">
                                                                            <a href="#"><img src={imgpath} /></a>
                                                                        </div>
                                                                        <div className="attributes">
                                                                            <p>{data.ProductDescription.length > 100 ? data.ProductDescription.substring(0, 35) + "..." : data.ProductDescription}</p>
                                                                            <div className="qtyPrice">
                                                                                <span>Qty: {data.count}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <img src={imgpath} /><span>{data.ProductName}</span><h4 style={{ position: 'absolute', top: '15px', right: '0px' }}>x{data.count}</h4> */}
                                                                </li>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </div>
                                    </section>

                                    <section className="form">
                                        <form onSubmit={handleSubmit} >

                                            <div className="fromRowWithLabel">
                                                <div className="lbl">Name<span>*</span></div>
                                                <div className="val">
                                                    <div className="splitField" style={{ width: '80%' }}>
                                                        <input type="text" className="input " placeholder="First name" name='firstName' value={values.firstName} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} maxLength='50' />
                                                        <input type="text" className="input " placeholder="Last name" name='lastName' value={values.lastName} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} maxLength='50' />
                                                    </div>
                                                    <div className="validation splitField" style={{ width: '80%' }}>
                                                        <div>{errors.firstName && <span style={{ display: 'block' }}>{errors.firstName}</span>}</div>
                                                        <div>{errors.lastName && <span style={{ display: 'block' }}>{errors.lastName}</span>}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="fromRowWithLabel">
                                                <div className="lbl">Mobile Number<span>*</span></div>
                                                <div className="val">
                                                    {/* <input type="text" className="input" maxLength='20' placeholder="Enter mobile number" style={{ width: '80%' }} value={values.phone} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} name='phone' /> */}

                                                    <PhoneInput
                                                        className='input' name='phone'
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry={countryCode}
                                                        value={contactNo} onChange={setContactNo}
                                                        style={{ padding: '5px 5px !important', width: "80%" }}
                                                    />
                                                    <div className="validation">
                                                        {
                                                            errors.phone && <span style={{ display: 'block' }}>{errors.phone}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="fromRowWithLabel">
                                                <div className="lbl">Email<span>*</span></div>
                                                <div className="val">
                                                    <input type="text" className="input" placeholder="Enter your email" style={{ width: '80%' }} name='email' value={values.email} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                    <div className="validation">
                                                        {
                                                            errors.email && <span style={{ display: 'block' }}>{errors.email}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="fromRowWithLabel">
                                                <div className="lbl">Firm Name</div>
                                                <div className="val">
                                                    <input type="text" className="input" placeholder="Enter firm name" style={{ width: '80%' }} name='companyName' value={values.companyName} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                    <div className="validation">
                                                        {
                                                            errors.companyName && <span style={{ display: 'block' }}>{errors.companyName}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="fromRowWithLabel">
                                                <div className="lbl">Address1<span>*</span></div>
                                                <div className="val">
                                                    <input type="text" className="input" placeholder="Enter address" style={{ width: '80%' }} name='address1' value={values.address1} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                    <div className="validation">
                                                        {
                                                            errors.address1 && <span style={{ display: 'block' }}>{errors.address1}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="fromRowWithLabel">
                                                <div className="lbl">Address2</div>
                                                <div className="val">
                                                    <input type="text" className="input" placeholder="Enter address" style={{ width: '80%' }} name='address2' value={values.address2} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                    <div className='validation'>

                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div class="fromRowWithLabel">
                                                <div class="lbl">State / Pin code<span>*</span></div>
                                                <div class="val">
                                                    <div class="splitField" style={{ width: '80%' }}>
                                                        <input type="text" className="input" placeholder="Enter State" name='state' value={values.state} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                        <input type="text" className="input" placeholder="Enter Pincode" name='pincode' value={values.pincode} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                    </div>
                                                    <div className="validation">
                                                        {
                                                            errors.state && <span style={{ display: 'block' }}>{errors.state}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="fromRowWithLabel">
                                                <div className="lbl">City / State / Pincode<span>*</span></div>
                                                <div className="val">
                                                    <div className="column-3-split" style={{ width: "80%" }}>
                                                        <input type="text" name="city" className="input" value={values.city} placeholder="Enter City" onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                        <input type="text" name="state" id="state" value={values.state} className="input" placeholder="Enter State" onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                        <input type="text" name="pincode" maxLength={6} id="pincode" value={values.pincode} className="input" placeholder="Enter Pincode" onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                    </div>
                                                    <div className="validation column-3-split" style={{ width: "80%" }}>
                                                        <div>{errors.city && <span style={{ display: 'block' }}>{errors.city}</span>}</div>
                                                        <div>{errors.state && <span style={{ display: 'block' }}>{errors.state}</span>}</div>
                                                        <div>{errors.pincode && <span style={{ display: 'block' }}>{errors.pincode}</span>}</div>
                                                    </div>

                                                    {/* <div className="validation"><span style={{ display: "block" }}>Validation message in this span</span></div> */}
                                                </div>
                                            </div>
                                            <div className="fromRowWithLabel">
                                                <div className="lbl">Country<span>*</span></div>
                                                <div className="val">
                                                    <select className="input" style={{ width: '80%' }} name='country' value={values.country} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)}>
                                                        <option value="">Select Country</option>
                                                        {
                                                            Countries.countries.map((e, key) => {
                                                                return <option value={e.code} >{e.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <div className="validation">
                                                        {
                                                            errors.country && <span style={{ display: 'block' }}>{errors.country}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="fromRowWithLabel">
                                                <div className="lbl">City<span>*</span></div>
                                                <div className="val">
                                                    <input type="text" className="input" placeholder="Enter address" style={{ width: '80%' }} name='city' value={values.city} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                                    <div className="validation">
                                                        {
                                                            errors.city && <span style={{ display: 'block' }}>{errors.city}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="fromRowWithLabel">
                                                <div className="lbl">Message<span></span></div>
                                                <div className="val">
                                                    <textarea className="input" placeholder="Enter your message here..." style={{ width: '80%', height: '150px' }}
                                                        name='orderMessage' value={values.orderMessage} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)}></textarea>

                                                </div>
                                            </div>

                                            <br />
                                            <div className="fromRowWithLabel">
                                                <div className="lbl"></div>
                                                <div className="submitBtn val requestQuotationBtn">

                                                    <span style={{ marginRight: '25px' }}>
                                                        <div className="btnLoader" style={{ display: loading === true ? 'block' : 'none', borderRadius: 0 }}>
                                                            <span className="spinLoader small"></span>
                                                        </div>
                                                        <button className='product-btn' value="Request a Quote" id='sunmit' type="submit">Request a Quote</button>
                                                    </span>

                                                </div>
                                            </div>

                                        </form>
                                    </section>


                                </section>
                            </section >

                        </>)
                } else if (isOTPPage === true && isSuccefullySave != true) {
                    return (
                        <>
                            <section class="innerPageBanner">
                                <section class="wrapper">
                                </section>
                            </section>
                            <section className="wrapper newpassword">
                                <form className='form' onSubmit={handleOTPSubmit}>
                                    <div className="card newpasswordFrom">
                                        {/* <strong>New Password</strong> */}
                                        <h1>OTP Verification</h1>

                                        <>
                                            <div className="fromRow">
                                                <div className="alert alert-error" style={{ display: isOTPVerfiedAndSave === false ? 'block' : 'none' }} id='ErrorMessage'>
                                                    <img src="/assets/images/error_x.svg" />
                                                    {/* <strong>Error!</strong> */}
                                                    OTP invalid/expired.
                                                    <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                </div>
                                            </div>
                                            <div className="fromRow">
                                                <div className="floatingLabelInput">
                                                    <input type="password" className="input" onBlur={HandleFloatingInputBlur.bind(this)} value={OTP} onChange={onChangeOTP} name="OTP" />
                                                    <label>OTP</label>
                                                </div>
                                                <div className="validation"><span style={{ display: 'block' }}>{OTPError}</span></div>
                                                <div style={{ padding: '10px', display: 'block' }} id="TimerCounter">Resend Otp in {minutes}:{seconds}</div>
                                                <div style={{ padding: '10px', display: 'none' }} id="Resendotp"><a onClick={() => { onResendOtpClick() }}>Resend OTP</a></div>
                                            </div>
                                            <div className="formButton">
                                                <div className="submitBtn">
                                                    <div className="btnLoader" style={{ display: loading === true ? 'block' : 'none' }}>
                                                        <span className="spinLoader"></span>
                                                    </div>
                                                    <button value="submit" id='sunmit' class='btn' type="submit">Submit</button>
                                                </div>
                                            </div>
                                        </>

                                    </div>
                                </form>
                            </section>
                        </>
                    )
                } else if (isSuccefullySave === true) {
                    return (
                        <>
                            <section class="innerPageBanner">
                                <section class="wrapper">
                                </section>
                            </section>
                            <section className="wrapper newpassword">
                                <div className="card newpasswordFrom">
                                    <div style={{ fontSize: '20px', textAlign: 'center' }}>Successfully send request for quotation</div>
                                    <img src="/assets/images/success-right.gif" style={{ width: '100%' }} />
                                    <div><Link to="/">Back To Home</Link></div>
                                </div>
                            </section>
                        </>
                    )
                }
                else {
                    history.push('');
                }
            })()}
        </>
    )
}

export default connect((state, props) => (
    {

        cartItems: state.cart.cartItems,
        isSendOTP: state.requestQuotation.isSendOTP,
        encryptedOTP: state.requestQuotation.encryptedOTP != null ? state.requestQuotation.encryptedOTP : null,
        isOTPVerfiedAndSave: state.requestQuotation.isOTPVerfiedAndSave,
        isSuccefullySave: state.requestQuotation.isSuccefullySave,
        loading: state.requestQuotation.loading,
        isLoggedIn: state.Login.isLoggedIn,
        profile: state.profile.ProfileDetails,

    }),
    { SendOTPForRequestQuotation, OTPVerfiedAndSave, otpExpired, ResetCartItem, ResetRequestQuotationState, ProfileDetails }
)(RequestForQuotation)