import React, { useState, useEffect,useLayoutEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import HandleFloatingInputBlur from '../Shared/custom';
import { ValidateResetCode, UpdateNewPassword } from '../../redux/actions/forgotpasswordAction';
import { useParams } from 'react-router-dom';
import { ActionTypes } from '../../redux/constants/action-types';
const NewPasswordComponent = ({
    ValidateResetCode, isValiadteResetcode, errorMsg, UpdateNewPassword, isUpdateNewPassword
}) => {
    const dispatch = useDispatch();
    const { resetCode } = useParams();

    const [values, setValues] = useState({
        password: '',
        confirmpassword: '',
    })
    const [errors, setErrors] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const registerValidateInfo = (values) => {

        let errors = {};
        let formIsValid = true;
        setBtnLoader(true);
        //password
        if (!values.password) {
            errors.password = 'Password is required';
            formIsValid = false;
        } else if (values.password.length < 6) {
            errors.password = 'Password needs to be 6 characters or more';
            formIsValid = false;
        }

        //confirmpassword
        if (!values.confirmpassword) {
            errors.confirmpassword = 'Confirm Password is required';
            formIsValid = false;
        } else if (values.confirmpassword !== values.password) {
            errors.confirmpassword = 'Passwords do not match';
            formIsValid = false;
        }
        setErrors(errors);
        setBtnLoader(false);
        return formIsValid;
    }
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };
    const handleSubmit = e => {
        e.preventDefault();
        if (registerValidateInfo(values)) {
            setBtnLoader(true);
            dispatch(UpdateNewPassword(resetCode, values.password)).then(() => {
            })
        }
    }
    useLayoutEffect(() => {
        if (isUpdateNewPassword === true) {

            setBtnLoader(false);
            setValues({
                password: '',
                confirmpassword: '',
            });

        }
    }, [isUpdateNewPassword]);
    useLayoutEffect(() => {
        ValidateResetCode(resetCode);
    }, []);
    return (
        <>
            {(() => {
                if (isValiadteResetcode === true) {
                    return (
                        <>
                            <section class="innerPageBanner">
                                <section class="wrapper">
                                </section>
                            </section>


                            <section className="wrapper newpassword">
                                <form className='form' onSubmit={handleSubmit}>
                                    <div className="card newpasswordFrom">
                                        {/* <strong>New Password</strong> */}
                                        <h1>New Password</h1>

                                        <>
                                            <div class="fromRow alert alert-success" style={{ display: isUpdateNewPassword === true ? 'block' : 'none' }}>
                                                <img src="/assets/images/success-tik.svg" />
                                                <strong>Success! </strong>
                                                Password change successfully.
                                                <button type="button" class="close"><span><i class="fa fa-times"></i></span></button>
                                            </div>
                                            <div className="fromRow">
                                                <div className="floatingLabelInput">
                                                    <input type="password" className="input" onBlur={HandleFloatingInputBlur.bind(this)} value={values.password} onChange={handleChange} name="password" />
                                                    <label>New Password</label>
                                                </div>
                                                {errors.password && <div class="validation"><span style={{ display: 'block' }}>{errors.password}</span> </div>}
                                            </div>

                                            <div className="fromRow">
                                                <div className="floatingLabelInput">
                                                    <input type="password" className="input" onBlur={HandleFloatingInputBlur.bind(this)} name="confirmpassword" value={values.confirmpassword} onChange={handleChange} />
                                                    <label>Confirm Password</label>
                                                </div>
                                                {errors.confirmpassword && <div class="validation"><span style={{ display: 'block' }}>{errors.confirmpassword}</span> </div>}
                                            </div>

                                            <div className="formButton">
                                                <div className="submitBtn">
                                                    <div className="btnLoader" style={{ display: btnLoader === true ? 'block' : 'none' }}>
                                                        <span className="spinLoader"></span>
                                                    </div>
                                                    <button value="submit" id='sunmit' class='btn' type="submit">Submit</button>
                                                </div>
                                            </div>
                                        </>

                                    </div>
                                </form>
                            </section>
                        </>
                    )
                } else if (isValiadteResetcode === false) {
                    return (
                        <>
                            <section class="innerPageBanner">
                                <section class="wrapper">
                                </section>
                            </section>
                            <section className="wrapper newpassword">
                                <form className='form'>
                                    <div className="card newpasswordFrom">
                                        {/* <strong>New Password</strong> */}
                                        <h1>New Password</h1>

                                        <>
                                            <div class="alert alert-error" style={{ display: 'block' }}>
                                                <img src="/assets/images/error_x.svg" />
                                                <strong> Error!  </strong>
                                                Forgot password link invalid/expired.
                                                <button type="button" class="close"><span><i class="fa fa-times"></i></span></button>
                                            </div>
                                        </>

                                    </div>
                                </form>
                            </section>
                        </>
                    )
                }
            }
            )()}
        </>
    )
}
export default connect((state, props) => (
    {
        isValiadteResetcode: state.forgotpassword.isValiadteResetcode,
        errorMsg: state.forgotpassword.message != null ? state.forgotpassword.message : null,
        isUpdateNewPassword: state.forgotpassword.isUpdateNewPassword
    }),
    { ValidateResetCode, UpdateNewPassword }
)(NewPasswordComponent)
