import React from 'react';
const NotFoundComponent = () => {
    return (
        <>
            <section className="wrapper">
                <img src='/assets/images/notfound.png' className='pageNotFoundImage'/>
            </section>
        </>
    )
};
export default NotFoundComponent;