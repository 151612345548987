import "./App.css";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// import Header from './components/Shared/header';
import Footer from './components/Shared/footer';
import HomeListing from './components/home/homeListing';
import aboutUsdata from './components/aboutus/aboutusdata';
import Privacypolicydata from './components/privacypolicy/privacypolicydata';
import returnpolicydata from './components/returnpolicy/returnpolicydata';
import termsconditiondata from './components/termscondition/termsconditiondata';
import ContactUsComponent from "./components/contactus/ContactUsComponent";
import Productdata from "./components/product/productListing";
import Headerdata from "./components/Shared/headerdata";
//import productdetail from "./components/product/productdetail";
import ProductDeatil from "./components/product/productDetail";
import Helpsupportdata from './components/helpsupport/helpsupportdata';
// import CategoriesData from "./components/category/CategoryComponet";
import CartComponent from "./components/cart/cartComponent";
import NewPasswordComponent from "./components/forgotpassword/newPasswordComponent";
import RequestForQuotation from './components/RequestForQuotation/RequestForQuotationComponent';
import ProjectData from './components/ourprojects/OurProjectComponent';
import FavoriteProduct from './components/favoriteProducts/favoriteProductsComponent';
import MyProfile from './components/profile/myProfileComponent';
import ChangePassword from "./components/profile/changePasswordComponent";
import EditProfile from "./components/profile/editProdfile";
import MyQuotationHistory from "./components/myQuotation/MyQuotationHistoryComponent";
import SVG from 'react-inlinesvg';
import ProductGHDemo from "./components/product/productShapeDiver";
import NotFoundComponent from "./components/Shared/404NotFoundComponent";
import ProductSketchfabDemo from "./components/product/sketchfabDemo";
import ProductDeatilWithScketchfabDemo from "./components/product/productdetailwithsketchfabDemo"

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, []);
  return (
    <Router>
      <div>
        {loading ? (<div className="main-loader"><SVG src="/assets/images/logo_loader.svg" width={'10%'} /></div>) :
          (
            <>
              <Headerdata />
              <Switch>
                <Route path="/" exact component={HomeListing} />
                <Route path="/aboutus" component={aboutUsdata} />
                <Route path="/privacypolicy" exact component={Privacypolicydata}></Route>
                <Route path="/returnpolicy" exact component={returnpolicydata}></Route>
                <Route path="/termscondition" exact component={termsconditiondata}></Route>
                <Route path="/contactus" exact component={ContactUsComponent}></Route>
                <Route path="/products" exact component={Productdata}></Route>
                <Route path="/products/:slug" component={ProductDeatil}></Route>
                <Route path="/help-support" component={Helpsupportdata} />
                <Route path="/cart" exact component={CartComponent} ></Route>
                <Route path="/quotation" exact component={RequestForQuotation} ></Route>
                <Route path="/newpassword/:resetCode" exact component={NewPasswordComponent} ></Route>
                <Route path="/ourprojects" exact component={ProjectData} ></Route>
                <Route path="/favoriteproducts" exact component={FavoriteProduct} ></Route>
                <Route path="/myprofile" exact component={MyProfile} ></Route>
                <Route path="/changepassoword" exact component={ChangePassword} ></Route>
                <Route path="/editprofile" exact component={EditProfile} ></Route>
                <Route path="/myquotation" exact component={MyQuotationHistory} ></Route>
                <Route path="/myquotation/:slug" component={MyQuotationHistory}></Route>
                <Route path="/shapediverdemo" exact component={ProductSketchfabDemo} ></Route>
                <Route path="/sketchfabDemo" exact component={ProductDeatilWithScketchfabDemo} ></Route>
                <Route path="*" component={NotFoundComponent} />
              </Switch>
              <Footer />

            </>
          )
        }
      </div>

    </Router>
    // </div>
  );
}

export default App;
