import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IMAGE_ROOT } from '../../api-config';
import { groupBy } from 'lodash';
import LoginComponent from '../login/LoginComponent';
import { logout } from '../../redux/actions/loginAction';
import { useDispatch, useSelector } from "react-redux";
import { openMenu, hideMenu } from './custom';
import { ResetGlobalSearchState } from '../../redux/actions/HeaderActions';
import { resources } from '../../resource';

const Header = ({ fetchHeaderPageData, cartCount, isLoggedIn, SerachProduct, ProductByNameSearchList }) => {
	const [openModal, setOpenModal] = useState(false);
	const [showSearchTextbox, setShowSearchTextbox] = useState(false);
	let CategoeyImagePath;
	let CategoryTypeID;
	const CategoryTypeWiseData = groupBy(fetchHeaderPageData, function (n) {
		return n.CategoryTypeName;
	});

	const dispatch = useDispatch();
	const { user: currentUser } = useSelector((state) => state.Login);
	const [ActiveMenu, setActiveMenu] = useState('Home');

	function IsActiveClick(menuItem) {
		setActiveMenu(menuItem);
		sessionStorage.setItem('activeMenu', menuItem);
		hideMenu();
	}
	if (ProductByNameSearchList != undefined && ProductByNameSearchList != null && ProductByNameSearchList.length == 0) {
		document.getElementById('SearchProductMenu').style.display = 'none';
		document.getElementById('NoSearchProductList').style.display = 'block';
	}
	if (ProductByNameSearchList != undefined && ProductByNameSearchList != null && ProductByNameSearchList.length > 0) {

		document.getElementById('SearchProductMenu').style.display = 'block';
		document.getElementById('NoSearchProductList').style.display = 'none';
	}

	const logOut = () => {
		dispatch(logout());
	};

	const ScrollToHospitalityCategory = (menuItem) => {
		document.getElementById("hospital-category").scrollIntoView();
		setActiveMenu(menuItem);
	};

	useEffect(() => {
		if (sessionStorage.getItem('activeMenu') != null)
			setActiveMenu(sessionStorage.getItem('activeMenu'));
	}, [sessionStorage.getItem('activeMenu')])

	useEffect(() => {
		if (ActiveMenu == 'Hospitality') {
			setTimeout(function () {
				ScrollToHospitalityCategory(ActiveMenu);
			}, 500);
		}
	}, [ActiveMenu])

	return (
		<>
			<LoginComponent trigger={openModal} setTrigger={setOpenModal} openModal></LoginComponent>
			{/* {openModal &&(<LoginComponent trigger={openModal} setTrigger={setOpenModal} openModal></LoginComponent> )} */}
			<section className="headerWrap">
				<section className="topHeaer">
					<section className="wrapper">
						<ul className="links">
							<li><Link to="/contactus" onClick={() => { setActiveMenu('') }}>Contact</Link></li>
							{/* <li><a href="#">Review</a></li> */}
							<li><Link to="/help-support" onClick={() => { setActiveMenu('') }}>Support</Link></li>

						</ul>
						<div className="headerActions">
							{
								isLoggedIn && (
									<div className="favoriteIco">
										<Link name="ico" to='/favoriteproducts'><img src="/assets/images/heart.svg" onClick={() => { setActiveMenu('') }} /></Link>
									</div>
								)
							}

							<div className="searchIco">
								{showSearchTextbox && (<><input type='text' placeholder='Search' maxLength={499} title="Press enter to search..." className='headerSearch' onKeyDown={(event) => {
									if (event.key === 'Enter') {
										SerachProduct(event.currentTarget.value);
									} else if (event.currentTarget.value.length == 1 || event.currentTarget.value.length == 0 && (event.key === "Backspace" || event.key === "Delete")) {
										document.getElementById('SearchProductMenu').style.display = 'none';
										document.getElementById('NoSearchProductList').style.display = 'none';

									}
								}} /><i className="fa fa-times closeSearchIcon" onClick={() => { setShowSearchTextbox(false); dispatch(ResetGlobalSearchState()) }}></i>

									<div class="subMenu SearchProductMenu" style={{ display: 'none', visibility: 'visible' }} id='SearchProductMenu'>
										<ul>
											{(() => {
												if (ProductByNameSearchList != undefined && ProductByNameSearchList != null && ProductByNameSearchList.length > 0) {
													return (
														(ProductByNameSearchList.map((data, key) => {
															var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ProductImage;
															return (
																<>
																	<li>
																		<Link href='javascript:void(0);' to={`/products/${data.Slug}`} onClick={(() => { setShowSearchTextbox(false); dispatch(ResetGlobalSearchState()) })}><img src={imgpath} style={{ width: '50px' }} /><strong style={{ fontSize: '14px', marginLeft: '20px', flex: '1' }}>{data.ProductName}</strong></Link>
																	</li>

																</>
															)
														}))
													)
												}
											})()}



										</ul>
									</div>
									<div className='NoSearchProductList' id='NoSearchProductList' style={{ display: 'none', position: 'absolute', top: '45px', width: '450px' }}>
										<ul>
											<li>
												<h1 style={{ color: 'black', padding: '18px', fontSize: '15px' }}>No search result</h1>

											</li>
										</ul>
									</div>




								</>)}
								{!showSearchTextbox && (<a href="#" name="ico"><img src="/assets/images/search.svg" onClick={() => { setShowSearchTextbox(true) }} /></a>)}
							</div>


							<div className="cartIco">
								{/* <a href="#" name="ico"><img src="/assets/images/bag.svg" /></a> */}
								<Link onClick={() => { setActiveMenu('') }} name="ico" to='/cart' style={{ position: 'relative' }} >
									<img src="/assets/images/bag.svg" /> <span className='CartBadge'>  {cartCount == 0 ? 0 : cartCount}</span>
								</Link>
							</div>


							<div className="userAccessIco">
								{
									!isLoggedIn
										? <a href="javascript:void(0)" onClick={() => {
											let body = document.getElementsByTagName("body")[0];
											body.classList.add("overflowhidden");
											body.classList.remove("overflowauto");
											setOpenModal(true);
										}} name="ico" ><img src="/assets/images/user.svg" /></a>
										: <div class="btn-userGroup">
											<a class="btn"></a>
											<div class="dropdown">
												<Link to='/myprofile' onClick={() => { setActiveMenu('') }}>My Profile</Link>
												<div class="divider"></div>
												<a href="javascript:void(0)" onClick={() => { logOut(); setActiveMenu(''); }}>Logout</a>
											</div>
										</div>

								}

							</div>
						</div>
					</section>
				</section>
				<header className="headerMain">
					<section className="wrapper">
						<a className="menuIcon" onClick={() => { openMenu() }}>
							<img src="/assets/images/menu_ico.png" />
						</a>
						<div className="menuOverlay" id="menuOverlay" style={{ display: "none" }} onClick={() => { hideMenu() }}></div>
						<div className="logo">
							<Link to='/' title="Unimaple">
								<img src="/assets/images/logo.png" />
							</Link>

						</div>

						<nav>
							<ul>
								<li key={Math.random()}>
									<Link to="/" name="parent" onClick={() => { IsActiveClick('Home') }} className={ActiveMenu === 'Home' ? "active" : " "}>Home</Link>
								</li>
								<li key={Math.random()}>
									<Link to="/aboutus" name="parent" onClick={() => { IsActiveClick('aboutus') }} className={ActiveMenu === 'aboutus' ? "active" : " "}>About us</Link>
								</li>

								{
									(fetchHeaderPageData != null && fetchHeaderPageData.length > 0) &&
									Object.entries(CategoryTypeWiseData).map(([key, data]) => {
										return (
											<>
												<li key={Math.random()} className={key == "Hospitality" ? "" : data.length >= 1 && key != data[0].CategoryName ? "hasSubMenu" : ""} >
													<Link name='parent'
														to={key == "Hospitality" && window.location.pathname == '/' ? '' : data.length == 1 && key == data[0].CategoryName ? { pathname: '/products', state: { CategoryID: data[0].CategoryID } } : ''}
														onClick={() => { key == "Hospitality" && window.location.pathname == '/' ? ScrollToHospitalityCategory(key) : IsActiveClick(key); }} className={ActiveMenu === key ? "active" : " "}>{key}</Link>
													{
														(data.length >= 1 && key != "Hospitality" &&
															<div className="subMenu">
																<ul>{
																	Object.entries(data).map(([key, value]) => {
																		CategoryTypeID = value.CategoryTypeID;
																		CategoeyImagePath = IMAGE_ROOT + resources.CATEGORIES_IMG_URL + value.CategoryImage;
																		return (
																			<li key={Math.random()}>
																				<Link to={{
																					pathname: '/products',
																					state: {
																						CategoryID: value.CategoryID
																					}
																				}} onClick={() => { IsActiveClick(value.CategoryTypeName) }}  >
																					<strong>{value.CategoryName}</strong>
																					<span>{value.ProductCount}</span>
																				</Link>
																			</li>

																		)

																	})
																}
																</ul>
															</div>)
													}

												</li>
											</>
										)
									})

								}
								<li key={Math.random()}>
									<Link to="/ourprojects" name="parent" onClick={() => { IsActiveClick('ourprojects') }} className={ActiveMenu === 'ourprojects' ? "active" : " "}>Projects</Link>
								</li>
							</ul>
						</nav>
					</section>
				</header>
			</section>
		</>
	)

};
export default Header;