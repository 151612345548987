import { ActionTypes } from '../constants/action-types';
const initialState = {
    isDocumentRequestSuccess: false,
    loading: false
}
export const DocumentRequestReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case ActionTypes.REQUESTFORDOCUMENT_SUCCESSFULLY:
            return { ...state, isDocumentRequestSuccess: true ,loading: false};
        case ActionTypes.LOADER_FOR_REQUESTFORDOCUMENT_START:
            return { ...state, loading: true };
        case ActionTypes.REQUESTFORDOCUMENT_FAIL:
            return { ...state, loading: false }
        case ActionTypes.GET_DOCUMENTREQUEST_BY_QUOTATIONID_SUCCESSFULLY:
            return { ...state, payload,loading: false }
            case ActionTypes.RESET_DOCUMENT_REQUEST_STATE:
                return {...state,isDocumentRequestSuccess:false,loading:false}
            
        default:
            return state;
    }
}