import { ActionTypes } from '../constants/action-types';
const initialState = {
    isSuccess: false,
    FailMessage: null,
    SuccessMessage: null,
    loading: false,
    ProfileDetails: null
}
export const ProfileReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.LOADER_START:
            return { ...state, loading: true };
        case ActionTypes.PROFILESTATISTICS_SUCCESFULLY:
            return { ...state, payload, loading: false };
        case ActionTypes.UPDATE_PASSWORD_FAIL:
            return { ...state, FailMessage: payload, isSuccess: false, loading: false };
        case ActionTypes.UPDATE_PASSWORD_SUCCESSFULLY:
            return { ...state, SuccessMessage: payload, FailMessage: null, isSuccess: true, loading: false };
        case ActionTypes.INVALID_UPDATE_PASSWORD:
            return { ...state, FailMessage: payload, isSuccess: false, SuccessMessage: null, loading: false };
        case ActionTypes.UPDATE_PASSWORD_STATE_RESET:
            return { ...state, FailMessage: null, isSuccess: false, SuccessMessage: null, loading: false };
        case ActionTypes.GET_PROFILE_BY_ID:
            return { ...state, ProfileDetails: payload, loading: false, SuccessMessage: null, FailMessage: null }
        case ActionTypes.UPDATE_PROFILE_RESPONSE:
            return { ...state, payload, loading: false }
        case ActionTypes.PROFILESTATISTICS_FAIL:
            return { ...state, FailMessage: payload, loading: false }
        case ActionTypes.RESET_PROFILE_STATE:
            return { ...initialState }
        default:
            return state;
    }
}