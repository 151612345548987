import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

export const MyQuotaionListByUserID = (
    UserID,StatusType
) => async dispatch => {

    var formData = new FormData();
    formData.append("UserID", UserID);
    formData.append("StatusType", StatusType);
    
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    dispatch({ type: ActionTypes.LOADER_START });
    return api.post('Quotation/GetByUserID', formData, { headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.MYQUOTATIONLISTBYUSERID_SUCCESSFULLY,
                    payload: response.data.ResultObject
                });
            } else {
                dispatch({
                    type: ActionTypes.MYQUOTATIONLISTBYUSERID_FAIL,
                });
            }
        }
    )
}

export const GetPartialDetailByID = (
    QuotationRequestID
) => async dispatch => {

    var formData = new FormData();
    const user = JSON.parse(localStorage.getItem('user'));
    formData.append("QuotationRequestID", QuotationRequestID);
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    return api.post('Quotation/GetPartialDetailByID', formData, { headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.QUOTATIONPARTIALDETAILBYID_SUCCESSFULLY,
                    payload: response.data.ResultObject
                });
            } else {
                dispatch({
                    type: ActionTypes.QUOTATIONPARTIALDETAILBYID_FAIL,
                });
            }
        }
    )
}
export const RequestQuotationDetail = (
    ID
) => async dispatch => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    return api.get('Quotation/GetQuotationRequestByID/'+ parseInt(ID), { headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.MYREQUESTQUOTATIONDETAIL_SUCCESSFULLY,
                    payload: response.data.ResultObject
                });
            } else {
                dispatch({
                    type: ActionTypes.MYREQUESTQUOTATIONDETAIL_FAIL,
                });
            }
        }
    )
}