import React, { useEffect } from 'react';
import { fetchReturnpolicy } from '../../redux/actions/returnpolicyActions';
import ReturnpolicyComponet from './ReturnpolicyComponet';
import { connect } from 'react-redux';


// const Returnpolicydata = () => {
//     const privacypolicy = useSelector((state) => state);
//     const dispatch = useDispatch();
//     useEffect(() => {
//         dispatch(fetchReturnpolicy());
//     }, []);
//     console.log(privacypolicy);
//     return (
//         <ReturnpolicyComponet />
//     );
// }
// export default Returnpolicydata;

const Returnpolicydata = (
    {
        fetchReturnpolicy,
        fetchReturnpolicyPageData,
    }) => {
        useEffect(() => {
            window.scrollTo(0,0);
            fetchReturnpolicy();
        }, [])
        return (
            fetchReturnpolicyPageData != null ? <ReturnpolicyComponet fetchReturnpolicyPageData={fetchReturnpolicyPageData} /> : null
        )
    }
export default connect((state, props) => (
    {
        fetchReturnpolicyPageData: state.allReturnpolicy.ReturnPolicy!= null ? state.allReturnpolicy.ReturnPolicy : null,
    }),
    {fetchReturnpolicy}
)(Returnpolicydata)