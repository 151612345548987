import { ActionTypes } from "../constants/action-types";
const initialState = {
}
export const HelpSupportReducer = (state = initialState, { type, payload}) => {
    switch (type) {
        case ActionTypes.FETCH_HELPSUPPORT_DATA:
            return {...state, payload};
        default:
            return state;
    }
}