import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

export const Forgotpassword = (
    Email
) => async dispatch => {
    var formData = new FormData();
    formData.append("Email", Email);
    return api.post('Account/GenerateResetPasswordURL/', formData).then(
        (response) => {
            if (response.data.ResultCode === 'ERROR') {
                dispatch({
                    type: ActionTypes.FORGOTPASSWORD_FAIL,
                    payload: response.data.ResultMessage,
                });

            } else if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.FORGOTPASSWORD_SUCCESS,
                    payload: response.data.ResultMessage,
                });
            } else if (response.data.ResultCode === 'EXISTS') {
                dispatch({
                    type: ActionTypes.FORGOTPASSWORD_FAIL,
                    payload: response.data.ResultMessage,
                });
            } else {
                dispatch({
                    type: ActionTypes.FORGOTPASSWORD_FAIL,
                    payload: response.data.ResultMessage,
                });
            }
        }
    )
}

export const ValidateResetCode = (
    ResetCode
) => async dispatch => {
    var formData = new FormData();
    formData.append("ResetCode", ResetCode);
    return api.post('Account/ValidateResetCode/', formData).then(
        (response) => {
            
            if (response.data.ResultCode === 'ERROR') {
                dispatch({
                    type: ActionTypes.VALIDATE_RESET_CODE_FAIL,
                    payload: response.data.ResultMessage,
                });

            } else if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.VALIDATE_RESET_CODE_SUCCESS,
                    payload: response.data.ResultMessage,
                });
            } else if (response.data.ResultCode === 'EXISTS') {
                dispatch({
                    type: ActionTypes.VALIDATE_RESET_CODE_FAIL,
                    payload: response.data.ResultMessage,
                });
            } else {
                dispatch({
                    type: ActionTypes.VALIDATE_RESET_CODE_FAIL,
                    payload: response.data.ResultMessage,
                });
            }
        }
    )
}
export const UpdateNewPassword = (
    ResetCode,NewPassword
) => async dispatch => {
    var formData = new FormData();
    formData.append("ResetCode", ResetCode);
    formData.append("NewPassword", NewPassword);
    return api.post('Account/UpdateNewPassword/', formData).then(
        (response) => {
            if (response.data.ResultCode === 'ERROR') {
                dispatch({
                    type: ActionTypes.UPDATE_NEW_PASSWORD_FAIL,
                    payload: response.data.ResultMessage,
                });

            } else if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.UPDATE_NEW_PASSWORD_SUCCESS,
                    payload: response.data.ResultMessage,
                });
            } else if (response.data.ResultCode === 'EXISTS') {
                dispatch({
                    type: ActionTypes.UPDATE_NEW_PASSWORD_FAIL,
                    payload: response.data.ResultMessage,
                });
            } else {
                dispatch({
                    type: ActionTypes.UPDATE_NEW_PASSWORD_FAIL,
                    payload: response.data.ResultMessage,
                });
            }
        }
    )
}
export function ResetForgotPasswordState() { 
    return { type:ActionTypes.FORGOTPASSWORD_STATE_RESET } 
}
