import React, { useEffect } from 'react';
import { fetchTermscondition } from '../../redux/actions/termsconditionActions';
import TermsconditionComponet from './TermsconditionComponet';
import { connect } from 'react-redux';

const Termsconditiondata = (
    {
        fetchTermscondition,
        fetchTermsconditionPageData,
    }) => {
    useEffect(() => {
        window.scrollTo(0,0);
        fetchTermscondition();
    }, [])
    return (
        fetchTermsconditionPageData != null ? <TermsconditionComponet fetchTermsconditionPageData={fetchTermsconditionPageData} /> : null
    )
}
export default connect((state, props) => (
    {
        fetchTermsconditionPageData: state.allTermscondition.TermsConditionPolicy != null ? state.allTermscondition.TermsConditionPolicy : null,
    }),
    { fetchTermscondition }
)(Termsconditiondata)