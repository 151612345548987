import axios from "axios";

let backendHost;
let imageURL;
// const hostname = window && window.location && window.location.hostname;

if (process.env.REACT_APP_ENVIRONMENT === 'DEV') {
  backendHost = process.env.REACT_APP_Unimaple_DEV_API_URL;
  imageURL = process.env.REACT_APP_Unimaple_DEV_IMAGE_URL;
} else {
  backendHost = process.env.REACT_APP_Unimaple_LIVE_API_URL;
  imageURL = process.env.REACT_APP_Unimaple_LIVE_IMAGE_URL;
}

export const API_ROOT = `${backendHost}/`;
export const IMAGE_ROOT = `${imageURL}/`;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const ReCaptchaPublicKey = '6Lfj1CghAAAAAEoRqY1dPHeTZD4TosoqCe13RC0X';
export const ReCaptchaSecretkey = '6Lfj1CghAAAAAMD-WAateNNoAS8QZNSSE4jbiL4';

export default axios.create({
  baseURL: API_ROOT,
  headers: {
    "Content-type": "application/json"
  }
});