import { ActionTypes } from '../constants/action-types';
const initialState = {
    TermsConditionPolicy: []
}
export const TermsConditionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.FETCH_DATA:
            return { ...state, TermsConditionPolicy: payload };
        default:
            return state;
    }
}