import React from 'react';
import axios from "axios";
import { ReCaptchaPublicKey, API_ROOT } from '../../api-config';

import ReCAPTCHA from "react-google-recaptcha";
class Whatsapp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isVerified: false, iswhatsappVisible: false, whatsappFields: {}, whatsapperrors: {}, whatsappLoading: false, whatsappSuccessLoading: false };
        this.handleCapthaOnChange = this.handleCapthaOnChange.bind(this);
        this.handlewhatsappDialog = this.handlewhatsappDialog.bind(this);
    }

    handleCapthaOnChange(value) {
        var $this = this;
        // console.log("Captcha value:", value);
        // this.setState({ isVerified: true });
        var formData = new FormData();
        formData.append("g-recaptcha-response", value);
        const options = {
            url: API_ROOT + 'WebsiteSetting/IsReCaptchValid',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: formData
        };
        axios(options)
            .then(function (response) {
                $this.setState({ isVerified: response.data});
            }).catch(
                function (error) {
                    return Promise.reject(error)
                }
            );




        // const options = {
        //     url: 'https://www.google.com/recaptcha/api/siteverify',
        //     method: 'POST',
        //     headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
        //     },
        //     body: `secret=${ReCaptchaSecretkey}&response=${ReCaptchaPublicKey}`
        // };
        // const isHuman = fetch(options).then((res) => {
        //     debugger;
        //     res.json()
        //     if ((res != undefined || res != null) && res.status == 200) {
        //         this.setState({ isVerified: true });
        //     }
        // })
        //     .then((json) => {
        //         debugger;
        //         console.log(json);
        //     })
        //     .catch(err => {
        //         throw new Error(`Error in Google Siteverify API. ${err.message}`)
        //     })
        // if (ReCaptchaPublicKey === null || !isHuman) {
        //     throw new Error(`YOU ARE NOT A HUMAN.`)
        // }

    }

    handlewhatsappDialog() {
        this.setState({ iswhatsappVisible: !this.state.iswhatsappVisible });
    }

    handleFormControlChange(field, e) {
        let whatsappFields = this.state.whatsappFields;
        whatsappFields[field] = e.target.value;
        this.setState({ whatsappFields });
    }

    whatsappSubmit(e) {
        var $this = this;
        e.preventDefault();

        if (this.handleWhatsappValidation() && this.state.isVerified) {
            $this.setState({ whatsappLoading: true });
            var formData = new FormData();
            formData.append("name", this.state.whatsappFields["Name"]);
            formData.append("phoneNumber", this.state.whatsappFields["PhoneNumber"]);
            formData.append("message", this.state.whatsappFields["Message"]);
            const options = {
                url: API_ROOT + 'WebsiteSetting/SendWhatsAppMessage',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: formData
            };
            axios(options)
                .then(function (response) {
                    $this.setState({ whatsappLoading: false, whatsappSuccessLoading: true });

                }).catch(
                    function (error) {
                        return Promise.reject(error)
                    }
                );
        } else {
        }
    }
    handleWhatsappValidation() {
        let whatsappFields = this.state.whatsappFields;
        let whatsapperrors = {};
        let formIsValid = true;

        if (!whatsappFields["Name"]) {
            formIsValid = false;
            whatsapperrors["Name"] = true;
        }

        if (typeof whatsappFields["Name"] !== "undefined") {
            if (!whatsappFields["Name"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                whatsapperrors["Name"] = true;
            }
        }

        if (!whatsappFields["PhoneNumber"]) {
            formIsValid = false;
            whatsapperrors["PhoneNumber"] = true;
        }

        if (typeof whatsappFields["PhoneNumber"] !== "undefined") {
            if (!whatsappFields["PhoneNumber"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                whatsapperrors["PhoneNumber"] = true;
            }
        }

        if (!whatsappFields["Message"]) {
            formIsValid = false;
            whatsapperrors["Message"] = true;
        }

        this.setState({ whatsapperrors: whatsapperrors });
        return formIsValid;
    }

    render() {
        return (
            <>
                <div className="whatsapp-position-fixed">
                    <div className="outer-circle-image" id="showDialog" onClick={this.handlewhatsappDialog}>
                        <svg width="32" height="32" viewBox="0 0 90 90" fill="#4FCE5D"><path d="M90 43.841c0 24.213-19.779 43.841-44.182 43.841a44.256 44.256 0 0 1-21.357-5.455L0 90l7.975-23.522a43.38 43.38 0 0 1-6.34-22.637C1.635 19.628 21.416 0 45.818 0 70.223 0 90 19.628 90 43.841zM45.818 6.982c-20.484 0-37.146 16.535-37.146 36.859 0 8.065 2.629 15.534 7.076 21.61L11.107 79.14l14.275-4.537A37.122 37.122 0 0 0 45.819 80.7c20.481 0 37.146-16.533 37.146-36.857S66.301 6.982 45.818 6.982zm22.311 46.956c-.273-.447-.994-.717-2.076-1.254-1.084-.537-6.41-3.138-7.4-3.495-.993-.358-1.717-.538-2.438.537-.721 1.076-2.797 3.495-3.43 4.212-.632.719-1.263.809-2.347.271-1.082-.537-4.571-1.673-8.708-5.333-3.219-2.848-5.393-6.364-6.025-7.441-.631-1.075-.066-1.656.475-2.191.488-.482 1.084-1.255 1.625-1.882.543-.628.723-1.075 1.082-1.793.363-.717.182-1.344-.09-1.883-.27-.537-2.438-5.825-3.34-7.977-.902-2.15-1.803-1.792-2.436-1.792-.631 0-1.354-.09-2.076-.09s-1.896.269-2.889 1.344c-.992 1.076-3.789 3.676-3.789 8.963 0 5.288 3.879 10.397 4.422 11.113.541.716 7.49 11.92 18.5 16.223C58.2 65.771 58.2 64.336 60.186 64.156c1.984-.179 6.406-2.599 7.312-5.107.9-2.512.9-4.663.631-5.111z"></path></svg>
                    </div>
                </div>
                <div id="dialog" className={`whatsapp-dialog-content dialog-content ${this.state.iswhatsappVisible ? "fadeInUp animated" : "dnone"}`}>
                    <div className="dialog-header">
                        <button type="button" className="btn-close" data-dismiss="modal"><span onClick={this.handlewhatsappDialog} aria-hidden="true" className="close-span">×</span></button>
                        <div className="header-image">
                            <img src="assets/images/logo.jpg" className="circle-container" />
                            <div className="title">
                                <div className="titleName">UNIMAPLE FURNITURE</div>
                                <div className="title-span">Typically replies within a day</div>
                            </div>
                        </div>
                    </div>
                    <form className="form-horizontal" name="whatsappform" onSubmit={this.whatsappSubmit.bind(this)}>
                        <div className="dialog-body">
                            <input type="hidden" name="hdnGoogleCaptchaResponseDialog" id="hdnGoogleCaptchaResponseDialog" value="" />
                            <ul id="contactform" className={`${this.state.whatsappSuccessLoading ? "dnone" : ""}`}>
                                <li className="li-dialog control-wrapper">
                                    <input type="text" placeholder="Enter Name" className={`form-control dialog-control ${this.state.whatsapperrors["Name"] ? "bb-red-2" : ""}`} refs="Name" onChange={this.handleFormControlChange.bind(this, "Name")} value={this.state.whatsappFields["Name"]} />
                                </li>
                                <li className="li-dialog control-wrapper">
                                    <input type="text" placeholder="Enter Phone Number" className={`form-control dialog-control ${this.state.whatsapperrors["PhoneNumber"] ? "bb-red-2" : ""}`} refs="PhoneNumber" onChange={this.handleFormControlChange.bind(this, "PhoneNumber")} value={this.state.whatsappFields["PhoneNumber"]} />
                                </li>
                                <li className="li-dialog control-wrapper">
                                    <textarea placeholder="Enter Message" className={`form-control dialog-control textarea-fix ${this.state.whatsapperrors["Message"] ? "bb-red-2" : ""}`} refs="Message" onChange={this.handleFormControlChange.bind(this, "Message")} value={this.state.whatsappFields["Message"]}></textarea>
                                </li>
                                <li className="control-wrapper">
                                    <ReCAPTCHA
                                        sitekey={ReCaptchaPublicKey}
                                        onChange={this.handleCapthaOnChange}
                                    />
                                </li>
                            </ul>
                            <div className={`divsuccess ${this.state.whatsappSuccessLoading ? "" : "dnone"}`} id="">
                                <div className="" id="txtSentMessage" style={{ "height": "auto" }}>
                                    <p className="whatsapp-success">
                                        <img src="assets/images/loader-success.gif" className="success-icon" />
                                        <span className='whatsapp-success-text'>Message Send Successfully. Will contact you soon.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button className="form-control message-send" id="btnSend" disabled={!this.state.isVerified || this.state.whatsappLoading}>
                                <label id="lblSend" className={`send-label ${this.state.whatsappLoading ? "dnone" : ""}`}><i className="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;Send</label>
                                <label id="lblSend" className={`send-label ${this.state.whatsappLoading ? "" : "dnone"}`}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;Submitting...</label>
                            </button>
                        </div>
                    </form>
                </div>
            </>

        );
    }
}
export default Whatsapp;