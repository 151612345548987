import { createSelector } from 'reselect'

export const stateSelector = state => state.allProductReducer.LastProductReducer

export const getLastProductID = createSelector(
  [stateSelector],
  state => state != null ? state.lastProductID : -1
)

export const projectStateSelector = state => state.ourProjectReducer.ProjectsByLastProjectID

export const getLastProjectID = createSelector(
  [projectStateSelector],
  state => state != null ? state.lastProjectID : -1
)
