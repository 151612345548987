import { ActionTypes } from '../constants/action-types';
const initialState = {
}
export const OurTeamReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.FETCH_TEAM:
            return { ...state, payload };
        default:
            return state;
    }
}