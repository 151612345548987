import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';
export const FETCH_AllPRODUCTSPAGEDATA = 'FETCH_AllPRODUCTSPAGEDATA'

export const fetchProducts = (
    Categoryid,
    lastProductID,
    pageSize,
    isNew = true,
    UserID
) => async dispatch => {
    try {
        dispatch({ type: ActionTypes.LOADER_START });
        Categoryid = Categoryid.length > 0 ? Categoryid : " ";
        await api.get(`Product/ProductGetForWeb/${Categoryid}/${lastProductID}/${pageSize}/${UserID}`).then((resultObject) => {
            // alert("last " + lastProductID);
            var pid = resultObject.data.ResultObject.length > 0 ?
                resultObject.data.ResultObject[resultObject.data.ResultObject.length - 1].RowNumber : 0

            pid = resultObject.data.ResultObject.length >= pageSize ? pid : 0;
            let ProductDisplayCount = resultObject.data.ResultObject.length;
            if (resultObject.data.ResultObject.length > pageSize) {
                resultObject.data.ResultObject = resultObject.data.ResultObject.slice(0, -1)
            }
            dispatch({
                type: ActionTypes.FETCH_AllPRODUCTSPAGEDATA,
                allProductData: {
                    products: resultObject.data.ResultObject,
                    lastproductID: pid,
                    isNew: isNew,
                    productDisplayCount: ProductDisplayCount

                }
            })
        });

    }
    catch (e) {
        dispatch({
            type: ActionTypes.FETCH_AllPRODUCTSPAGEDATA,
            homeExceptionLogObject: []
        })
    }
}