import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RequestQuotationDetail } from '../../redux/actions/myQuotationAction';
import { IMAGE_ROOT } from '../../api-config';
import { resources } from '../../resource';
const MyRequestQuotationDetail = (
    {
        isRequestQuotationToggle, setIsRequestQuotationToggle, RequestQuotationDetail, RequestQuotationID, RequestQuotationDetailData
    }) => {
    useEffect(() => {
        if (isRequestQuotationToggle) {
            RequestQuotationDetail(RequestQuotationID)
        }
    }, [isRequestQuotationToggle, RequestQuotationID]);
    return (
        <>
            {(() => {
                if (isRequestQuotationToggle === true && RequestQuotationDetailData != null) {
                    let ProductsList = JSON.parse(RequestQuotationDetailData.ProductJSON || "[]");
                    return (
                        <form className='form' id="registerFrm">
                            <div class="overlay" id="accessModelOverlay" style={{ display: 'block' }}></div>
                            <div class="model userAccessModel" style={{ display: 'block' }}>
                                <div class="modelWrapper-quotation modelWrapper">

                                    <div class="quotation-cards">
                                        <div id="scroll" class="prod-card-wrap">
                                            {
                                                (ProductsList != null && ProductsList.length > 0) &&
                                                ProductsList.map((data, key) => {
                                                    var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ProductImage;
                                                    return (
                                                        <>
                                                            <div class="checkout-prod-card">
                                                                <div class="card-head">
                                                                    <p class="product-name">{data.ProductName}</p>

                                                                </div>
                                                                <div class="card-content">
                                                                    <div class="card-img">
                                                                        <img src={imgpath} alt="product-img" width="80" height="80" />
                                                                    </div>
                                                                    <div class="card-desc">
                                                                        <p class="prod-desc">{ data.ProductDescription.length > 100 ? data.ProductDescription.substring(0, 50) + "......" : data.ProductDescription}</p>
                                                                        <div class="prod-detail">
                                                                            <p class="prod-qty">Qty: {data.Quantity}</p>
                                                                            <p class="prod-detail-separator">&#x25CF;</p>
                                                                            {/* <p class="prod-price">Unit Price: $200</p>
                                                                            <p class="prod-detail-separator">&#x25CF;</p>
                                                                            <p class="total">Total: $1000</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })

                                            }

                                        </div>

                                    </div>

                                    <div class="quotation-det accessForm">
                                        <a href="javascript:void(0);" class="close" onClick={() => { setIsRequestQuotationToggle(false); }}></a>

                                        <div class="prod-quotation">
                                            <div class="prod-quotation-head">
                                                <h3 style={{fontSize:'29px'}}>Quotation Detail</h3>
                                            </div>
                                            <div class="prod-quotation-content">
                                                <div class="quotation-details">
                                                    <h5>Contact Name:</h5>
                                                    <span>{RequestQuotationDetailData.ContactName}</span>
                                                </div>
                                                <div class="quotation-details">
                                                    <h5>Mobile No:</h5>
                                                    <span>{RequestQuotationDetailData.PhoneNumber}</span>
                                                </div>
                                                <div class="quotation-details">
                                                    <h5>Email Address:</h5>
                                                    <span>{RequestQuotationDetailData.Email}</span>
                                                </div>
                                                <div class="quotation-details">
                                                    <h5>Firm Name:</h5>
                                                    <span>{RequestQuotationDetailData.FirmName}</span>
                                                </div>
                                                <div class="quotation-details-last">
                                                    <h5>Message:</h5>
                                                    <span id="scroll" class="msg">{RequestQuotationDetail.Message != null ? RequestQuotationDetail.Message : '-'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }
            })()}
        </>
        //    <AboutUsComponet fetchAbountPageData={fetchAbountPageData} fetchTeamsData={fetchTeamsData}/>
    )
}
export default connect((state, props) => (
    {
        RequestQuotationDetailData: state.myQuotation.RequestQuotationDetail != null ? state.myQuotation.RequestQuotationDetail : null
    }),
    { RequestQuotationDetail }
)(MyRequestQuotationDetail)
