import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAboutus } from '../../redux/actions/aboutusActions';
import { fetchTeams } from '../../redux/actions/ourTeamAction';
import AboutUsComponet from './AboutUsComponet';

const Aboutusdata = (
    {
        fetchAboutus,
        fetchTeams,
        fetchAbountPageData,
        fetchTeamsData

    }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAboutus();
        fetchTeams();
    }, [])
    return (
       <AboutUsComponet fetchAbountPageData={fetchAbountPageData} fetchTeamsData={fetchTeamsData}/>
    )
}
export default connect((state, props) => (
    {
        fetchAbountPageData: state.allAboutus.payload != null ? state.allAboutus.payload : null,
        fetchTeamsData: state.allOurTeam.payload != null ? state.allOurTeam.payload : null
    }),
    { fetchAboutus, fetchTeams }
)(Aboutusdata)
