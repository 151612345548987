import { ActionTypes } from '../../constants/action-types';
const initial = {
    lastProductID: 0  
}
export const LastProductReducer = (state = initial, { type, allProductData }) => {
    switch (type) {    
      case ActionTypes.FETCH_AllPRODUCTSPAGEDATA:
        // alert(allProductData.lastproductID +" ac")
        return {
          ...state,
          lastProductID:  allProductData.lastproductID
        }
      default:
        return state
    }
  }