import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';


export const fetchAboutus = (
    
    ) => async dispatch => {
        try {
            const resultObject = await api.get("CMS/GetCMSWeb?slug=about-us");
            dispatch({
                type: ActionTypes.FETCH_ABOUT_DATA,
                payload: resultObject.data.ResultObject
            })
        }
        catch (e) {
            dispatch({
                type: ActionTypes.FETCH_ABOUT_DATA,
                homeExceptionLogObject: []
            })
        }
    }
