import React from 'react';

const PrivacypolicyComponet = ({fetchPrivacypolicyPageData}) => {
    return (
        <div>
           <section className="wrapper">
            <div style={{paddingTop:"10px"}}>
                  
                <h2 style={{fontSize:'30px',fontWeight:'600',color: '#6E0010',textAlign:'left',marginBottom:'15px',}}>{fetchPrivacypolicyPageData.Title}</h2>
                <br/>
                <div className="box" dangerouslySetInnerHTML={{
                    __html: fetchPrivacypolicyPageData.Content
                }}>

                </div>



            </div>
            </section>
        </div>
    )
};
export default PrivacypolicyComponet;