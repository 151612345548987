import { useEffect, useState } from "react"
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import SubHeader from '../Shared/subHeader';
import { Link } from "react-router-dom";
import { FavoriteProductsByUserID, AddOrRemoveFavoriteProduct } from "../../redux/actions/favoriteProductAction";
import { IMAGE_ROOT } from '../../api-config';
import $ from 'jquery';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { resources } from "../../resource";

const FavoriteProduct = ({
    fetchFavoriteProducts, AddOrRemoveFavoriteProduct,
    FavoriteProductsByUserID,
    loading, isProductAddOrRemoveToFavorites, isLoggedIn
}) => {
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("user"));
    const [isFavorites, setIsFavorite] = useState(false)
    useEffect(() => {
        if (!isLoggedIn) {
            history.push("/")
        } else if (isLoggedIn) {

            FavoriteProductsByUserID(user != null ? user.UserID : null)
        }
    }, [isFavorites, isLoggedIn, history]);

    useEffect(() => {
        if (isProductAddOrRemoveToFavorites != null && isProductAddOrRemoveToFavorites.length > 0) {
            FavoriteProductsByUserID(user != null ? user.UserID : null, false)
        }
    }, [isProductAddOrRemoveToFavorites != null && isProductAddOrRemoveToFavorites.length]);


    function makeFavourite(ProductID) {
        AddOrRemoveFavoriteProduct(ProductID, user != null ? user.UserID : null);

    }

    return (
        <>
            <SubHeader CurrentPageName={'Favorite Products'}></SubHeader>
            {(() => {
                return (
                    <>
                        <section className="myFavoritePage">
                            <section className="wrapper">

                                {(() => {
                                    if (fetchFavoriteProducts != null && fetchFavoriteProducts.length > 0 && !loading) {
                                        return (
                                            <div class="procommerce">
                                                <ul className="productList products --skin-cards">
                                                    {
                                                        fetchFavoriteProducts.map((data, key) => {

                                                            var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ImageName;
                                                            return (
                                                                <li key={Math.random()} id={key} class="product wc-skin--cards is-animated is-animated-entry --animated-in">

                                                                    <div class="product-card">
                                                                        <Link to={`/products/${data.Slug}`}>
                                                                            <div class="product-img">
                                                                                <img src={imgpath} data-product={key} style={{ display: "inline" }}></img>
                                                                                <div class="favorite-wrap">
                                                                                    <div class="favorite">
                                                                                        <Link onClick={() => {  makeFavourite(data.ProductID) }}>

                                                                                        <img src="/assets/images/heart_fill.svg" height={20} width={20} />

                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                    <div class="product-desc-wrap">
                                                                        <div class="product-desc">
                                                                            <p class="product-name">{data.ProductName}</p>

                                                                        </div>
                                                                        {/* <div className="unfavoriteLink">
                                                                            <a href="javascript:void(0);" onClick={() => { makeFavourite(data.ProductID) }}>Unfavourite</a>
                                                                        </div> */}
                                                                    </div>


                                                                </li>
                                                            )

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }
                                    else if (fetchFavoriteProducts != null && fetchFavoriteProducts.length == 0 && !loading) {
                                        return (
                                            <>
                                                <div className="noRecord" style={{ display: "block" }}>
                                                    <picture>
                                                        <source srcset="/assets/images/no_fav.webp" type="image/webp" />
                                                        <img src="/assets/images/no_fav.png" />
                                                    </picture>
                                                    <label>No search result found!</label>
                                                    <p>We couldn't find any result.</p>
                                                </div>
                                            </>
                                        )
                                    }
                                    else if (loading) {
                                        return (
                                            <>
                                                <div className="productLoader" style={{ display: "block" }}>
                                                    <ul className="productList">

                                                        {
                                                            [...Array(5)].map((ignore, index) => {
                                                                return (

                                                                    <li key={index}>
                                                                        <Skeleton height={295} width={329}></Skeleton>
                                                                        <div style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '5px' }}>
                                                                            <Skeleton style={{ display: 'block', width: '20px', height: '20px', border: '2px solid #fff', borderRadius: '100%', boxShadow: '0.8px 3px 4px 0px rgb(0 0 0 / 36%)' }} >
                                                                            </Skeleton>
                                                                        </div>
                                                                        <a href="#0" className="productIntro">
                                                                            <Skeleton style={{ width: "70%" }} ></Skeleton>
                                                                        </a>
                                                                    </li>

                                                                )
                                                            })
                                                        }

                                                    </ul>
                                                </div>
                                            </>
                                        )
                                    }
                                })()
                                }
                            </section>
                        </section>
                    </>
                )
            })()
            }
        </>
    )
}
export default connect((state, props) => ({
    fetchFavoriteProducts: state.favoriteProduct.payload != null ? state.favoriteProduct.payload : null,
    loading: state.favoriteProduct.loading,
    isProductAddOrRemoveToFavorites: state.favoriteProduct.payload != null ? state.favoriteProduct.payload : null,
    isLoggedIn: state.Login.isLoggedIn
}), { FavoriteProductsByUserID, AddOrRemoveFavoriteProduct }
)(FavoriteProduct)