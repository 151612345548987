import React from 'react';
import { IMAGE_ROOT } from '../../api-config'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { resources } from '../../resource';

const ClientComponet = ({ ClientData }) => {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                }
            }]
    };
    var i = 0;
    return (
        <section class="brand-slider-wrap">
            <div class="brand-slider">
                <div class="slide-track">
                    {(() => {
                        if (ClientData != null && ClientData.length > 0) {
                            return (
                                [...Array(14)].map((ignore, index) => {
                                    var imgpath = '';
                                    if (index >= ClientData.length) {
                                        i = i >= ClientData.length ? 0 : i;
                                        imgpath = IMAGE_ROOT + resources.CLIENT_IMG_URL + ClientData[i].ProfileImage;
                                        i++;
                                    } else {
                                        imgpath = IMAGE_ROOT + resources.CLIENT_IMG_URL + ClientData[index].ProfileImage;
                                    }

                                    return (
                                        <div className="slide-img" key={index}>
                                            <img src={imgpath} height="100" width="250" draggable='false' />
                                        </div>
                                    )

                                })
                            )
                        }


                        //     (ClientData != null && ClientData.length > 0) &&
                        // ClientData.map((data, key) => {
                        //     var imgpath = IMAGE_ROOT + "Images/clients/" + data.ProfileImage;
                        //     return (
                        //         <div className="slide-img" key={key}>
                        //             <img src={imgpath} height="100" width="250" />
                        //         </div>
                        //     )
                        // })
                    })()
                    }
                </div>
            </div>
        </section>
        // <section className="clientlyLogoSlider">
        //     <Slider {...settings}>
        //         {
        //             (ClientData != null && ClientData.length > 0) &&
        //             ClientData.map((data, key) => {
        //                 var imgpath = IMAGE_ROOT + "Images/clients/" + data.ProfileImage;
        //                 return (
        //                     <div key={key}>
        //                         <img src={imgpath} className="clientSliderWidth" />
        //                     </div>
        //                 )
        //             })

        //         }
        //     </Slider>
        // </section>
    )

};
export default ClientComponet;