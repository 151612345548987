import { ActionTypes } from '../constants/action-types';

const initialState = {
    loading: false,
    error: ''
}
export const FavoriteProductReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.LOADER_START:
            return { ...state, loading: true };
        case ActionTypes.FAVORITE_PRODUCTS_SUCCESFULLY:{
            return { ...state, loading: false,payload };
        }    
        case ActionTypes.FAVORITE_PRODUCTS_FAIL:
            return { ...state, payload, loading: false };
        case ActionTypes.FAVORITE_PRODUCTS_SELECT:
            return { ...state, payload, loading: false }
        default:
            return state;
    }
}