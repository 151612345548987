import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IMAGE_ROOT } from "../../api-config";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ProfileDetails, UpdateProfile } from "../../redux/actions/profileAction";
import { Countries } from "../Shared/countries";
import { resources } from "../../resource";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const EditProfile = ({ isLoggedIn, loading, ProfileDetails, profile, UpdateProfile, UpdateReponse }

) => {

    const history = useHistory();
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user"));

    var initialState = {
        userID: profile != null ? profile.UserID : '',
        fullName: profile != null ? profile.FullName : '',
        mobileNo: profile != null ? profile.ContactNo : '',
        email: profile != null ? profile.Email : '',
        address: profile != null ? profile.Address : '',
        city: profile != null ? profile.City : '',
        state: profile != null ? profile.State : '',
        pincode: profile != null ? profile.Zipcode : '',
        country: profile != null ? profile.Country : '',
        profileImage: profile != null ? profile.ProfileImage : '',
        oldProfileImage: profile != null ? profile.ProfileImage : '',
        imageX: profile != null ? profile.ImageX : '',
        imageY: profile != null ? profile.ImageY : '',
        imageWidth: profile != null ? profile.ImageWidth : '',
        imageHeight: profile != null ? profile.ImageHeight : '',
    }

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    // const [userPic, setUserPic] = useState(profilePic);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [selectedFile, setSelectedFile] = useState();

    const [mobileNo, setMobileNo] = useState();
    const [countryCode, setCountryCode] = useState();
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        setFormData(initialState)
        setMobileNo(profile != null ? profile.ContactNo : '');
        // setCountryCode(profile != null ? profile.CountryCode.toString() : '');
    }, [profile])

    const InitFormValidation = (values, mobileNo) => {
        let errors = {};
        let formIsValid = true;
        // var mobilePattern = new RegExp(/^[0-9\b]+$/);
        var mobilePattern = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

        if (!values.fullName) {
            errors.fullName = 'Fullname is required';
            formIsValid = false;
        }

        if (!mobileNo) {
            errors.mobileNo = 'Mobile no is required';
            formIsValid = false;
        } else if (!mobilePattern.test(mobileNo)) {
            formIsValid = false;
            errors.mobileNo = 'Please enter only numbers.';
        }
        //  else if (values.mobileNo.length != 10) {
        //     errors.mobileNo = 'Mobile no. should not be greater than 10';
        //     formIsValid = false;
        // }

        if (!values.email) {
            errors.email = 'Email is required';
            formIsValid = false;
        }
        if (!values.address) {
            errors.address = 'Address is required'
            formIsValid = false;
        } else if (!values.address.trim()) {
            errors.address = 'Address is required'
            formIsValid = false;
        }



        if (!values.city && !values.state && !values.pincode) {
            errors.city = 'City,State and Pincode are required';
            formIsValid = false;
        }
        else if (!values.city.trim() && !values.state.trim() && !values.pincode) {
            errors.city = 'City,State and Pincode are required';
            formIsValid = false;
        }
        else if (!values.city.trim() && !values.state.trim()) {
            errors.city = 'City and State are required';
            formIsValid = false;
        }
        else if (!values.city.trim() && !values.pincode) {
            errors.city = 'City and Pincode are required';
            formIsValid = false;
        }
        else if (!values.city.trim()) {
            errors.city = 'City is required';
            formIsValid = false;
        }
        else if (!values.state.trim() && !values.pincode) {
            errors.city = 'State and Pincode are required';
            formIsValid = false;
        }
        else if (!values.state.trim()) {
            errors.city = 'State is required';
            formIsValid = false;
        }
        else if (!values.pincode) {
            errors.city = 'Pincode is required';
            formIsValid = false
        }

        //else if (!FormValidator.isEmail(values.email)) {
        //     errors.email = 'Please enter a valid email';
        //     formIsValid = false;
        // }

        // if (!FormValidator.isPostalCode(values.pincode, 'IN')) {
        //     errors.email = 'Please enter valid pincode';
        //     formIsValid = false;
        // }

        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (InitFormValidation(formData, mobileNo)) {
            formData.country = formData.country != '' || formData.country != 'null' ? formData.country : '';
            UpdateProfile(formData, mobileNo, countryCode);
            // UpdatePassword(user.UserID, values.oldPassword, values.newPassword);
        }
    }

    useEffect(() => {
        if (!isLoggedIn)
            history.push("/");
        else {
            ProfileDetails();
        }
    }, [isLoggedIn, history]);

    const fileUpload = e => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedFile(reader.result);
            formData.profileImage = e.target.files[0];
            // setFormData(initialState);
            // setUserPic(reader.result);
        };
        reader.readAsDataURL(files[0]);
        document.getElementsByTagName('body')[0].style.overflowY = "hidden";
        setIsShowModal(true);
        // setUserPic(URL.createObjectURL(e.target.files[0]));
    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            // initialState.profileImage = cropper.getCroppedCanvas().toDataURL();
            // setFormData(initialState);
            formData.imageX = parseInt(Math.round(cropper.getData().x));
            formData.imageY = parseInt(Math.round(cropper.getData().y));
            formData.imageWidth = parseInt(Math.round(cropper.getData().width));
            formData.imageHeight = parseInt(Math.round(cropper.getData().height));
            // setFormData(initialState);
            setCropData(cropper.getCroppedCanvas().toDataURL());
            setIsShowModal(false);
            document.getElementsByTagName('body')[0].style.overflowY = "scroll";
        }
    };
    const [isShowModal, setIsShowModal] = useState(false);


    const selectElement = document.querySelector('.PhoneInputCountrySelect');
    let countryCodeOnChange = () => {
        selectElement.addEventListener('change', (e) => {
            e.stopImmediatePropagation();
            setCountryCode(e.target.value.toString())
            setMobileNo('');
            
        });
        selectElement.removeEventListener("change", countryCodeOnChange);
    }
    if (selectElement != null) {
        countryCodeOnChange();
    }
    return (
        <>
            <section className="innerPageBanner">
                <section className="wrapper">

                    <h6>Edit Profile</h6>
                    <h1>{user != null ? user.FullName : ''}</h1>
                    <div className="spacer" style={{ height: '40px' }}></div>


                </section>
            </section>
            {(() => {
                if (ProfileDetails == null) {

                    return;
                }
            })()}
            {(() => {
                if (isShowModal) {
                    return (
                        <>
                            <div class="overlay" id="accessModelOverlay" style={{ display: 'block' }}></div>
                            <div class="model userAccessModel" id="profileImageModal" style={{ display: 'block' }}>
                                <div class="modelWrapper">
                                    <div style={{ padding: '40px 40px 20px 40px', width: '100%' }}>
                                        <a href="javascript:void(0)" className="close" onClick={() => { setIsShowModal(false); document.getElementsByTagName('body')[0].style.overflowY = "scroll"; }}></a>
                                        <div id="registerFrom" style={{ display: 'block', width: '100%' }}>
                                            <Cropper
                                                style={{ height: 400, width: "100%" }}
                                                zoomTo={0.5}
                                                initialAspectRatio={1}
                                                preview=".img-preview"
                                                src={selectedFile}
                                                viewMode={1}
                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                guides={true}
                                            />
                                        </div>
                                    </div>
                                    <div class="formButton" style={{ marginBottom: '20px' }}>
                                        <div class="submitBtn">
                                            <div class="btnLoader" style={{ display: 'none' }}>
                                                <span class="spinLoader"></span>
                                            </div>
                                            {/* <input value="Register" class="btn" type="button" /> */}
                                            <button id='btnCrop' class='btn' onClick={getCropData}>Crop Image</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            })()}


            <section className="editProfilePage">
                <section className="wrapper">
                    <section className="profileWapper">
                        <div className="userAvatar">
                            <div className="avtar">
                                <input type="file" onChange={fileUpload} ></input>
                                <i><img src="/assets/images/camera.svg" /></i>

                                <img src={cropData != "#" ? cropData : IMAGE_ROOT + resources.USER_IMG_URL + formData.profileImage} className="pic" onError={(e) => (e.target.onerror = null, e.target.src = "/assets/images/user-default-image.jpg")} />
                            </div>
                        </div>
                        <section className="form">
                            {UpdateReponse && UpdateReponse.ResultCode == "ERROR" && (
                                <div class="fromRowWithLabel">
                                    <div class="lbl"></div>
                                    <div class="val">
                                        <div className="alert alert-error" style={{ display: 'flex', width: '80%' }} id='ErrorMessage'>
                                            <img src="/assets/images/error_x.svg" />
                                            {UpdateReponse.ResultMessage}
                                            <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {UpdateReponse && UpdateReponse.ResultCode == "SUCCESS" && (
                                <div class="fromRowWithLabel">
                                    <div class="lbl"></div>
                                    <div class="val">
                                        <div className="alert alert-success" style={{ display: 'flex', width: '80%' }} id="isSuccessfullyUpdatePassword">
                                            <img src="/assets/images/success-tik.svg" />
                                            {UpdateReponse.ResultMessage}
                                            <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className="fromRowWithLabel">
                                    <input type="hidden" name="userID" value={formData.userID} ></input>
                                    <div className="lbl">Name<span>*</span></div>
                                    <div className="val">
                                        {/* <div className="splitField" style={{ width: "80%" }}> */}
                                        <input type="text" name="fullName" className="input" placeholder="Full Name" value={formData.fullName} onChange={handleChange} style={{ width: "80%" }} />
                                        {/* <input type="text" name="fullName" className="input" placeholder="Last Name" value={formData.fullName.split(' ')[1]} onChange={handleChange} /> */}
                                        {/* </div> */}
                                        <div className="validation">
                                            {errors.fullName && <span style={{ display: "block" }}>{errors.fullName}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="fromRowWithLabel">
                                    <div className="lbl">Mobile Number<span>*</span></div>
                                    <div className="val">
                                        {/* <input type="text" name="mobileNo" value={formData.mobileNo} className="input" placeholder="Enter Mobile NUmber" onChange={handleChange} style={{ width: "80%" }} /> */}
                                        <PhoneInput
                                            className='input' name='mobileNo'
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry={countryCode}
                                            value={mobileNo} onChange={setMobileNo}
                                            style={{ padding: '5px 5px !important', width: "80%" }}
                                        />
                                        <div className="validation">{errors.mobileNo && <span style={{ display: "block" }}>{errors.mobileNo}</span>} </div>
                                    </div>
                                </div>

                                <div className="fromRowWithLabel">
                                    <div className="lbl">Email
                                        <span>*</span>
                                    </div>
                                    <div className="val">
                                        <input type="text" name="email" className="input" Value={user != null ? user.Email : ''} style={{ width: "80%", marginBottom: "20px" }} readOnly />

                                        <div className="inputNotes">
                                            <a className="ico"><i className="fa  fa-question-circle"></i></a>
                                            <div className="notes">You can not edit this field as this is the username of your account.</div>
                                        </div>

                                        {/* <div className="validation"><span style={{ display: "block" }}>Validation message in this span</span></div> */}
                                    </div>
                                </div>

                                <div className="fromRowWithLabel">
                                    <div className="lbl">Address<span>*</span></div>
                                    <div className="val">
                                        <textarea rows={3} type="text" name="address" value={formData.address} className="input" placeholder="Enter Your Address" style={{ width: "80%" }} onChange={handleChange} />
                                        <div className="validation">
                                            {errors.address && <span style={{ display: "block" }}>{errors.address}</span>}
                                        </div>
                                        {/* <div className="validation"><span style={{ display: "block" }}>Validation message in this span</span></div> */}
                                    </div>
                                </div>

                                <div className="fromRowWithLabel">
                                    <div className="lbl">City / State / Pincode<span>*</span></div>
                                    <div className="val">
                                        <div className="column-3-split" style={{ width: "80%", marginBottom: "20px" }}>
                                            <input type="text" name="city" className="input" value={formData.city} placeholder="Enter City" onChange={handleChange} />
                                            <input type="text" name="state" id="state" value={formData.state} className="input" placeholder="Enter State" onChange={handleChange} />
                                            <input type="text" name="pincode" id="pincode" value={formData.pincode} className="input" placeholder="Enter Pincode" onChange={handleChange} />
                                        </div>
                                        <div className="validation">
                                            {
                                                errors.city && <span style={{ display: 'block' }}>{errors.city}</span>
                                            }
                                        </div>

                                        {/* <div className="validation"><span style={{ display: "block" }}>Validation message in this span</span></div> */}
                                    </div>
                                </div>

                                <div className="fromRowWithLabel">
                                    <div className="lbl">Country<span>*</span></div>
                                    <div className="val">
                                        <select className="input" name="country" onChange={handleChange} value={formData.country} style={{ width: "80%", marginBottom: "20px" }}>
                                            {
                                                Countries.countries.map((e, key) => {
                                                    return <option value={e.code} >{e.name}</option>
                                                })
                                            }
                                        </select>
                                        {/* <div className="validation"><span style={{ display: "block" }}>Validation message in this span</span></div> */}
                                    </div>
                                </div>

                                <br />
                                <div className="fromRowWithLabel">
                                    <div className="lbl"></div>
                                    <div className="val">

                                        <span className="" style={{ marginRight: "25px" }}>
                                            <div className="btnLoader" style={{ display: loading === true ? 'block' : 'none' }}>
                                                <span className="spinLoader small"></span>
                                            </div>
                                            <button className="product-btn" type="submit">Update Profile</button>
                                        </span>
                                        <Link to='javascript:void(0);' onClick={() => { history.goBack() }} >Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </section>
                </section>
            </section>
        </>
    )
}
export default connect((state, props) => ({
    isLoggedIn: state.Login.isLoggedIn,
    loading: state.profile.loading,
    profile: state.profile.ProfileDetails,
    UpdateReponse: state.profile.payload,
}), { ProfileDetails, UpdateProfile }
)(EditProfile)