import { ActionTypes } from '../constants/action-types';
const initialState = {
    loading: false,
    error: ''
}
export const HomeReducer = (state = initialState, { type, homeData }) => {
    switch (type) {
        case ActionTypes.SET_HOME:
            return { ...state, homeData };
        case ActionTypes.HOME_LOADER_START:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case ActionTypes.FETCH_HOMEDATA:
            return { ...state, homeData, loading: false };
        default:
            return state;
    }
}