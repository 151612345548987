import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProfileStatistics } from '../../redux/actions/profileAction';
import { IMAGE_ROOT } from '../../api-config';
import { Link } from 'react-router-dom';
import { HandleAuthResponse } from '../../services/api-response';
import { resources } from '../../resource';

const MyProfile = (
    {
        isLoggedIn, ProfileStatistics, profileData, error, HandleAuthResponse
    }) => {
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        if (!isLoggedIn) {
            history.push("/")
        } else if (isLoggedIn) {
            ProfileStatistics(user.UserID);
        }

    }, [isLoggedIn, history])

    useEffect(() => {
        HandleAuthResponse(error, history);
    }, [error])

    return (
        <>
            {(() => {

                if (profileData != null) {
                    var imgpath = IMAGE_ROOT + resources.USER_IMG_URL + profileData.ProfileImage;
                    return (
                        <>
                            <section class="innerPageBanner">
                                <section class="wrapper">

                                    <h6>My Profile</h6>
                                    <h1>{profileData.FullName}</h1>
                                    <div class="spacer" style={{ height: '40px' }}></div>


                                </section>
                            </section>
                            <section class="myProfilePage">
                                <section class="wrapper">

                                    <section class="profileWapper">

                                        <div class="userAvatar">
                                            <div class="avtar"><img src={imgpath} class="pic" onError={(e) => (e.target.onerror = null, e.target.src = "/assets/images/user-default-image.jpg")} /></div>
                                        </div>

                                        <div class="userStates">

                                            <div class="states">
                                                {/* <a href="#">
                                                    <i><img src="/assets/images/bag.svg" /></i>
                                                    <label>My Orders</label>
                                                    <strong>{profileData.OrderCompleteCount}</strong>
                                                </a> */}
                                                <Link to={'/myquotation/order'}>
                                                    <i><img src="/assets/images/bag.svg" /></i>
                                                    <label>My Orders</label>
                                                    <strong>{profileData.OrderCompleteCount}</strong>
                                                </Link>
                                            </div>

                                            <div class="states">
                                                <Link to="/favoriteproducts">
                                                    <i><img src="/assets/images/heart.svg" /></i>
                                                    <label>My Favourite</label>
                                                    <strong>{profileData.FavoriteProductsCount}</strong>
                                                </Link>
                                            </div>

                                            <div class="states">
                                                <Link to="/myquotation">
                                                    <i><img src="/assets/images/quotation.svg" /></i>
                                                    <label>Quotation</label>
                                                    <strong>{profileData.QuotationCount}</strong>
                                                </Link>
                                            </div>

                                        </div>

                                        <div class="viewProfileTable">

                                            <ul>
                                                <li>
                                                    <div class="label">Email</div>
                                                    <div class="value">{profileData.Email}</div>
                                                </li>

                                                <li>
                                                    <div class="label">Contact No.</div>
                                                    <div class="value">{profileData.ContactNo}</div>
                                                </li>
                                            </ul>

                                            <div class="profileAction">
                                                <Link to="/editprofile" >Edit Profile</Link> |<Link to="/changepassoword" >Change Password</Link>
                                            </div>

                                        </div>


                                    </section>

                                </section>
                            </section>
                        </>)
                }
            })()
            }
        </>
    )
}

export default connect((state, props) => (
    {
        isLoggedIn: state.Login.isLoggedIn,
        profileData: state.profile.payload != null ? state.profile.payload : null,
        error: state.profile.FailMessage
    }),
    { ProfileStatistics, HandleAuthResponse }
)(MyProfile)
