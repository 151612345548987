import $ from 'jquery';
const HandleFloatingInputBlur = (e) => {
    if (e.target.value != "") {
        e.target.nextElementSibling.classList.add('activeLabel');
        e.target.classList.add('activeInput');
    } else {
        e.target.nextElementSibling.classList.remove('activeLabel');
        e.target.classList.remove('activeInput');
    }
};
export default HandleFloatingInputBlur;
export const QuotationDetailToggle = () => {
    var widowWidth = $(window).width();
    if (widowWidth <= 980) {
        $("#quoteDetail").toggle();
        $("#quoteListing").toggle();
        $("html, body").animate({ scrollTop: 0 }, "slow");
    }
    // else {
    //     alert("Only Works in responsive 980");
    // }
}
export function openMenu(){
	$('body').addClass("lock");
	$('#menuOverlay').fadeIn("fast");
	$('.headerMain nav').addClass("activeView");
    $('#homeSlider .slick-prev').css('z-index','0');
}

// For Responsive :: Hide Slide Menu	
export function hideMenu(){			
	$('.headerMain nav').removeClass("activeView");
	$('#menuOverlay').fadeOut('fast');			
	$('body').removeClass("lock");	
    $('#homeSlider .slick-prev').css('z-index','700');
}
export function toggleFilterPanel() {
    
    $("body").toggleClass("lock");
    $(".productFilter").toggleClass("activeView");
    $(".responsiveFilterTabOverlay").toggle();
  }

 export function DetectMobileDevice() {
    
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

