import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { fetchproductdetail } from '../../redux/actions/productdetailActions';
import { IMAGE_ROOT } from '../../api-config';
import { useParams, Link, useHistory } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import SubHeader from '../Shared/subHeader';
import $, { data } from 'jquery';
import { addToCart, AddQuantity, SubtractQuantity, AddToCartResetSuccess } from '../../redux/actions/addToCartAction';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginComponent from '../login/LoginComponent';
import { AddOrRemoveFavoriteProduct } from '../../redux/actions/favoriteProductAction';
import { HandleAuthResponse } from '../../services/api-response';
import { groupBy, remove, isEqual } from 'lodash';
import { ShadeCard } from '../Shared/shades';
import { fileIcons } from '../Shared/fileIcons';
import ImgsViewer from "react-images-viewer";
import '../Shared/wheelzoom.js';
import { resources } from '../../resource';

import { chain } from 'lodash';

const ProductDeatilWithScketchfabDemo = (
    {
        addToCart, AddQuantity, SubtractQuantity, AddOrRemoveFavoriteProduct,
        fetchproductdetail,
        fetchproductdetailpagedata,
        fetchRelatedProductData,
        loading, isLoggedIn,
        isProductAddOrRemoveToFavorites,
        HandleAuthResponse,
        error, isSuccessSave, fetchProductFileList, AddToCartResetSuccess
    }
) => {
    let ProductName;
    let isFavorites;
    let ProductDescription, ProductID, ProductDeatilData, IsCustomize;
    let ProductTabDescription;

    var containerSD = React.createRef();
    //sketchfab
    var DEFAULT_URLID = 'e374a66c99694501b7877585d9c79dd7';
    const [parameter, setParameter] = useState(null);
    var config = null;
    var options = [];
    const [dimension, setDimension] = useState(new Map());
    const [nodevalue, setNodes] = useState();
    const [heightRangeValue, setHeightRangeValue] = useState();
    const [depthRangeValue, setDepthRangeValue] = useState();
    const [lengthRangeValue, setLengthRangeValue] = useState();
    let material;
    const [RangeHeight, setRangeHeight] = useState(new Map());

    const [api, setApi] = useState(null);
    var parameters = null;
    var ticketNo = '';

    const user = JSON.parse(localStorage.getItem('user'));

    const [openModal, setOpenModal] = useState(false);
    let param = useParams();
    const history = useHistory();
    const trackProductDetailTab = useRef();
    const [quantityInput, setQuantityInput] = useState(1);
    const [btnLoader, setBtnLoader] = useState(false);


    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [isShapeDiverLoaded, setShapeDiverLoaded] = useState(false);
    const [dimensionsVisibility, setDimensionsVisibility] = useState({});

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        Infinite: true,
        arrows: true,
        fade: true,
        // autoplay: true,
        // speed: 1000,
        lazyLoad: 'ondemand',
        adaptiveHeight: true,
    };

    const settingsThumbs = {
        slidesToShow: fetchproductdetailpagedata != null ? fetchproductdetailpagedata.length : 3,
        slidesToScroll: 1,
        Infinite: true,
        centerPadding: '0px',
        dots: false,
        centerMode: false,
        draggable: true,
        // speed: 1000,
        // autoplay: true,
        focusOnSelect: true
    };

    const [isShapeDiverIsAlreadyLoaded, setShapeDiverIsAlreadyLoaded] = useState(false);
    const [rangeValue, setRangeValue] = useState({});
    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);


    useEffect(() => {
        HandleAuthResponse(error, history);
    }, [error])
    const [parameterGroup, setParameterGroup] = useState(null);
    const [defualtParameterValue, setDefualtParameterValue] = useState({});

    useEffect(() => {
        setParameterGroup(parameterGroup);
    }, [parameterGroup])



    async function InitShapeDiver() {

        if (isShapeDiverIsAlreadyLoaded)
            return;

        document.getElementById('btnCustomize').style.display = 'none';
        document.getElementById('btnRequestForQuote').style.paddingLeft = '0';
        containerSD.current.innerHTML = '';
        let _container = containerSD.current;

        // ShapeDiver Viewer constructor settings
        // Refer to https://app.shapediver.com/api for details
        let settings = {
            container: _container,
            showSceneMode: 1 // do not show the scene automatically
        };
        // construct an instance of the viewer
        const shapeDiverApi = new window.SDVApp.ParametricViewer(settings);
        setApi(shapeDiverApi);

        // register a ShapeDiver CommPlugin
        await shapeDiverApi.plugins.registerCommPluginAsync({
            // ticket of the model as shown on app.shapediver.com
            ticket: ticketNo,
            // URL of the ShapeDiver backend system used
            modelViewUrl: 'https://sdeuc1.eu-central-1.shapediver.com',
            // runtime id to use for this CommPlugin (you might register several)
            runtimeId: 'CommPlugin_1',
            // the following setting tells the viewer to wait with loading of geometry
            deferGeometryLoading: true
        });
        console.log('ShapeDiver CommPlugin successfully loaded');
        // var globalDiv = document.getElementById("parameters");
        // get parameters of the model
        parameters = shapeDiverApi.parameters.get().data;

        const removeByParameter = remove(parameters, function (n) {
            if (n.name === "Dimensions Visibility") {
                // dimensionsVisibility = n;
                setDimensionsVisibility(n);
            } else if (n.group.name === "Default Values") {
                setDefualtParameterValue(n);
            }
            // dimensionsVisibility = n.name == "Dimensions Visibility";
            return n.name != "Dimensions Visibility" && n.group.name != "Default Values";
        });

        const groupByParameter = groupBy(removeByParameter, function (n) {
            return n.group.id;
        });

        setParameterGroup(groupByParameter);


        console.log('Available model parameters', parameters);

        // optionally change parameter values before showing the scene
        //await this.api.parameters.updateAsync([
        //  {name: INSERT_NAME_OF_PARAMETER, value: INSERT_VALUE},
        //  {id: INSERT_ID_OF_PARAMETER, value: INSERT_VALUE},
        //]);

        // refresh (load geometry), because the initial parameter update might not have changed any values
        await shapeDiverApi.plugins.refreshPluginAsync('CommPlugin_1');

        // finally show the scene
        await shapeDiverApi.updateSettingAsync('scene.show', true);

        setShapeDiverIsAlreadyLoaded(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchproductdetail("king-headboard", user != null ? user.UserID : null, false);
        // slider()
        setNav1(slider1);
        setNav2(slider2);
    }, [param.slug]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://viewer.shapediver.com/v2/2.28.0/sdv.concat.min.js";

        // document.head.appendChild(script);
        document.head.insertBefore(script, document.querySelector('title'));

        // const style = document.createElement('link');
        // style.rel = 'stylesheet';
        // style.type = 'text/css';
        // style.href = 'https://viewer.shapediver.com/v2/2.28.0/sdv.css';
        // // document.head.appendChild(style);
        // document.head.insertBefore(style, document.querySelector('title'));

        return () => {
            document.head.removeChild(script);
            // document.head.removeChild(style);
        }
    }, [])

    function ProductTabClick(e) {
        var tabContentId = $(e.target).attr("data-tabcontent");

        $(".tabs a").removeClass("active");
        $(e.target).addClass("active");

        $(".tabContent").hide();
        $("#" + tabContentId).fadeToggle();
    }

    const ReadMoreDetailOnClick = () => {
        if (trackProductDetailTab.current) {
            trackProductDetailTab.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }

    const addToCartClick = (data) => {
        // setLoading(true);
        setBtnLoader(true);
        let element = document.getElementById("btnRequestQuote");
        element.classList.add("loading-state");
        element.classList.add("state-change");
        if (document.getElementById('btnCustomize') != null)
            document.getElementById('btnCustomize').style.display = 'none';
        addToCart(data, defualtParameterValue);
        // fetchproductdetail(param.slug, user != null ? user.UserID : null, true);
    }
    useEffect(() => {
        let element = document.getElementById("btnRequestQuote");
        if (isSuccessSave === true) {
            setTimeout(() => {
                setBtnLoader(false);
                element.classList.remove("loading-state");
            }, 1000);

            setTimeout(() => {
                element.classList.add("success-state");
                setTimeout(() => {
                    element.classList.remove("success-state");
                }, 1200);
            }, 1500);

            setTimeout(() => {
                setBtnLoader(false);
                AddToCartResetSuccess();
                element.classList.remove("state-change");
                // document.getElementById("isSuccessfullySaveMsg").style.display = "none";
                window.scrollTo(0, 0);
            }, 2400);
        }
    }, [isSuccessSave]);
    function makeFavourite(e) {
        // alert(isfavorites)
        if (isLoggedIn) {
            AddOrRemoveFavoriteProduct(ProductID, user != null ? user.UserID : null);
            // fetchproductdetail(param.slug, user != null ? user.UserID : null, true);
        } else {
            setOpenModal(true);

        }
    }

    function SelectOption(optionLabel, optionId, element) {
        
        if (optionId == undefined || optionId == '') {
            document.getElementById('productAttribute').classList.remove('activeView');
            document.getElementsByTagName('body')[0].classList.remove('lock');

        } else {
            document.querySelector('#productAttribute h3').innerHTML = optionLabel;
            document.querySelectorAll('.selectOptions').forEach(function (el) { el.style.display = 'none' });
            document.getElementById(optionId).style.display = 'block';
            document.getElementById('productAttribute').classList.add('activeView');
            document.getElementsByTagName('body')[0].classList.add('lock');
            // element.currentTarget.parentElement.classList.add('selected');
        }
    }

    function ProductThumbnailClick() {
        setParameterGroup(null);
        setShapeDiverIsAlreadyLoaded(false);
        if (document.getElementById('btnCustomize') != null)
            document.getElementById('btnCustomize').style.display = 'block';
        document.getElementById('btnRequestForQuote').style.paddingLeft = '0%';
        document.getElementById('productAttribute').classList.remove('activeView');
        document.getElementsByTagName('body')[0].classList.remove('lock');
    }

    function SetAttributeValue() {

        var visibleAttr = Array.from(document.querySelectorAll('#productAttribute .selectOptions')).filter(s =>
            window.getComputedStyle(s).getPropertyValue('display') != 'none'
        );
        document.querySelector('[data-group="' + visibleAttr[0].id + '"]').parentElement.classList.add('selected');
        document.getElementById('productAttribute').classList.remove('activeView');
        document.getElementsByTagName('body')[0].classList.remove('lock');
        if (visibleAttr[0].children[0].className == 'slider') {
            var dimensionList = [];
            for (let index = 0; index < visibleAttr[0].children.length; index++) {
                // console.log(visibleAttr[0].children[index].children[0].children[1].textContent)
                dimensionList.push(visibleAttr[0].children[index].children[0].children[1].textContent);
            }
            document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children[1].innerText = dimensionList.join(' X ');
        } else if (visibleAttr[0].children[0].className == 'radioBtn') {
            for (let index = 0; index < visibleAttr[0].children.length; index++) {
                if (visibleAttr[0].children[index].children[0].checked) {
                    document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children[1].innerHTML = visibleAttr[0].children[index].children[1].outerHTML + " " + visibleAttr[0].children[index].children[3].outerHTML;
                    document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children[1].children[0].style.borderRadius = '50px';
                    document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children[1].children[0].style.width = '40px';
                }
            }
        }
        // document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children
    }
    const [radioValue, setRadioValue] = useState(0);
    function RadioChanged(paramID, e) {
        setRadioValue(e.currentTarget.value);
        api.parameters.updateAsync({
            id: paramID,
            value: e.currentTarget.value
        })
    }

    const [isOpen, setIsOpen] = useState(false);
    const [currImg, setCurrImg] = useState(0);

    function CloseImgsViewer() {
        setCurrImg(0);
        setIsOpen(false);
    }

    function GotoPrev() {
        setCurrImg(currImg - 1);
    }

    function GotoNext() {
        setCurrImg(currImg + 1);
    }
    const ImageClick = (currIndex) => {
        setCurrImg(currIndex);
        setIsOpen(true);
        setTimeout(function () {
            window.wheelzoom(document.querySelector('#viewerBackdrop figure img'));
            document.querySelector('#viewerBackdrop figure img').classList.add('zoom-in-cursor');
            document.querySelector('#viewerBackdrop figure img').addEventListener('touchstart', function (e) {
                if (e.originalEvent.touches.length > 1) {
                    window.wheelzoom(document.querySelector('#viewerBackdrop figure img'));
                    console.log("Touch event called");
                }
            }, false);
        }, 500);
    }






    useEffect(() => {

        if (dimension.size > 0) {

            setDimension(dimension);

            let data = dimension;
            let keys = Array.from(data.keys());
            let MinRangeValue = chain(keys).sort().first().value();
            setLengthRangeValue(MinRangeValue.split('*')[0]);
            setDepthRangeValue(MinRangeValue.split('*')[1]);
            setHeightRangeValue(MinRangeValue.split('*')[2])

        }

    }, [dimension.size])
    useEffect(() => {
        if (options.length > 0) {
            setParameter(options);
        }
    }, [parameter]);
    useEffect(() => {
        setHeightRangeValue(heightRangeValue);
    }, [heightRangeValue]);
    useEffect(() => {
        setLengthRangeValue(lengthRangeValue);
    }, [lengthRangeValue]);
    useEffect(() => {
        setDepthRangeValue(depthRangeValue);
    }, [depthRangeValue]);
    useEffect(() => {
        if (RangeHeight.length > 0)
            setRangeHeight(RangeHeight)
    }, [RangeHeight]);

    function InitSketchfabDemo(config) {
        
        if (isShapeDiverIsAlreadyLoaded)
            return;

        config = config;
        var client = new window.Sketchfab(containerSD.current);
        client.init(DEFAULT_URLID, {
            ui_infos: 0,
            ui_controls: 0,
            graph_optimizer: 0,
            // autospin: 0.1,
            continuousRender: 1,
            preload: 1,
            ui_stop: 0,
            ui_watermark: 0,

            success: function onSuccess(api) {
                
                setShapeDiverIsAlreadyLoaded(true);
                setApi(api);
                api.start();
                api.addEventListener('viewerready', function () {

                    // api.getMaterialList((err, materials) => {

                    //     material = materials[0]; // There's only one material in this case

                    //     var textureElement = document.getElementsByClassName('btnChangeValue');
                    //     // api.addTexture('https://engrossinfotech.com/shadecard/texture2.jpg', (err, uid) => {

                    //     //     if (!err) {
                    //     //         textureid = uid;
                    //     Array.from(textureElement).forEach(function (element) {
                    //         element.addEventListener('click', (e) => {

                    //             api.addTexture(element.src, (err, textureid) => {
                    //                 let materialToUpdate = material;
                    //                 materialToUpdate.channels.AlbedoPBR.texture.uid = textureid;
                    //                 materialToUpdate.channels.AlbedoPBR.enable = true;
                    //                 api.setMaterial(materialToUpdate, () => {
                    //                     window.console.log('Updated material.');
                    //                 });
                    //             });
                    //         });
                    //     })
                    //     // document.getElementsByClassName('btnChangeValue').addEventListener('click', () => {
                    //     //     
                    //     //     let materialToUpdate = material;
                    //     //     materialToUpdate.channels.AlbedoPBR.texture.uid = textureid;
                    //     //     materialToUpdate.channels.AlbedoPBR.enable = true;
                    //     //     api.setMaterial(materialToUpdate, () => {
                    //     //         window.console.log('Updated material.');
                    //     //     });
                    //     // });
                    //     // }
                    //     // });

                    // });

                    api.getNodeMap(function (err, nodes) {
                        
                        if (err) {
                            console.error(err);
                            return;
                        }
                        setNodes(nodes);
                        var node;
                        var isOptionObject = false;
                        var keys = Object.keys(nodes);
                        var isDefaultShawn = false;
                        for (var i = 0; i < keys.length; i++) {

                            node = nodes[keys[i]];
                            if (node.name != undefined && node.name.includes('LDH') && node.type === 'Group') {

                                if (!isDefaultShawn) {
                                    isDefaultShawn = true;
                                    // setHeightRangeValue(node.name.split('_')[1])
                                    api.show(node.children[0].instanceID);
                                } else {
                                    api.hide(node.children[0].instanceID);
                                }

                                var key = node.name.split('_')[1];
                                var value = node.children[0].instanceID;

                                setDimension(dimension.set(key, value));
                            }
                            // if (node.name != undefined && node.name.includes('Length') && node.type === 'Group') {
                            //     var key = node.name.split('_')[1];
                            //     var value = node.children[0].instanceID;
                            //     RangeLength.set(key, value);
                            //     setRangeLength(RangeLength);
                            //     setLengthRangeValue(1)
                            //     api.hide(node.children[0].instanceID);
                            // }
                            // if (node.name != undefined && node.name.includes('Depth') && node.type === 'Group') {
                            //     var key = node.name.split('_')[1];
                            //     var value = node.children[0].instanceID;
                            //     RangeDepth.set(key, value);
                            //     setRangeDepth(RangeDepth);
                            //     setDepthRangeValue(1);
                            //     api.hide(node.children[0].instanceID);
                            // }


                            isOptionObject =
                                // node.name.indexOf(DEFAULT_PREFIX) !== -1 &&
                                node.name != 'Scene - RootModel' && node.name != 'Scene - PolygonNode' && node.name != undefined && node.name != 'root' &&
                                (node.type === "Group");
                            if (isOptionObject) {
                                // childrenData = node.children;
                                // for (let index = 0; index < childrenData.length; index++) {

                                // } 
                                options.push({
                                    id: node.instanceID,
                                    // name: node.name,
                                    selected: false
                                });
                                api.show(node.instanceID)

                            }
                        }
                        setParameter(options);
                        // selectOption(0)

                    });


                    // initializeOptions(function () {
                    //     console.log('Found the following options:', options);
                    //     selectOption(0);
                    // }.bind(this));

                }.bind(this));
            }.bind(this),
            error: function onError() {
                console.log('Viewer error');
            }
        });
    }
    function addTextured(e) {
        let imagesrcValue = e.currentTarget.value;
        // 
        // api.getMatrix(nodevalue[414], function (err, matrix) {
        //     
        //     if (!err) {
        //         window.console.log('Matrix:', matrix);
        //     } else { console.log('dd') }
        //     console.log('asdasd')
        // });

        // api.getMatrix(nodevalue[426].instanceID, function (err, matrix) {
        //     if (!err) {
        //         window.console.log('Matrix:', matrix);
        //     }
        // });

        // api.setMatrix(412, [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1], function (err) {
        //     if (!err) {
        //         window.console.log('Matrix set');
        //     }
        // });

        api.getMaterialList((err, materials) => {

            material = materials[0]; // There's only one material in this case

            var textureElement = document.getElementsByClassName('btnChangeValue');
            // api.addTexture('https://engrossinfotech.com/shadecard/texture2.jpg', (err, uid) => {

            //     if (!err) {
            //         textureid = uid;
            // Array.from(textureElement).forEach(function (element) {
            //     element.addEventListener('click', (e) => {

            api.addTexture(imagesrcValue, (err, textureid) => {
                let materialToUpdate = material;
                materialToUpdate.channels.AlbedoPBR.texture.uid = textureid;
                materialToUpdate.channels.AlbedoPBR.enable = true;
                api.setMaterial(materialToUpdate, () => {
                    window.console.log('Updated material.');
                });
            });
            //     });
            // })
            // document.getElementsByClassName('btnChangeValue').addEventListener('click', () => {
            //     
            //     let materialToUpdate = material;
            //     materialToUpdate.channels.AlbedoPBR.texture.uid = textureid;
            //     materialToUpdate.channels.AlbedoPBR.enable = true;
            //     api.setMaterial(materialToUpdate, () => {
            //         window.console.log('Updated material.');
            //     });
            // });
            // }
            // });

        });

    }

    function selectOption(index) {

        var options = parameter;
        for (var i = 0, l = options.length; i < l; i++) {
            if (i === index) {
                options[i].selected = true;
                api.show(options[i].id);
            }
            else {
                options[i].selected = false;
                api.hide(options[i].id);
            }
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.sketchfab.com/api/sketchfab-viewer-1.1.0.js";

        // document.head.appendChild(script);
        document.head.insertBefore(script, document.querySelector('title'));

        // setTimeout(function () {
        //     InitSketchfabDemo(config);
        // }, 200);

        return () => {
            document.head.removeChild(script);
        }
    }, [])

    var FinishWoodImagePath = [
        { "url": "https://engrossinfotech.com/shadecard/texture2.jpg", "name": "ULA1001" },
        { "url": "https://engrossinfotech.com/shadecard/texture3.jpg", "name": "ULA1002" },
    ];


    function OnChangeSetAttributeValue() {
        var visibleAttr = Array.from(document.querySelectorAll('#productAttribute .selectOptions')).filter(s =>
            window.getComputedStyle(s).getPropertyValue('display') != 'none'
        );

        document.querySelector('[data-group="' + visibleAttr[0].id + '"]').parentElement.classList.add('selected');
        // document.getElementById('productAttribute').classList.remove('activeView');
        document.getElementsByTagName('body')[0].classList.remove('lock');
        if (visibleAttr[0].children[0].className == 'slider') {
            var dimensionList = [];
            for (let index = 0; index < visibleAttr[0].children.length; index++) {
                // console.log(visibleAttr[0].children[index].children[0].children[1].textContent)
                dimensionList.push(visibleAttr[0].children[index].children[0].children[1].textContent);
            }
            document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children[1].innerText = dimensionList.join(' X ');
        } else if (visibleAttr[0].children[0].className == 'radioBtn') {
            for (let index = 0; index < visibleAttr[0].children.length; index++) {
                if (visibleAttr[0].children[index].children[0].checked) {
                    document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children[1].innerHTML = visibleAttr[0].children[index].children[1].outerHTML + " " + visibleAttr[0].children[index].children[3].outerHTML;
                    document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children[1].children[0].style.borderRadius = '50px';
                    document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children[1].children[0].style.width = '40px';
                }
            }
        }
        // document.querySelector('[data-group="' + visibleAttr[0].id + '"]').children
    }


    return (
        <>
            <LoginComponent trigger={openModal} setTrigger={setOpenModal} openModal></LoginComponent>
            <SubHeader CurrentPageName={'Product Detail'} />
            <section className="productDetail">
                <section className="wrapper">

                    <div className="images">
                        <div className='main-img-slider'>
                            {(() => {
                                const IMG_SET = [];
                                if (!isShapeDiverLoaded) {
                                    return (
                                        <>
                                            <Slider
                                                {...settingsMain}
                                                asNavFor={nav2}
                                                ref={slider => (setSlider1(slider))}>
                                                {(() => {
                                                    if (fetchproductdetailpagedata != null && fetchproductdetailpagedata.length > 0 && !loading) {
                                                        return (
                                                            fetchproductdetailpagedata.map((data, key) => {
                                                                var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ImageName;
                                                                var item = {};
                                                                item["src"] = imgpath;
                                                                item["caption"] = "Use mouse wheel to zoom.";
                                                                IMG_SET.push(item);
                                                                return (
                                                                    <div key={Math.random()}>
                                                                        <img className="zoom slick-slide-image" loading='lazy'
                                                                            srcSet={`${imgpath} 1000px,${imgpath} 800px`}
                                                                            sizes="(max-width: 600px) 1000px,800px"
                                                                            src={imgpath} height='600px' onClick={() => { ImageClick(key) }} />
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                    } else if (loading) {
                                                        return (
                                                            <Skeleton className='product-detail-skeletonloader' />
                                                        )
                                                    }
                                                })()}
                                            </Slider>
                                            <ImgsViewer
                                                imgs={IMG_SET}
                                                currImg={currImg}
                                                isOpen={isOpen}
                                                onClickPrev={GotoPrev}
                                                onClickNext={GotoNext}
                                                onClose={CloseImgsViewer}
                                            />
                                        </>
                                    )
                                }
                                else if (!isShapeDiverIsAlreadyLoaded) {
                                    return (
                                        <>
                                            <Skeleton className='product-detail-skeletonloader' />
                                        </>
                                    )
                                }
                            })()}
                            {/* <div ref={containerSD} id='sdv-container' style={{ width: '100%', height: '600px', display: isShapeDiverLoaded && isShapeDiverIsAlreadyLoaded ? 'block' : 'none' }}>
                            </div> */}
                            <iframe
                                // We feed the ref to the iframe component to get the underlying DOM object
                                ref={containerSD}
                                title="sketchfab-viewer"
                                style={{ width: '100%', height: '700px', border: 0, display: isShapeDiverLoaded && isShapeDiverIsAlreadyLoaded ? 'block' : 'none' }}
                            />
                        </div>
                        <div className="thumbnail-slider-wrap">
                            <Slider
                                {...settingsThumbs}
                                asNavFor={nav1}
                                ref={slider => (setSlider2(slider))}
                            >

                                {(() => {
                                    if (fetchproductdetailpagedata != null && fetchproductdetailpagedata.length > 0 && !loading) {
                                        return (
                                            fetchproductdetailpagedata.map((data, key) => {
                                                var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ImageName;
                                                return (

                                                    <div key={Math.random()}>
                                                        <img className="slick-slide-image" loading='lazy' src={imgpath} onClick={() => { setShapeDiverLoaded(false); ProductThumbnailClick(); }} />
                                                    </div>
                                                )
                                            })
                                        )

                                    } else if (loading) {
                                        return (
                                            <Skeleton height={105} width={105} />
                                        )
                                    }
                                })()}

                            </Slider>
                        </div>
                    </div>
                    {(() => {
                        if (fetchproductdetailpagedata != null && fetchproductdetailpagedata.length > 0 && !loading) {
                            ProductName = fetchproductdetailpagedata[0].ProductName;
                            ProductDescription = fetchproductdetailpagedata[0].ProductDescription.substring(0, 490) + "...";
                            ProductTabDescription = fetchproductdetailpagedata[0].ProductDescription;
                            ProductID = fetchproductdetailpagedata[0].ProductID;
                            ProductDeatilData = fetchproductdetailpagedata[0];
                            isFavorites = fetchproductdetailpagedata[0].IsFavorites;
                            ticketNo = fetchproductdetailpagedata[0].TicketNo;
                            IsCustomize = fetchproductdetailpagedata[0].IsCustomize;
                            return (
                                <div className="info">
                                    <div style={{ display: 'flex' }}>
                                        <h2 style={{ marginRight: 'auto' }} >{ProductName}</h2>
                                        <div className="makeFavourite">

                                            {/* <a href="javascript:void(0);" onClick={makeFavourite} className={isFavorites == true || isProductAddOrRemoveToFavorites == 1 ? 'activeFav' : ''}></a> */}
                                            {(() => {
                                                let favId = isProductAddOrRemoveToFavorites != null && isProductAddOrRemoveToFavorites.filter(x => x.ProductID == ProductID)

                                                if (favId.length > 0) {
                                                    return (
                                                        favId.map(x => {
                                                            return (
                                                                <>
                                                                    {(() => {
                                                                        if (x.ProductID == ProductID) {
                                                                            return (
                                                                                <a href="javascript:void(0);" onClick={makeFavourite} className='activeFav'></a>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </>
                                                            )
                                                        })
                                                    )
                                                }
                                                else if (isFavorites == true && isProductAddOrRemoveToFavorites == null) {
                                                    return (
                                                        <>
                                                            <a href="javascript:void(0);" onClick={makeFavourite} className='activeFav'></a>
                                                        </>
                                                    )
                                                } else if (isFavorites == false && isProductAddOrRemoveToFavorites == null) {
                                                    return (
                                                        <a href="javascript:void(0);" onClick={makeFavourite} className=''></a>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <a href="javascript:void(0);" onClick={makeFavourite} className=''></a>
                                                    )
                                                }


                                            })()}
                                        </div>
                                    </div>

                                    <div className="description">
                                        <p>{ProductDescription.substring(0, 490) + "..."}</p>
                                        <a onClick={ReadMoreDetailOnClick}>Read More Detail</a>
                                    </div>

                                    {/* Product Attribute */}

                                    <ul class="productAttribute">
                                        {(() => {

                                            if (dimension.size > 0 && isShapeDiverIsAlreadyLoaded) {
                                                return (

                                                    <>
                                                        <li>
                                                            <a href="javascript:;" data-group='Dimensions' onClick={(e) => { SelectOption('Select ' + 'Dimensions', 'Dimensions', e) }}>
                                                                <strong>Dimensions</strong>
                                                                <p>Click to Customize</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:;" data-group='TopFinish' onClick={(e) => { SelectOption('Select ' + 'Top Wood Finish', 'TopFinish', e) }}>
                                                                <strong>Top Wood Finish</strong>
                                                                <p>Click to Customize</p>
                                                            </a>
                                                        </li>
                                                    </>
                                                )
                                            }

                                        })()}
                                    </ul>



                                    <div className="btnGroup">

                                        {IsCustomize && !isShapeDiverIsAlreadyLoaded &&

                                            <div id='btnCustomize' className="buttons">
                                                <div className="submitBtn" >
                                                    <div className="btnLoader" style={{ display: 'none' }}>
                                                        <span className="spinLoader"></span>
                                                    </div>
                                                    <button className="product-btn" onClick={(e) => { setShapeDiverLoaded(true); InitSketchfabDemo(config); }} > Customize</button>
                                                </div>
                                            </div>
                                        }

                                        <div id='btnRequestForQuote' className="buttons">
                                            <div className="submitBtn" >
                                                {/*<div className="btnLoader" style={{ display: btnLoader === false ? 'none' : 'block' }}>
                                                <span className="spinLoader"></span>
                                            </div> */}
                                                <button onClick={() => { addToCartClick(ProductDeatilData) }} className="product-btn btn-cart" id='btnRequestQuote'>
                                                    <span class="btn-success">
                                                        <i class="fa fa-check"></i>
                                                        Succefully Added
                                                    </span>
                                                    <span class="btn-content">Request for Quote</span>
                                                    <span class="btn-loading">
                                                        <span className="btn-cart-loader"></span>
                                                    </span>

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                    {/* {isSuccessSave && !btnLoader && (

                                        <div className="alert alert-success" id="isSuccessfullySaveMsg" style={{ display: 'flex' }}>
                                            <img src="/assets/images/success-tik.svg" />
                                            <h1>Added To Cart Successfully!!</h1>
                                            <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                        </div>
                                    )} */}
                                    {/* <div className="buttons">
                                        <div className="submitBtn" style={{ width: '100%', maxWidth: '100%', marginTop: '20px' }}>
                                            <div className="btnLoader" style={{ display: 'none' }}>
                                                <span className="spinLoader"></span>
                                            </div>
                                            <button className="btn"> Request Drawing / cad file</button>
                                        </div>

                                    </div> */}


                                </div>
                            )
                        } else if (loading) {
                            return (
                                <Skeleton count={7} />
                            )
                        }
                    })()}
                </section>
            </section>
            <section className="wrapper productTabs">

                <div className="tabs">
                    <a onClick={ProductTabClick} data-tabcontent="ProductDetailTab" className="active">Product Detail</a>
                    {fetchProductFileList != null && fetchProductFileList.length > 0 &&
                        <a onClick={ProductTabClick} data-tabcontent="tab2">Documents</a>
                    }

                </div>

                <div className="tabContent" id="ProductDetailTab" style={{ display: 'block' }}>

                    <p ref={trackProductDetailTab}>{ProductTabDescription}</p>

                </div>

                <div class="tabContent" id="tab2" style={{ display: 'none' }}>

                    <div className="productFile-card-wrap">
                        {(() => {
                            if (fetchProductFileList != null && fetchProductFileList.length > 0) {

                                return (
                                    fetchProductFileList.map((e, key) => {
                                        return (
                                            fileIcons.icons
                                                .filter(icon => icon.name == /[^.]+$/.exec(e.Filename)[0])
                                                .map(filterIcons =>
                                                    <a href={IMAGE_ROOT + 'Documents/Products/' + e.Filename} className='productFile-wrap productFile-card productFile' target='_blank'>
                                                        <img src={filterIcons.imagePath} alt="Hospitality Image" />
                                                        <span className='productFile-detail'>{e.Filename}</span>
                                                    </a>
                                                )
                                        )
                                    })
                                )
                            }

                        })()}

                    </div>

                </div>




            </section>

            <section className="relatedProduct">
                <section className="wrapper">

                    <h3>
                        <label>Related Product</label>
                        You May Also Like this
                    </h3>

                    {(() => {
                        if (fetchRelatedProductData != null && fetchRelatedProductData.length > 0 && !loading) {
                            return (
                                <div class="procommerce">
                                    <ul className="productList products --skin-cards grid4">
                                        {
                                            fetchRelatedProductData.map((data, key) => {

                                                // let ImageColorData = JSON.parse(data.ImageAndColorJson);
                                                var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ImageAndColorJson;
                                                return (<>


                                                    <li key={Math.random()} id={key} class="product wc-skin--cards is-animated is-animated-entry --animated-in">
                                                        <Link to={`/products/${data.Slug}`}>
                                                            <div class="product-card">
                                                                <div class="product-img">
                                                                    <img src={imgpath} data-product={key} style={{ display: "inline" }}></img>

                                                                </div>
                                                            </div>
                                                            <div class="product-desc-wrap">
                                                                <div class="product-desc">
                                                                    <p class="product-name">{data.ProductName}</p>

                                                                </div>
                                                            </div>
                                                        </Link>

                                                    </li>
                                                </>
                                                )
                                            })}
                                    </ul>
                                </div>
                            )
                        } else if (fetchRelatedProductData != null && fetchRelatedProductData.length == 0) {
                            return (
                                <>
                                    <div className="noRecord" style={{ display: "block" }}>
                                        <picture>
                                            <source srcset="/assets/images/no_search_result.webp" type="image/webp" />
                                            <img src="/assets/images/no_search_result.png" />
                                        </picture>
                                        <label>There are no related products!</label>
                                        {/* <p>We couldn't find any result.</p> */}
                                    </div>
                                </>
                            )
                        } else if (loading) {
                            return (
                                <ul className="productList grid4">
                                    <li>
                                        {/* <div className="thumb"> */}
                                        <Skeleton height={295} width={405} style={{ display: 'block', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} />
                                        {/* </div> */}
                                        <div className='colorVariation'>
                                            <Skeleton style={{ display: 'block', width: '20px', height: '20px', border: '2px solid #fff', borderRadius: '100%', boxShadow: '0.8px 3px 4px 0px rgb(0 0 0 / 36%)' }} >
                                            </Skeleton>
                                        </div>
                                    </li>
                                </ul>
                            )
                        }
                    })()}


                </section>
            </section>

            <section class="productAttributePanel" id="productAttribute">

                <h2>Product Attributes</h2>

                {(() => {

                    if (dimension.size != 0) {

                        let data = dimension;
                        let keys = Array.from(data.keys());
                        let MaxRangeValue = chain(keys).sort().last().value();
                        let MinRangeValue = chain(keys).sort().first().value();
                        return (
                            <>
                                <h3></h3>
                                <div className='selectOptions' id='Dimensions' style={{ display: 'none' }}>
                                    <div className='slider'>
                                        <div class="sliderTitle">
                                            <label>Height</label>
                                            <p className='rangeval'>{heightRangeValue + "″  High"}</p>
                                        </div>
                                        <input type='range' min={MinRangeValue.split('*')[2]} max={MaxRangeValue.split('*')[2]} value={heightRangeValue} step={5}
                                            onChange={(e) => {

                                                for (let [key, value] of dimension.entries()) {
                                                    if (key == lengthRangeValue + "*" + depthRangeValue + "*" + e.currentTarget.value.toString()) { // 30*30*35 = 30*30*35
                                                        setHeightRangeValue(e.currentTarget.value);
                                                        api.show(value);
                                                    } else {
                                                        // for (let [key, value] of RangeLength.entries()) {
                                                        //     api.hide(value);
                                                        // }
                                                        // for (let [key, value] of RangeDepth.entries()) {
                                                        //     api.hide(value);
                                                        // }
                                                        api.hide(value);
                                                    }
                                                }
                                                OnChangeSetAttributeValue();


                                            }} />
                                    </div>
                                    <div className='slider'>
                                        <div class="sliderTitle">
                                            <label>Length</label>
                                            <p className='rangeval'>{lengthRangeValue + "″  Long"}</p>
                                        </div>
                                        <input type='range' min={MinRangeValue.split('*')[0]} max={MaxRangeValue.split('*')[0]} value={lengthRangeValue} step={5}
                                            onChange={(e) => {

                                                for (let [key, value] of dimension.entries()) {
                                                    if (key == e.currentTarget.value.toString() + "*" + depthRangeValue + "*" + heightRangeValue) {
                                                        setLengthRangeValue(e.currentTarget.value);
                                                        api.show(value);
                                                    } else {
                                                        // for (let [key, value] of RangeHeight.entries()) {
                                                        //     api.hide(value);
                                                        // }
                                                        // for (let [key, value] of RangeDepth.entries()) {
                                                        //     api.hide(value);
                                                        // }
                                                        api.hide(value);
                                                    }
                                                }
                                                OnChangeSetAttributeValue();
                                            }}
                                        />
                                    </div>

                                    <div className='slider'>
                                        <div class="sliderTitle">
                                            <label>Depth</label>
                                            <p className='rangeval'>{depthRangeValue + "″  Deep"}</p>
                                        </div>
                                        <input type='range' min={MinRangeValue.split('*')[1]} max={MaxRangeValue.split('*')[1]} value={depthRangeValue} step={5}
                                            onChange={(e) => {

                                                for (let [key, value] of dimension.entries()) {
                                                    if (key == lengthRangeValue + "*" + e.currentTarget.value.toString() + "*" + heightRangeValue) {
                                                        setDepthRangeValue(e.currentTarget.value);
                                                        api.show(value);
                                                    } else {
                                                        api.hide(value);
                                                    }
                                                }
                                                OnChangeSetAttributeValue();

                                            }}
                                        />
                                    </div>

                                </div>
                            </>
                        )
                        // return (
                        //     Object.entries(parameterGroup).map(([key, data]) => {
                        //         return (
                        //             <>
                        //                 <h3></h3>
                        //                 <div class="selectOptions" id={data[0].group.id} style={{ display: 'none' }}>
                        //                     {
                        //                         Object.entries(data).map(([key, param]) => {

                        //                             return (
                        //                                 <>


                        //                                     {(() => {
                        //                                         if ((param.type == "Int" || param.type == "Float" || param.type == "Even" || param.type == "Odd") && !param.hidden) {
                        //                                             return (
                        //                                                 <>
                        //                                                     <div className='slider'>
                        //                                                         <div class="sliderTitle">
                        //                                                             <label>{param.name}</label>
                        //                                                             <p className='rangeval'>{param.name === "Diameter" || param.name === "Length" ? param.value + "″  Long" : param.name === "Depth" ? param.value + "″  Deep" : param.name === "Height" ? param.value + "″  High" : param.value}</p>
                        //                                                         </div>
                        //                                                         <input id={param.id} type="range" min={param.min} max={param.max} value={rangeValue.value} name={param.name}
                        //                                                             step={param.type == 'Int' ? 1 : param.type == 'Even' || param.type == 'Odd' ? 2 : 1 / Math.pow(10, param.decimalplaces)}
                        //                                                             onChange={(e) => {
                        //                                                                 const { name, value } = e.target;
                        //                                                                 setRangeValue({ [name]: value })
                        //                                                                 e.currentTarget.parentElement.children[0].children[1].innerText = param.name === "Diameter" || param.name === "Length" ? e.currentTarget.value + "″  Long" : param.name === "Depth" ? e.currentTarget.value + "″  Deep" : param.name === "Height" ? e.currentTarget.value + "″  High" : param.value;
                        //                                                                 api.parameters.updateAsync([
                        //                                                                     { id: param.id, value: e.currentTarget.value },
                        //                                                                     { id: dimensionsVisibility.id, value: 1 }]);

                        //                                                             }} />
                        //                                                     </div>
                        //                                                 </>
                        //                                             )

                        //                                         } else if (param.type == "Bool" && !param.hidden) {
                        //                                             return (
                        //                                                 <label className='radioBtn' >
                        //                                                     <img src={param.defval} />
                        //                                                     <input id={param.id} type='radio' checked={param.value}
                        //                                                         onChange={(e) => {
                        //                                                             api.parameters.updateAsync({
                        //                                                                 id: param.id,
                        //                                                                 value: e.currentTarget.checked
                        //                                                             })
                        //                                                         }} />
                        //                                                     <span className='checkmark'></span>
                        //                                                     <strong>{param.name}</strong>
                        //                                                 </label>
                        //                                             )
                        //                                         } else if (param.type == "String" && !param.hidden) {
                        //                                             return (
                        //                                                 <input id={param.id} type="text" value={param.value}
                        //                                                     onChange={(e) => {
                        //                                                         api.parameters.updateAsync({
                        //                                                             id: param.id,
                        //                                                             value: e.currentTarget.value
                        //                                                         });
                        //                                                     }} />
                        //                                             )
                        //                                         } else if (param.type == "Color" && !param.hidden) {
                        //                                             return (
                        //                                                 <input id={param.id} type="color" value={param.value}
                        //                                                     onChange={(e) => {
                        //                                                         api.parameters.updateAsync({
                        //                                                             id: param.id,
                        //                                                             value: e.currentTarget.value
                        //                                                         });
                        //                                                     }} />
                        //                                             )
                        //                                         } else if (param.type == "StringList" && !param.hidden) {
                        //                                             return (
                        //                                                 // <select id={param.id} onChange={(e) => {
                        //                                                 //     api.parameters.updateAsync({
                        //                                                 //         id: param.id,
                        //                                                 //         value: e.currentTarget.value
                        //                                                 //     });
                        //                                                 // }} >
                        //                                                 //     {
                        //                                                 //         param.choices.map((e, key) => {
                        //                                                 //             return (
                        //                                                 //                 <>
                        //                                                 //                     <option value={key} name={e} {...param.value == key ? "selected" : ""} >{e}</option>
                        //                                                 //                 </>
                        //                                                 //             )
                        //                                                 //         })
                        //                                                 //     }
                        //                                                 // </select>





                        //                                                 param.choices.map((e, key) => {
                        //                                                     return (
                        //                                                         <>

                        //                                                             <label className='radioBtn' >
                        //                                                                 {(() => {
                        //                                                                     return (
                        //                                                                         ShadeCard.shades
                        //                                                                             .filter(shade => shade.TextureName == e)
                        //                                                                             .map(filteredShade =>
                        //                                                                             (
                        //                                                                                 <img src={filteredShade.Link} />
                        //                                                                             ))
                        //                                                                     )
                        //                                                                 })()
                        //                                                                 }
                        //                                                                 <input id={param.id} value={key} name={param.id} type='radio'
                        //                                                                     onChange={(e) => {
                        //                                                                         api.parameters.updateAsync({
                        //                                                                             id: param.id,
                        //                                                                             value: parseInt(e.currentTarget.value)
                        //                                                                         })
                        //                                                                     }} />
                        //                                                                 <span className='checkmark'></span>
                        //                                                                 <strong>{e}</strong>
                        //                                                             </label>
                        //                                                         </>
                        //                                                     )
                        //                                                 })




                        //                                             )
                        //                                         }

                        //                                     })()

                        //                                     }


                        //                                 </>
                        //                             )
                        //                         })

                        //                     }
                        //                 </div>
                        //             </>

                        //         )
                        //     })
                        // )
                    }
                })()}

                <div className='selectOptions' id='TopFinish' style={{ display: 'none' }}>
                    {(() => {
                        if (FinishWoodImagePath.length > 0) {
                            return (
                                FinishWoodImagePath.map((data, key) => {

                                    return (
                                        <>


                                            <label className='radioBtn' >
                                                <input type='radio' value={data.url} onClick={(e) => { addTextured(e); OnChangeSetAttributeValue() }} name="FinishWood" />
                                                <img src={data.url} style={{ objectPosition: '50% 50%', objectFit: 'none' }} className='btnChangeValue' />
                                                <span className='checkmark'></span>
                                                <strong>{data.name}</strong>
                                            </label>

                                        </>
                                    )
                                })
                            )
                        }
                    })()}
                </div>




                <div class="buttons">

                    <div class="btn borderBtn">
                        <div class="loader" style={{ display: 'none' }}>
                            <span class="spinLoader small"></span>
                        </div>
                        <a href="javascript:;" onClick={() => { SelectOption() }}>Close</a>
                    </div>

                    <div class="btn">
                        {/* <div class="loader" style={{ display: 'block' }}>
                            <span class="spinLoader small"></span>
                        </div> */}
                        <a href="javascript:;" onClick={() => { SetAttributeValue() }} >Done</a>
                    </div>

                </div>

            </section>

        </>

    )
}
export default connect((state, props) => (
    {
        fetchproductdetailpagedata: state.allProductdetailReducer.productdetail != null && state.allProductdetailReducer.productdetail.length > 0 ? state.allProductdetailReducer.productdetail[0].ProductDetailForWeb : null,
        fetchRelatedProductData: state.allProductdetailReducer.productdetail != null && state.allProductdetailReducer.productdetail.length > 0 ? state.allProductdetailReducer.productdetail[0].RelatedProductViewModelForWeb : null,
        loading: state.allProductdetailReducer.loading,
        isLoggedIn: state.Login.isLoggedIn,
        isProductAddOrRemoveToFavorites: state.favoriteProduct.payload != null ? state.favoriteProduct.payload : null,
        error: state.favoriteProduct.payload,
        isSuccessSave: state.cart.isSuccess,
        fetchProductFileList: state.allProductdetailReducer.productdetail != null && state.allProductdetailReducer.productdetail.length > 0 ? state.allProductdetailReducer.productdetail[0].ProductFilesForWeb : null,

    }),
    { addToCart, AddQuantity, SubtractQuantity, fetchproductdetail, AddOrRemoveFavoriteProduct, HandleAuthResponse, AddToCartResetSuccess }
)(ProductDeatilWithScketchfabDemo)