import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

export const ProfileStatistics = (
    UserID
) => async dispatch => {
    var formData = new FormData();
    formData.append("UserID", UserID);
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    return api.post('Account/ProfileStatistics', formData, { headers: headers })
        .then(
            (response) => {
                if (response.data.ResultCode === 'ERROR' || response.data.ResultCode === 'EXISTS') {
                    dispatch({
                        type: ActionTypes.PROFILESTATISTICS_FAIL
                    });

                } else if (response.data.ResultCode === 'SUCCESS') {
                    dispatch({
                        type: ActionTypes.PROFILESTATISTICS_SUCCESFULLY,
                        payload: response.data.ResultObject
                    });
                } else {
                    dispatch({
                        type: ActionTypes.PROFILESTATISTICS_FAIL,
                    });
                }
            }
        )
        .catch((error) => {
            dispatch({
                type: ActionTypes.PROFILESTATISTICS_FAIL,
                payload:error.response
            });
        })
}

export const UpdatePassword = (
    UserID, oldPassword, newPassword
) => async dispatch => {

    var formData = new FormData();
    formData.append("UserID", UserID);
    formData.append("OldPassword", oldPassword);
    formData.append("NewPassword", newPassword);
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    dispatch({ type: ActionTypes.LOADER_START });
    return api.post('Account/UpdatePassword', formData, { headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'ERROR' || response.data.ResultCode === 'EXISTS') {
                dispatch({
                    type: ActionTypes.UPDATE_PASSWORD_FAIL
                });

            } else if (response.data.ResultObjectID === -1 || response.data.ResultObjectID === -2) {
                dispatch({
                    type: ActionTypes.INVALID_UPDATE_PASSWORD,
                    payload: response.data.ResultMessage
                });
            }
            else if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.UPDATE_PASSWORD_SUCCESSFULLY,
                    payload: response.data.ResultMessage
                });
            } else {
                dispatch({
                    type: ActionTypes.UPDATE_PASSWORD_SUCCESSFULLY,
                });
            }
        }
    )
}

export function ResetUpdatePasswordState() {
    return { type: ActionTypes.UPDATE_PASSWORD_STATE_RESET }
}

export const ProfileDetails = (
) => async dispatch => {
    const user = JSON.parse(localStorage.getItem('user'));
    var formData = new FormData();
    formData.append("UserID", user.UserID);
    const headers = {
        Authorization: 'Bearer ' + user.token
    }
    dispatch({ type: ActionTypes.LOADER_START });
    return api.post('Account/GetUserProfileByIDForWeb', formData, { headers: headers }).then(
        (response) => {
            if (response.data.ResultCode === 'ERROR') {
                dispatch({
                    // type: ActionTypes.UPDATE_PASSWORD_FAIL
                });
            } else if (response.data.ResultCode === 'SUCCESS') {
                dispatch({
                    type: ActionTypes.GET_PROFILE_BY_ID,
                    payload: response.data.ResultObject
                });
            }
        }
    )
}

export const UpdateProfile = (
    entity
    ,mobileNo
    ,countryCode
) => async dispatch => {

    var user = new FormData();
    user.append("UserID", entity.userID);
    user.append("FullName", entity.fullName);
    user.append("ContactNo", mobileNo);
    user.append("FormFile", entity.profileImage);
    user.append("Address", entity.address);
    user.append("City", entity.city);
    user.append("State", entity.state);
    user.append("Zipcode", entity.pincode);
    user.append("Country", entity.country);
    // user.append("IsActive", true);
    user.append("ProfileImage", entity.oldProfileImage);
    user.append("ModifiedBy", entity.userID);
    user.append("ImageX",entity.imageX);
    user.append("ImageY",entity.imageY);
    user.append("ImageWidth",entity.imageWidth);
    user.append("ImageHeight",entity.imageHeight);
    user.append("CountryCode", countryCode);
    // user.append("ModifiedDate", new Date().toLocaleDateString());

    const JWT = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: 'Bearer ' + JWT.token
    }
    return api.post('Account/UpdateUserProfile', user, { headers: headers }).then(
        (response) => {
            dispatch({
                type: ActionTypes.UPDATE_PROFILE_RESPONSE,
                payload: response.data
            });
        }
    )
}