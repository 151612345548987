import React, { useEffect } from 'react';
import { IMAGE_ROOT } from '../../api-config';
import AOS from 'aos';
import "aos/dist/aos.css";
import { resources } from '../../resource';

const AboutUsComponet = ({ fetchAbountPageData, fetchTeamsData }) => {
    useEffect(() => {
        AOS.init({
            once: true,
        });
        AOS.refresh();
    }, [])
    return (
        <>
            {(() => {
                if (fetchAbountPageData != null) {
                    return (
                        <div>
                            <div className="box" dangerouslySetInnerHTML={{
                                __html: fetchAbountPageData.Content
                            }}>
                            </div>
                        </div>

                    )
                }
                else {
                    return (

                        <div>
                            <div className='skeleton-box' style={{ width: '100%' }}>
                            </div>
                        </div>
                    )

                }

            })()}

            <section className="blog-one">
                <div className="blog-one-bg"></div>
                <div className="wrapper">
                    {

                        (fetchTeamsData != null && fetchTeamsData != undefined && fetchTeamsData.length > 0) &&
                        fetchTeamsData.filter(x => x.MemberType == "Director").map((data, key) => {
                            const Description = data.Description.replace(/(<([^>]+)>)/gi, "");
                            let imgpath = IMAGE_ROOT + resources.OURTEAM_IMG_URL + data.Image;

                            if (key % 2 == 0) {
                                return (
                                    <>
                                        <div class="row aboutus-card" style={{ justifyContent: 'space-between' }}>
                                            <div class="col-xl-3 col-lg-3 col-md-6 aboutus-card-img" data-wow-delay="100ms">
                                                <div class="team-one__single">
                                                    <div class="director-one_border"></div>
                                                    <div class="team-one__img">
                                                        <img src={imgpath} alt=""></img>
                                                    </div>
                                                    <div class="why-choose-three__dot">
                                                        <img src="/assets/images/why-choose-three-dot.png" alt=""></img>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-9 col-lg-8 col-md-6 aboutus-card-desc" data-wow-delay="100ms">
                                                <div class="director-one__single">
                                                    <p class="director-one__single_text-1" dangerouslySetInnerHTML={{
                                                        __html: data.Description
                                                    }}>
                                                    </p>
                                                    <div class="director-one_des">
                                                        <h2 class="elementor-heading-title elementor-size-default"> {data.Name}</h2>
                                                        <p>{data.Designation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix p-3"></div>
                                    </>

                                )
                            } else if (Math.abs(key % 2) == 1) {

                                return (

                                    <>
                                        <div class="row aboutus-card" style={{ justifyContent: 'space-between' }}>
                                            <div class="col-xl-9 col-lg-8 col-md-6 aboutus-card-img" data-wow-delay="100ms">
                                                <div class="director-two__single">
                                                    <p class="director-one__single_text-2" dangerouslySetInnerHTML={{
                                                        __html: data.Description
                                                    }}>
                                                    </p>
                                                    <div class="director-one_des">
                                                        <h2 class="elementor-heading-title elementor-size-default"> {data.Name}</h2>
                                                        <p>{data.Designation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-6 aboutus-card-desc" data-wow-delay="100ms">
                                                <div class="team-one__single">
                                                    <div class="director-two_border"></div>
                                                    <div class="team-one__img">
                                                        <img src={imgpath} alt=""></img>
                                                    </div>
                                                    <div class="why-choose-four__dot">
                                                        <img src="/assets/images/why-choose-three-dot.png" alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix p-3"></div>
                                    </>

                                )
                            }


                        })
                    }

                    <div class="row aboutus-member-card-wrap">
                        {
                            (fetchTeamsData != null && fetchTeamsData != undefined && fetchTeamsData.length > 0) &&
                            fetchTeamsData.filter(x => x.MemberType == "TeamMember").map((data, key) => {
                                let imgpath = IMAGE_ROOT + resources.OURTEAM_IMG_URL + data.Image;
                                return (

                                    <div class="col-xl-3 col-lg-3 col-md-6 aboutus-member-card wow fadeInUp" data-wow-delay="100ms">
                                        <div class="team-one__single">
                                            <div class="team-one__img">
                                                <img src={imgpath} alt=""></img>
                                                <div class="team-one__content">
                                                    <p class="team-one__sub-title">{data.Designation}</p>
                                                    <h3 class="team-one__title"><a href="team.html">{data.Name}</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </section>


            {/* <section className="wrapper ourWork">

                <h2 style={{ color: "#e34040" }}>Our Team</h2>

                <ul>
                    {
                        (fetchTeamsData != null && fetchTeamsData != undefined && fetchTeamsData.length > 0) &&
                        fetchTeamsData.map((data, key) => {
                            let imgpath = IMAGE_ROOT + "Images/ourTeam/" + data.Image;
                            return (
                                <li key={key}><img src={imgpath} />
                                    <h4 style={{ paddingTop: '15px', textAlign: 'center', color: '#333' }}>{data.Name}</h4>
                                    <h5 style={{ textAlign: 'center', color: '#505050' }}>{data.Designation}</h5>
                                </li>
                            )

                        })

                    }
                </ul>

            </section> */}

        </>
    )
};
export default AboutUsComponet;