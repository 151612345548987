import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { fetchHeader } from '../../redux/actions/HeaderActions'
import { Link } from 'react-router-dom';
import Whatsapp from './whatsapp';
import { groupBy } from 'lodash';
import ClientComponet from '../../components/home/ClientComponent';
import { fetchHomePageData } from '../../redux/actions/homeActions';


const Footer = (
    {
        fetchHeader,
        fetchFooterPageData,
        footerAboutUsData,fetchHomePageData
    }) => {
    const [ActiveMenu, setActiveMenu] = useState('');
    const ClientData = useSelector((state) => state.allHomes.homeData != null ? state.allHomes.homeData.ClientForWeb : null);

    const ScrollToHospitality = (categoryName) => {
        setTimeout(() => {
            if (categoryName == "Hospitality" && window.location.pathname == '/')
                document.getElementById("hospital-category").scrollIntoView();
        }, 500);
        sessionStorage.setItem('activeMenu', categoryName);
        document.querySelectorAll('.headerMain > section ul li').forEach(function (e) {
            e.children[0].classList.remove('active');
            if (e.children[0].innerText == categoryName.trim()) {
                e.children[0].classList.add('active');
            }
        })
    };

    useEffect(() => {
        setActiveMenu('');
        fetchHeader();
        fetchHomePageData();
    }, [])

    return (
        <>

            {
                ClientData != null ? <ClientComponet ClientData={ClientData} /> : ''
            }

            <footer className="footerMain">
                <section className="wrapper">
                    <Whatsapp />
                    <div className="row">
                        <div className="col footerAboutUs">
                            <img src="/assets/images/logo.png" />
                            <p dangerouslySetInnerHTML={{
                                                        __html: footerAboutUsData
                                                    }}></p>
                        </div>


                        <div className="col">
                            <h5>Shop</h5>
                            <ul>
                                {(() => {
                                    if (fetchFooterPageData != null && fetchFooterPageData != undefined && fetchFooterPageData.length > 0) {
                                        const CategoryTypeWiseData = groupBy(fetchFooterPageData, function (n) {
                                            return n.CategoryTypeName;
                                        });
                                        return (
                                            Object.entries(CategoryTypeWiseData).map(([key, data]) => {

                                                return (
                                                    // <li><Link to={{
                                                    //     pathname: '/Categories',
                                                    //     state: {
                                                    //         CategoryTypeID: data[0].CategoryTypeID
                                                    //     }
                                                    // }} name='parent'>{key}</Link></li>
                                                    <li key={Math.random()}>
                                                        <Link
                                                            to={key == "Hospitality" && window.location.pathname == '/' ? '' : data.length == 1 && key == data[0].CategoryName ? { pathname: '/products', state: { CategoryID: data[0].CategoryID } } : ''}
                                                            onClick={() => { ScrollToHospitality(key) }}
                                                            name='parent'>{key}</Link>
                                                    </li>
                                                )
                                            })
                                        )
                                    }
                                })()}
                            </ul>
                        </div>


                        <div className="col">
                            <h5>Useful links</h5>
                            <ul>
                                <li>
                                    <Link to="/privacypolicy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/returnpolicy">Return Policy</Link>
                                </li>
                                <li>
                                    <Link to="/termscondition">Terms & Condition</Link>
                                </li>
                                
                            </ul>
                        </div>


                        <div className="col">
                            <h5>Quick links</h5>
                            <ul>
                                <li>
                                    <Link to="/contactus">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/help-support">Help / Support</Link>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="copyright">

                        <div>Copyright ©{(new Date().getFullYear())} Unimaple.</div>

                        <div className="socialIcon">
                            <a href="https://www.facebook.com/unimaple" target="_blank"><i className="fa fa-facebook"></i></a>
                            <a href="https://twitter.com/unimaple_1" target="_blank"><i className="fa fa-twitter"></i></a>
                            <a href="https://www.instagram.com/unimaple/" target="_blank" ><i className="fa fa-instagram"></i></a>
                            <a href="https://in.pinterest.com/umodutech/" target="_blank" ><i className="fa fa-pinterest-p"></i></a>
                            <a href="https://www.youtube.com/channel/UCFme7nSg2-r-VdvHSzJMIFQ" target="_blank" ><i className="fa fa-youtube-play"></i></a>
                        </div>

                    </div>

                </section>
            </footer>
        </>

    )


}
export default connect((state, props) => (
    {
        fetchFooterPageData: state.allHeader != null ? state.allHeader.Header : null,
        footerAboutUsData: state.allHomes.homeData != null ? state.allHomes.homeData.CMSForFooterAboutUs.Content : null,
        //fetchHeaderPageData: state.allHeader.payload != null ? state.allHeader.payload : null,
    }),
    { fetchHeader,fetchHomePageData }
)(Footer)
