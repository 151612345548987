import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import RegisterComponet from '../register/registerComponent';
import ForgotPasswordComponet from '../forgotpassword/forgotPasswordComponent';
import HandleFloatingInputBlur from '../Shared/custom';
import { useDispatch, connect } from "react-redux";
import { Login, UserValidateAccount, LoginCredentials } from '../../redux/actions/loginAction'
import { ActionTypes } from '../../redux/constants/action-types';
const LoginComponent = (props) => {
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(false);
    const [isLoginClick, setIsLoginClick] = useState(true);
    const [openRegister, setOpenRegister] = useState(false);
    const [openForgotPassword, setOpenForgotPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [OTP, setOTP] = useState("");
    const [UserNameError, setUserNameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [OTPError, setOTPError] = useState("");
    const [isLoginWithPassword, setIsLoginWithPassword] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();


    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const onChangeOTP = (e) => {
        const OTP = e.target.value;
        setOTP(OTP);
    };
    const handleUserValidation = () => {
        let Error;
        let formIsValid = true;
        setLoading(true);
        if (!username) {
            Error = "Please enter username / email"
            formIsValid = false;
            setLoading(false);
        }
        setUserNameError(Error);
        return formIsValid;
    }
    const handleOTPValidation = () => {
        let Error;
        let formIsValid = true;
        setLoading(true);
        if (!OTP) {
            Error = "Please enter OTP"
            formIsValid = false;
            setLoading(false);
        }
        setOTPError(Error);
        return formIsValid;

    }
    const handlePasswordValidation = () => {
        let Error;
        let formIsValid = true;
        setLoading(true);
        if (!password) {
            Error = "Please enter password"
            formIsValid = false;
            setLoading(false);
        }
        setPasswordError(Error);
        return formIsValid;

    }

    // Timer start
    useEffect(() => {
        let timerId;

        if (runTimer) {
            setCountDown(60 * 2);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer]);
    useEffect(() => {
        if (countDown < 0 && runTimer) {
            console.log("expired");
            document.getElementById("TimerCounter").style.display = "none"
            document.getElementById("Resendotp").style.display = "block"
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);
    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);
    // Timer end

    const handleLogin = (e) => {
        e.preventDefault();
        if (handleUserValidation() && isLoginClick === true && isLoginWithPassword === false) {
            setLoading(true);
            dispatch(UserValidateAccount(username))
                .then((res) => {
                    // setUsername()
                    setLoading(false);
                })
        } else if (isLoginWithPassword === false && handleOTPValidation() && isLoginClick === false) {
            setLoading(true);
            dispatch(Login(username, OTP))
                .then((res) => {
                    // setUsername();
                    // setOTP();
                    setLoading(false);
                })
        }
        else if (isLoginWithPassword === true && handlePasswordValidation() && handleUserValidation()) {
            setLoading(true);
            dispatch(LoginCredentials(username, password))
                .then((res) => {
                    // setUsername();
                    // setPassword();
                    setLoading(false);
                })
        }

    };
    useEffect(() => {
        if (props.isOTPSend == true) {
            setRunTimer((t) => !t);
            setLoading(false);
            setIsLoginClick(false);
            document.getElementById("TimerCounter").style.display = "block"
            document.getElementById("Resendotp").style.display = "none"
        }
    }, [props.isOTPSend]);
    const onResendOtpClick = () => {
        dispatch(UserValidateAccount(username))
        // UserValidateAccount(username);
        setRunTimer((t) => !t);
        setLoading(false);
        setIsLoginClick(false);
        document.getElementById("TimerCounter").style.display = "block";
        document.getElementById("Resendotp").style.display = "none";
    }
    if (props.isLoggedIn) {
        let accessModel = document.getElementById("loginModel");
        if (accessModel != null)
            accessModel.classList.add('modal-close');
        setTimeout(() => {
            props.setTrigger(false)
        }, 400);
        // props.setTrigger(false);

    }
    useEffect(() => {
        if (props.isLoggedIn) {
            setUsername("");
            setOTP("");
            setUserNameError("");
            setPasswordError("");
            setOTPError("");
            setIsLoginClick(true);
            setCountDown(0);
            setRunTimer(false);
            setIsLoginWithPassword(false);
            setPassword();
            let body = document.getElementsByTagName("body")[0];
            body.classList.add("overflowauto");
            // dispatch({ type: ActionTypes.USER_LOGIN_STATE_RESET });
        }

    }, [props.isLoggedIn]);
    const closeModal = () => {
        setUsername("");
        setOTP("");
        setUserNameError("");
        setPasswordError("");
        setOTPError("");
        setIsLoginClick(true);
        setCountDown(0);
        setRunTimer(false);
        setIsLoginWithPassword(false);
        dispatch({ type: ActionTypes.USER_LOGIN_STATE_RESET });

        let accessModel = document.getElementById("loginModel");
        if (accessModel != null)
            accessModel.classList.add('modal-close');
        setTimeout(() => {
            props.setTrigger(false)
        }, 400);

        let body = document.getElementsByTagName("body")[0];
        body.classList.add("overflowauto");
        body.classList.remove('overflowhidden');
    }
    const onLoginWithPasswordClick = () => {
        setIsLoginWithPassword(true);
        dispatch({ type: ActionTypes.USER_LOGIN_STATE_RESET });
    }
    const onLoginWithOTPClick = () => {
        setIsLoginWithPassword(false);
        setIsLoginClick(true);
        setCountDown(0);
        setRunTimer(false);
    }
    useEffect(() => {
        setTimeout(() => {
            if (document.getElementById('ErrorMessage') != null)
                document.getElementById('ErrorMessage').style.display = 'none'
        }, 3000);
    }, [props.errMsg]);
    useEffect(() => {
        setTimeout(() => {
            if (document.getElementById('OtpsendSuccesMsg') != null)
                document.getElementById('OtpsendSuccesMsg').style.display = 'none'
        }, 3000);
    }, [props.isOTPSend]);
    useEffect(() => {
        setTimeout(() => {
            if (document.getElementById('otpExpiredOrInvalid') != null)
                document.getElementById('otpExpiredOrInvalid').style.display = 'none'
        }, 3000);
    }, [props.otpExpiredOrInvalid]);

    return (
        <>
            {(() => {
                if (props.trigger) {
                    return (
                        <>
                            <div className="overlay" id="accessModelOverlay" style={{ display: 'block' }}></div>
                            <div className="model userAccessModel loginmodal" id="loginModel" style={{ display: 'block' }}>
                                <div className="modelWrapper" style={{ maxWidth: '800px' }}>

                                    <div className="accessImage">
                                        {/* <img src="/assets/images/model_chair.png" /> */}
                                    </div>
                                    <form onSubmit={handleLogin}>
                                        <div className="accessForm">
                                            <a href="javascript:void(0)" className="close" onClick={() => { closeModal(); }}></a>
                                            <div id="loginFrom" style={{ display: 'block' }}>
                                                <h3>Login</h3>
                                                <p>Login to continue access page</p>

                                                <div className="alert alert-error" style={{ display: 'none' }}>
                                                    <img src="/assets/images/success-tik.svg" />
                                                    {/* <strong>Success!</strong> */}
                                                    Message has been sent.
                                                    <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                </div>
                                                {props.errMsg && (
                                                    <div className="alert alert-error" style={{ display: 'flex' }} id='ErrorMessage'>
                                                        <img src="/assets/images/error_x.svg" />
                                                        {/* <strong>Error!</strong> */}
                                                        {props.errMsg}
                                                        <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                    </div>
                                                )}
                                                {props.otpExpiredOrInvalid && (
                                                    <div className="alert alert-error" style={{ display: 'flex' }} id='otpExpiredOrInvalid'>
                                                        <img src="/assets/images/error_x.svg" />
                                                        {/* <strong>Error!</strong> */}
                                                        {props.otpExpiredOrInvalid}
                                                        <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                    </div>
                                                )}
                                                {/* {props.isOTPSend && ( */}
                                                <div className="alert alert-success" style={{ display: props.isOTPSend == false ? 'none' : 'flex' }} id="OtpsendSuccesMsg">
                                                    <img src="/assets/images/success-tik.svg" />
                                                    {/* <strong>Success!</strong> */}
                                                    OTP has been successfully sent
                                                    <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                                </div>
                                                {/* )} */}



                                                <div className="fromRow">
                                                    <div className="floatingLabelInput hasIcon">
                                                        <input type="text" className="input" id='txtEmail' name='email' onBlur={HandleFloatingInputBlur.bind(this)} value={username}
                                                            onChange={onChangeUsername} />
                                                        <label>Email (Username)</label>
                                                        <i className="ico email"></i>
                                                    </div>
                                                    <div className="validation"><span style={{ display: 'block' }}>{UserNameError}</span></div>
                                                </div>

                                                <div className="fromRow" style={{ display: props.isOTPSend == false ? 'none' : 'block' }} >
                                                    <div style={{ width: '50%', float: 'right', padding: '10px', display: 'block', fontSize: '13px', marginTop: '13px' }} id="TimerCounter">Resend Otp in {minutes}:{seconds}</div>
                                                    <div style={{ width: '50%', float: 'right', padding: '10px', display: 'none', fontSize: '13px', marginTop: '13px' }} id="Resendotp"><a onClick={() => { onResendOtpClick() }}>Resend OTP</a></div>

                                                    <div className="floatingLabelInput hasIcon" style={{ width: '50%' }}>
                                                        <input type="number" className="input" name='otp' onBlur={HandleFloatingInputBlur.bind(this)} value={OTP} onChange={onChangeOTP} />
                                                        <label>OTP</label>
                                                        <i className="ico password"></i>
                                                    </div>
                                                    <div className="validation"><span style={{ display: 'block' }}>{OTPError}</span></div>
                                                </div>
                                                <div className="fromRow" style={{ display: isLoginWithPassword === false ? 'none' : 'block' }}>
                                                    <div className="floatingLabelInput hasIcon">
                                                        <input type="password" className="input" onBlur={HandleFloatingInputBlur.bind(this)} value={password} onChange={onChangePassword} />
                                                        <label>Password</label>
                                                        <i className="ico password"></i>
                                                    </div>
                                                    <div className="validation"><span style={{ display: 'block' }}>{passwordError}</span></div>
                                                </div>

                                                <div className="formButton flex ForgotPassword">
                                                    <div >
                                                        <Link onClick={() => {

                                                            let body = document.getElementsByTagName("body")[0];
                                                            body.classList.add("overflowhidden");
                                                            setOpenForgotPassword(true);
                                                            setOpenRegister(false);
                                                            props.setTrigger(false);
                                                        }}>Forgot Password?</Link>
                                                    </div>

                                                    <div className="submitBtn">
                                                        <div className="btnLoader" style={{ display: loading ? 'block' : 'none' }}>
                                                            <span className="spinLoader"></span>
                                                        </div>
                                                        <button value='Login' className='btn'>Login</button>
                                                    </div>
                                                </div>
                                                <div className="note">
                                                    <a href="javascript:void(0)" onClick={() => { onLoginWithPasswordClick() }} style={{ display: isLoginWithPassword === true ? 'none' : 'block' }}>Login with password</a><br />
                                                    <a href="javascript:void(0)" onClick={() => { onLoginWithOTPClick() }} style={{ display: isLoginWithPassword === false ? 'none' : 'block' }}>Login with otp</a>
                                                    Don’t have an account? <a href="javascript:void(0)" onClick={() => {
                                                        
                                                        let body = document.getElementsByTagName("body")[0];
                                                        body.classList.add("overflowhidden");
                                                        setOpenRegister(true);
                                                        props.setTrigger(false);
                                                        setOpenForgotPassword(false)
                                                    }}>Register Now</a>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    )
                } else if (openRegister === true) {
                    return (
                        <RegisterComponet openRegister={openRegister} setOpenRegister={setOpenRegister} openLoginModal={props.setTrigger}></RegisterComponet>
                    )
                } else if (openForgotPassword === true) {
                    return (
                        <ForgotPasswordComponet openForgotPassword={openForgotPassword} setOpenForgotPassword={setOpenForgotPassword} openLoginModal={props.setTrigger}></ForgotPasswordComponet>
                    )
                }
            })()}
        </>
    )

};

export default connect((state, props) => (
    {
        errMsg: state.userValidate.message != null ? state.userValidate.message : null,
        isOTPSend: state.userValidate.isOTPSend,
        otpExpiredOrInvalid: state.Login.message != null ? state.Login.message : null,
        isLoggedIn: state.Login.isLoggedIn

    }),
    { UserValidateAccount, Login, LoginCredentials }
)(LoginComponent)
// export default LoginComponent;