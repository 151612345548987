export const fileIcons = {
    icons:[
        {
            "name": "pdf",
            "imagePath": "/assets/images/icons/pdf-icon.png"
        },
        {
            "name": "mp4",
            "imagePath": "/assets/images/icons/video-icon.png"
        },
        {
            "name": "mov",
            "imagePath": "/assets/images/icons/video-icon.png"
        },
        {
            "name": "wmv",
            "imagePath": "/assets/images/icons/video-icon.png"
        },
        {
            "name": "flv",
            "imagePath": "/assets/images/icons/video-icon.png"
        },
        {
            "name": "doc",
            "imagePath": "/assets/images/icons/doc-icon.png"
        },
        {
            "name": "docx",
            "imagePath": "/assets/images/icons/doc-icon.png"
        },
        {
            "name": "jpeg",
            "imagePath": "/assets/images/icons/image-icon.png"
        },
        {
            "name": "png",
            "imagePath": "/assets/images/icons/image-icon.png"
        },
        {
            "name": "jpg",
            "imagePath": "/assets/images/icons/image-icon.png"
        },
        {
            "name": "xlsx",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xls",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xlsm",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xlsb",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xltx",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xltm",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xlt",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xml",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xlam",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xla",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xlw",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "xlr",
            "imagePath": "/assets/images/icons/xls-icon.png"
        },
        {
            "name": "zip",
            "imagePath": "/assets/images/icons/zip-icon.png"
        },

        {
            "name": "rar",
            "imagePath": "/assets/images/icons/rar-icon.png"
        },
    ]
}
