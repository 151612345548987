import React, { useEffect } from 'react';
import { IMAGE_ROOT } from '../../api-config';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { resources } from '../../resource';

const TestimonialComponent = ({ TestimonialsData }) => {


    useEffect(() => {
        if (TestimonialsData == null)
            return;
        var testim = document.getElementById("testim"),
            testimDots = Array.prototype.slice.call(document.getElementById("testim-dots").children),
            testimContent = Array.prototype.slice.call(document.getElementById("testim-content").children),
            testimLeftArrow = document.getElementById("left-arrow"),
            testimRightArrow = document.getElementById("right-arrow"),
            testimSpeed = 4500,
            currentSlide = 0,
            currentActive = 0,
            testimTimer,
            touchStartPos,
            touchEndPos,
            touchPosDiff,
            ignoreTouch = 30;
        // Testim Script
        function playSlide(slide) {
            for (var k = 0; k < testimDots.length; k++) {
                testimContent[k].classList.remove("active");
                testimContent[k].classList.remove("inactive");
                testimDots[k].classList.remove("active");
            }

            if (slide < 0) {
                slide = currentSlide = testimContent.length - 1;
            }

            if (slide > testimContent.length - 1) {
                slide = currentSlide = 0;
            }

            if (currentActive != currentSlide) {
                testimContent[currentActive].classList.add("inactive");
            }
            if (testimContent.length > 0) {
                testimContent[slide].classList.add("active");
                testimDots[slide].classList.add("active");
            }
            currentActive = currentSlide;

            clearTimeout(testimTimer);
            testimTimer = setTimeout(function () {
                playSlide(currentSlide += 1);
            }, testimSpeed)
        }

        testimLeftArrow.addEventListener("click", function () {
            playSlide(currentSlide -= 1);
        })

        testimRightArrow.addEventListener("click", function () {
            playSlide(currentSlide += 1);
        })

        for (var l = 0; l < testimDots.length; l++) {
            testimDots[l].addEventListener("click", function () {
                playSlide(currentSlide = testimDots.indexOf(this));
            })
        }

        playSlide(currentSlide);

        // keyboard shortcuts
        document.addEventListener("keyup", function (e) {
            switch (e.keyCode) {
                case 37:
                    testimLeftArrow.click();
                    break;

                case 39:
                    testimRightArrow.click();
                    break;

                case 39:
                    testimRightArrow.click();
                    break;

                default:
                    break;
            }
        })

        testim.addEventListener("touchstart", function (e) {
            touchStartPos = e.changedTouches[0].clientX;
        })

        testim.addEventListener("touchend", function (e) {
            touchEndPos = e.changedTouches[0].clientX;

            touchPosDiff = touchStartPos - touchEndPos;

            console.log(touchPosDiff);
            console.log(touchStartPos);
            console.log(touchEndPos);


            if (touchPosDiff > 0 + ignoreTouch) {
                testimLeftArrow.click();
            } else if (touchPosDiff < 0 - ignoreTouch) {
                testimRightArrow.click();
            } else {
                return;
            }

        })
    }, [TestimonialsData]);


    return (
        <>
            <section class="testimonials-wrap" data-aos="fade-up" data-aos-delay="100">
                <div class="wrapper">
                    <div class="testimonials-head-wrap">
                        <h2 class="testimonials-head">Client Testimonials</h2>
                    </div>
                    <div id="testim" class="testim">
                        <div class="wrap">
                            <span id="right-arrow" class="arrow right fa fa-chevron-right"></span>
                            <span id="left-arrow" class="arrow left fa fa-chevron-left "></span>
                            <ul id="testim-dots" class="dots">
                                {
                                    (TestimonialsData != null && TestimonialsData.length > 0) &&
                                    TestimonialsData.map((data, key) => {
                                        return (
                                            <li class={key == 0 ? 'dot active' : 'dot'}></li>
                                        )
                                    })
                                }
                            </ul>
                            <div id="testim-content" class="cont">
                                {
                                    (TestimonialsData != null && TestimonialsData.length > 0) &&
                                    TestimonialsData.map((data, key) => {
                                        var imgpath = IMAGE_ROOT + resources.USER_IMG_URL + data.ProfileImage;
                                        return (
                                            <div className={key == 0 ? 'active' : ''}>
                                                <div class="img">
                                                    <img src={imgpath} draggable='false' onError={(e) => (e.target.onerror = null, e.target.src = "/assets/images/user-default-image.jpg")} />
                                                </div>
                                                <h2>{data.FullName}</h2>
                                                <div className="star-ratting">
                                                    {
                                                        [...Array(5)].map((ignore, ind) => {
                                                            return (

                                                                <i
                                                                    className={
                                                                        data.Ratings >= ind + 1
                                                                            ? 'fas fa-star'
                                                                            : data.Ratings >= ind + 0.5
                                                                                ? 'fas fa-star-half-alt'
                                                                                : 'far fa-star'
                                                                    }
                                                                ></i>

                                                            )
                                                        })
                                                    }
                                                </div>
                                                <p class="testim-title" >{data.ReviewTitle}</p>
                                                <p title={data.Description}>{data.Description}</p>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="testimonialsSlider">
                <section className="wrapper">

                    <h4>Testimonials</h4>

                    <Slider {...settings}>
                        {
                            (TestimonialsData != null && TestimonialsData.length > 0) &&
                            TestimonialsData.map((data, key) => {
                                var imgpath = IMAGE_ROOT + "Images/Users/" + data.ProfileImage;
                                return (
                                    <div key={key}>
                                        <div className="starRatting">
                                            {
                                                [...Array(5)].map((ignore, ind) => {
                                                    return (

                                                        <i
                                                            className={
                                                                data.Ratings >= ind + 1
                                                                    ? 'fas fa-star'
                                                                    : data.Ratings >= ind + 0.5
                                                                        ? 'fas fa-star-half-alt'
                                                                        : 'far fa-star'
                                                            }
                                                        ></i>

                                                    )
                                                })
                                            }
                                        </div>
                                        <h5>{data.ReviewTitle}</h5>
                                        <p>{data.Description}</p>
                                        <div className="client">
                                            <div className="avtar">
                                                <img src={imgpath} className="clientSliderWidth" />
                                            </div>
                                            <div className="info">
                                                <label>{data.FullName}</label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>

                </section>
            </section> */}
        </>
    )

};
export default TestimonialComponent;