import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { GetByQuotationRequestID, RequestForDocument, ResetDocumentRequestState } from '../../redux/actions/documentRequestAction';

const DocumentRequestComponent = (
    {
        loader,isDocumentRequestToggle, setIsDocumentRequestToggle, isDocumentRequestSuccess, RequestForDocument,quotationRequestID,userID,GetByQuotationRequestID,ResetDocumentRequestState
    }) => {
    const [values, setValues] = useState({
        Message:''
    });
    const [errors, setErrors] = useState({});
    const handleSubmit = e => {
        e.preventDefault();
        // SendOTPForRequestQuotation(values.phone)
        if (DocumentRequestValidateInfo(values)) {
            RequestForDocument(quotationRequestID,values.Message,userID)
        }
    }
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };
    const DocumentRequestValidateInfo = (values) => {

        let errors = {};
        let formIsValid = true;
        if (!values.Message.trim()) {
        errors.Message = 'Document request message.';       
            formIsValid = false
        }
        setErrors(errors);
        return formIsValid;
    }
    useEffect(() => {
        if(isDocumentRequestSuccess === true){
            setIsDocumentRequestToggle(false);
            setValues({
                Message:''
            });
            ResetDocumentRequestState();
            GetByQuotationRequestID(quotationRequestID,userID)
        }
    }, [isDocumentRequestSuccess]);
    return (
        <>
            {(() => {
                if (isDocumentRequestToggle === true) {
                    return (
                        <form className='form' onSubmit={handleSubmit}>
                            <div class="overlay" id="accessModelOverlay" style={{ display: 'block' }}></div>
                            <div class="model userAccessModel" id="accessModel" style={{ display: 'block' }}>
                                <div class="modelWrapper" style={{ gridTemplateColumns: '100%' }}>

                                    {/* <div class="accessImage changeBackground">
                                        <img src="../assets/images/model_chair.png" />
                                    </div> */}

                                    <div class="accessForm">
                                        <a href="javascript:void(0)" className="close" onClick={() => { setIsDocumentRequestToggle(false) }}></a>
                                        <div id="registerFrom" style={{ display: 'block' }}>
                                            <h3>Document Request</h3>
                                            <p>Enter your detail to document request.</p>
                                            <div class="fromRow">
                                                <div class="floatingLabelInput hasIcon">
                                                    <textarea class="input" placeholder="Enter Message" rows="5" cols="100" name='Message' value={values.Message} onChange={handleChange}></textarea>
                                                    <label>Document Message</label>
                                                </div>
                                                <div className="validation">
                                                        {
                                                            errors.Message && <span style={{ display: 'block' }}>{errors.Message}</span>
                                                        }
                                                    </div>
                                            </div>

                                            <div class="formButton">
                                                <div class="submitBtn">
                                                    <div class="btnLoader" style={{ display: loader ? 'block':'none' }}>
                                                        <span class="spinLoader"></span>
                                                    </div>
                                                    {/* <input value="Register" class="btn" type="button" /> */}
                                                    <button value="submit" id='sunmit' class='btn' type="submit">Submit</button>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }
            })()}
        </>
        //    <AboutUsComponet fetchAbountPageData={fetchAbountPageData} fetchTeamsData={fetchTeamsData}/>
    )
}
export default connect((state, props) => (
    {
        isDocumentRequestSuccess: state.documentRequest.isDocumentRequestSuccess,
        loader : state.documentRequest.loading
    }),
    { RequestForDocument,GetByQuotationRequestID,ResetDocumentRequestState }
)(DocumentRequestComponent)
