import React from 'react';
import { IMAGE_ROOT } from '../../api-config';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { resources } from '../../resource';

const CategoryComponet = ({ Categorydata, loader }) => {


	return (
		<div>
			<section className="separator-wrap">
				<section className="wrapper separator">
					<div className="separator-img-wrap">
					</div>
					<div className="separator-desc">
						<p className="separator-text">Lets make your home beautiful of customized Furniture</p>
						<Link className="hospitality-btn separator-btn" to={'/products'} >Buy Now</Link>
					</div>
				</section>
			</section>
			<section className="hospitality-wrap" id="hospital-category">
				<div className="hospitality wrapper">
					<div className="hospitality-head-wrap">
						<div>
							<label>Furniture Categories</label>
							<h2 className="hospitality-head">Hospitality</h2>
						</div>
					</div>
					<ul className="hospitality-cards-wrap">
						{(() => {
							if (Categorydata != null && Categorydata.length > 0 && loader != true) {
								return (
									Object.entries(Categorydata).map(([key, data]) => {
										var imgpath = IMAGE_ROOT + resources.CATEGORIES_IMG_URL + data.CategoryImage;
										return (
											<>
												<li className='hospitality-card-item'>
													<Link to={{
														pathname: '/products',
														state: {
															CategoryID: data.CategoryID
														}
													}} title={data.CategoryName}>
														<div class="hospitality-card" data-aos="fade-up">
															<div className="hospitality-card-img">
																<img
																	sizes="(max-width: 320px) 290px,(max-width: 425px) 410px,(max-width: 768px) 340px,(max-width: 1024px) 290px,(max-width: 1440px) 410px"
																	srcset={`${IMAGE_ROOT}${resources.CATEGORIES_IMG_URL}${resources.IMAGEVERSIONS.THUMBNAILIMAGERESIZER + data.CategoryImage} 200w,
                                                                                                ${IMAGE_ROOT}${resources.CATEGORIES_IMG_URL}${resources.IMAGEVERSIONS.SMALLIMAGERESIZER + data.CategoryImage} 290w,
                                                                                                ${IMAGE_ROOT}${resources.CATEGORIES_IMG_URL}${resources.IMAGEVERSIONS.MEDIUMIMAGERESIZER + data.CategoryImage} 340w,
                                                                                                ${IMAGE_ROOT}${resources.CATEGORIES_IMG_URL}${resources.IMAGEVERSIONS.LARGEIMAGERESIZER + data.CategoryImage} 410w`}
																	src={`${IMAGE_ROOT}${resources.CATEGORIES_IMG_URL}${data.CategoryImage}`}
																	alt="Hospitality Image" />
																	{/* <img src={imgpath} alt="Hospitality Image" /> */}
															</div>
															<div className="hospitality-card-desc">
																<h2 className="hospitality-card-head">{data.CategoryName}</h2>
																<Link to={{
																	pathname: '/products',
																	state: {
																		CategoryID: data.CategoryID
																	}
																}} className="hospitality-btn">Shop now<span></span></Link>
															</div>
														</div>
													</Link>
												</li>
											</>
										)
									})
								)
							}
						})()}
					</ul>
				</div>
			</section>

			{/* <section className="wrapper featuredCategory">
				<div className="category" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,60%), rgba(0,0,0,60%)),url(${ImagePathCategory1})` }}>
					<h4 className="h4-categoryName">{CategoryName1}</h4>
					<div>
						<Link to={{
							pathname: '/products',
							state: {
								CategoryID: CategoryId1
							}
						}}>View All</Link>
					</div>
				</div>
				<>

					<div className="categoryProducts">
						<ul className="productList">
							{(() => {
								if (Categorydata != null && Categorydata.length > 0 && loader != true) {

									console.log(Category1);
									return (
										Object.entries(ProductsByCategory1).map(([key, data]) => {
											return (
												<>
													<li key={Math.random()} id={key} style={{ paddingBottom: '20px' }}>
														<div className="thumb">
															<Link to={`/products/${data[0].Slug}`} title={data[0].ProductName}>
																{
																	Object.entries(data).map(([key, value]) => {
																		var imgpath = IMAGE_ROOT + "Images/Products/" + value.ProductImage;
																		return (
																			<>
																				<img src={imgpath} data-product={`${(value.ProductID) + (key)}`} style={{ display: key == 0 ? "inline" : "none" }}></img>
																			</>
																		)
																	})
																}
															</Link>
														</div>
														<div className="colorVariation">
															{
																Object.entries(data).map(([key, productImages]) => {
																	return (
																		<>
																			<a onClick={colorVariationClick} style={{ backgroundColor: productImages.Color }} data-image={productImages.ProductID + key} data-liid={productImages.ProductID} className={key == 0 ? "active" : ""}  ></a>
																		</>
																	)
																})
															}
														</div>
														<a title={data[0].ProductName} className='productIntro'>
															<label title={data[0].ProductName}>{data[0].ProductName}</label>
														</a>
													</li>
												</>
											)
										})
									)
								} else {
									return (
										[...Array(4)].map((ignore, index) => {
											return (

												<li key={index}>
													<Skeleton height={295} width={329}></Skeleton>
													<div style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '5px' }}>
														<Skeleton style={{ display: 'block', width: '20px', height: '20px', border: '2px solid #fff', borderRadius: '100%', boxShadow: '0.8px 3px 4px 0px rgb(0 0 0 / 36%)' }} >
														</Skeleton>
													</div>
													<a href="#0" className="productIntro">
														<Skeleton style={{ width: "70%" }} ></Skeleton>
													</a>
												</li>

											)
										})
									)
								}
							})()}
						</ul>

					</div>

				</>

			</section>
			<section className="wrapper featuredCategory switch">
				<div className="category" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,60%), rgba(0,0,0,60%)),url(${ImagePathCategory2})` }}>
					<h4 className="h4-categoryName">{CategoryName2}</h4>
					<div>
						<Link to={{
							pathname: '/products',
							state: {
								CategoryID: CategoryId2
							}
						}}>View All</Link>
					</div>
				</div>
				<>
					<div className="categoryProducts">
						<ul className="productList">
							{(() => {
								if (Categorydata != null && Categorydata.length > 0 && loader != true) {
									return (
										Object.entries(ProductsByCategory2).map(([key, data]) => {
											return (
												<>
													<li key={Math.random()} id={key} style={{ paddingBottom: '20px' }}>
														<div className="thumb">
															<Link to={`/products/${data[0].Slug}`} title={data[0].ProductName}>
																{
																	Object.entries(data).map(([key, value]) => {
																		var imgpath = IMAGE_ROOT + "Images/Products/" + value.ProductImage;
																		return (
																			<>


																				<img src={imgpath} data-product={`${(value.ProductID) + (key)}`} style={{ display: key == 0 ? "inline" : "none" }}></img>

																			</>
																		)
																	})
																}
															</Link>
														</div>
														<div className="colorVariation">
															{
																Object.entries(data).map(([key, productImages]) => {
																	return (
																		<>
																			<a onClick={colorVariationClick} style={{ backgroundColor: productImages.Color }} data-image={productImages.ProductID + key} data-liid={productImages.ProductID} className={key == 0 ? "active" : ""}  ></a>
																		</>
																	)
																})
															}
														</div>
														<a title={data[0].ProductName} className='productIntro'>
															<label title={data[0].ProductName}>{data[0].ProductName}</label>
														</a>
													</li>
												</>
											)
										})
									)
								}
								else {
									return (
										[...Array(4)].map((ignore, index) => {
											return (

												<li key={index}>

													<a href="#" style={{ width: '100%' }}>
														<img src='/assets/images/product_listing_skeleton.svg' ></img>
													</a>
												</li>

											)
										})
									)
								}
							})()}
						</ul>

					</div>

				</>

			</section> */}
		</div>
	);
};
export default CategoryComponet;