export const ShadeCard = {
    shades:[
        {
            "TextureName": "ULA1001",
            "Link": "https://engrossinfotech.com/shadecard/1.jpg"
        },
        {
            "TextureName": "ULA1002",
            "Link": "https://engrossinfotech.com/shadecard/2.jpg"
        },
        {
            "TextureName": "ULA1003",
            "Link": "https://engrossinfotech.com/shadecard/3.jpg"
        },
        {
            "TextureName": "ULA1004",
            "Link": "https://engrossinfotech.com/shadecard/4.jpg"
        },
        {
            "TextureName": "ULA1005",
            "Link": "https://engrossinfotech.com/shadecard/5.jpg"
        },
        {
            "TextureName": "ULA1006",
            "Link": "https://engrossinfotech.com/shadecard/6.jpg"
        },
        {
            "TextureName": "ULA1007",
            "Link": "https://engrossinfotech.com/shadecard/7.jpg"
        },
        {
            "TextureName": "ULA1008",
            "Link": "https://engrossinfotech.com/shadecard/8.jpg"
        },
        {
            "TextureName": "ULA1009",
            "Link": "https://engrossinfotech.com/shadecard/9.jpg"
        },
        {
            "TextureName": "ULA1010",
            "Link": "https://engrossinfotech.com/shadecard/10.jpg"
        },
        {
            "TextureName": "ULA1011",
            "Link": "https://engrossinfotech.com/shadecard/11.jpg"
        },
        {
            "TextureName": "ULA1012",
            "Link": "https://engrossinfotech.com/shadecard/12.jpg"
        },
        {
            "TextureName": "ULA1013",
            "Link": "https://engrossinfotech.com/shadecard/13.jpg"
        },
        {
            "TextureName": "ULA1014",
            "Link": "https://engrossinfotech.com/shadecard/14.jpg"
        },
        {
            "TextureName": "ULA1015",
            "Link": "https://engrossinfotech.com/shadecard/15.jpg"
        },
        {
            "TextureName": "ULA1016",
            "Link": "https://engrossinfotech.com/shadecard/16.jpg"
        },
        {
            "TextureName": "ULA1017",
            "Link": "https://engrossinfotech.com/shadecard/17.jpg"
        },
        {
            "TextureName": "ULA1018",
            "Link": "https://engrossinfotech.com/shadecard/18.jpg"
        },
        {
            "TextureName": "ULA1019",
            "Link": "https://engrossinfotech.com/shadecard/19.jpg"
        },
        {
            "TextureName": "ULA1020",
            "Link": "https://engrossinfotech.com/shadecard/20.jpg"
        },
        {
            "TextureName": "ULA1021",
            "Link": "https://engrossinfotech.com/shadecard/21.jpg"
        },
        {
            "TextureName": "ULA1022",
            "Link": "https://engrossinfotech.com/shadecard/22.jpg"
        },
        {
            "TextureName": "ULA1023",
            "Link": "https://engrossinfotech.com/shadecard/23.jpg"
        },
        {
            "TextureName": "ULA1024",
            "Link": "https://engrossinfotech.com/shadecard/24.jpg"
        },
        {
            "TextureName": "ULA1025",
            "Link": "https://engrossinfotech.com/shadecard/25.jpg"
        },
        {
            "TextureName": "UM001",
            "Link": "https://engrossinfotech.com/shadecard/26.jpg"
        },
        {
            "TextureName": "UM002",
            "Link": "https://engrossinfotech.com/shadecard/27.jpg"
        },
        {
            "TextureName": "UM003",
            "Link": "https://engrossinfotech.com/shadecard/28.jpg"
        },
        {
            "TextureName": "UM004",
            "Link": "https://engrossinfotech.com/shadecard/29.jpg"
        },
        {
            "TextureName": "UM005",
            "Link": "https://engrossinfotech.com/shadecard/30.jpg"
        },
        {
            "TextureName": "UM006",
            "Link": "https://engrossinfotech.com/shadecard/31.jpg"
        },
        {
            "TextureName": "UM007",
            "Link": "https://engrossinfotech.com/shadecard/32.jpg"
        },
        {
            "TextureName": "UM008",
            "Link": "https://engrossinfotech.com/shadecard/33.jpg"
        },
        {
            "TextureName": "Grey",
            "Link": "https://engrossinfotech.com/shadecard/grey.jpeg"
        },
        {
            "TextureName": "Dark Grey",
            "Link": "https://engrossinfotech.com/shadecard/darkgrey.jpeg"
        },
        {
            "TextureName": "Ivory",
            "Link": "https://engrossinfotech.com/shadecard/ivory.jpeg"
        }

    ]
}
