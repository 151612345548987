import React, { Component } from 'react';
import axios from 'axios';
import { API_ROOT } from '../../api-config';


class ContactUsComponent extends Component {
    constructor(props) {
        window.scrollTo(0, 0);
        super(props);
        this.state = {
            fields: {},
            formErrors: {}
        };
    }

    handleFormValidation() {
        let fields = this.state.fields;
        let formErrors = {};
        let formIsValid = true;

        if (!fields["name"]) {
            formIsValid = false;
            formErrors["name"] = 'Name is Required..';
        }

        if (!fields["email"]) {
            formIsValid = false;
            formErrors["email"] = 'Email is Required..';
        }

        if (typeof fields["email"] !== "undefined") {
            var pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                formErrors["email"] = 'Please provide valid Email..';
            }
        }

        if (!fields["phone"]) {
            formIsValid = false;
            formErrors["phone"] = 'Phone Number is Required..';
        }


        if (typeof fields["phone"] !== "undefined") {
            var pattern = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;
            // var pattern = new RegExp(/^(\+\d{1,3})?\d{10}$/);
            if (!pattern.test(fields["phone"])) {
                formIsValid = false;
                formErrors["phone"] = 'Please provide valid Phone Number..';
            }
        }

        if (!fields["subject"]) {
            formIsValid = false;
            formErrors["subject"] = 'Please Write Message..'
        }

        this.setState({ formErrors: formErrors });
        return formIsValid;
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    handleFloatingInputBlur = (e) => {
        if (e.target.value != "") {
            e.target.nextElementSibling.classList.add('activeLabel');
            e.target.classList.add('activeInput');
        } else {
            e.target.nextElementSibling.classList.remove('activeLabel');
            e.target.classList.remove('activeInput');
        }
    }


    handleSubmit = (e) => {
        e.preventDefault();
        if (this.handleFormValidation()) {
            document.getElementById("btnLoader").style.display = "block";
            var formData = new FormData();
            formData.append("customerName", this.state.fields["name"]);
            formData.append("email", this.state.fields["email"]);
            formData.append("contactNo", this.state.fields["phone"]);
            formData.append("message", this.state.fields["subject"]);
            const options = {
                url: API_ROOT + 'Inquiry/InquiryUpsert',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: formData
            };
            axios(options)
                .then(function (response) {
                    if (response.data.ResultCode = "SUCCESS") {
                        document.getElementById("message").style.display = "block";

                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );

                        Array.from(document.querySelectorAll("textarea")).forEach(
                            textarea => (textarea.value = "")
                        );

                        setTimeout(() => document.getElementById("message").style.display = "none", 4000);
                        document.getElementById("btnLoader").style.display = "none";
                    }
                    //  alert(response.data.ResultCode)

                }).catch(
                    function (error) {
                        return Promise.reject(error)
                    }
                );
            this.setState({fields: {}}) // <= here  
        } else {
            // alert("form has errors");
        }
    }

    render() {

        // const{nameErr} = this.state.formErrors;

        return (
            <>



                <div>
                    <section className='contactUsMap'>
                        <iframe width="100%" height="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.9243634947975!2d-97.20535888452575!3d31.443749158066996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864f876a329c581f%3A0x9d4d0a899145f985!2s816%20Eagles%20Nest%20Dr%2C%20Hewitt%2C%20TX%2076643%2C%20USA!5e0!3m2!1sen!2sin!4v1658397624340!5m2!1sen!2sin" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </section>
                    <section className='wrapper contactUs'>
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <div className='card contactFrom'>
                                <strong>Contact Us</strong>
                                <h1>Let's Talk</h1>
                                <div className="fromRow">
                                    <div className="alert alert-success" id='message' style={{ display: 'none' }}><span style={{ display: 'flex' }}>
                                        <img src="/assets/images/success-tik.svg"></img><strong style={{ color: '#2BC155' }}>Success!</strong><span style={{ padding: '3px' }}>Message has been sent.</span>

                                    </span>
                                        <button type='button' className='close'>
                                            <span>
                                                <i className='fa fa-times'></i>
                                            </span>
                                        </button></div>
                                </div>
                                <div className="fromRow">
                                    <div className="floatingLabelInput">
                                        <input ref="name" type="text"  maxLength="50" autoComplete='off' value={this.state.fields["name"]} onBlur={this.handleFloatingInputBlur.bind(this)} onChange={this.handleChange.bind(this, "name")} id="fname" name="name" className='input' />
                                        <label>Name</label>
                                    </div>
                                    <div className="validation"><span style={{ display: 'block' }}>{this.state.formErrors["name"]}</span> </div>
                                </div>
                                <div className="fromRow">
                                    <div className="floatingLabelInput">
                                        <input ref="email" type="text" autoComplete='off' maxLength={50} value={this.state.fields["email"]} onBlur={this.handleFloatingInputBlur.bind(this)} onChange={this.handleChange.bind(this, "email")} id="email" name="email" className='input' />
                                        <label>Email</label>
                                    </div>
                                    <div className="validation"><span style={{ display: 'block' }}>{this.state.formErrors["email"]}</span> </div>
                                </div>
                                <div className="fromRow">
                                    <div className="floatingLabelInput">
                                        <input type="text" maxLength={15} autoComplete='off' className='input' value={this.state.fields["phone"]} onBlur={this.handleFloatingInputBlur.bind(this)} onChange={this.handleChange.bind(this, "phone")} id="phone" name="phone" />
                                        <label>Mobile Number</label>
                                    </div>
                                    <div className="validation"><span style={{ display: 'block' }}>{this.state.formErrors["phone"]}</span> </div>
                                </div>
                                <div className="fromRow">
                                    <div className="floatingLabelInput">
                                        <textarea id="subject" className='input' name="subject" placeholder="Enter Your Message..." value={this.state.fields["subject"]} onChange={this.handleChange.bind(this, "subject")}></textarea>
                                    </div>
                                    <div className="validation"><span style={{ display: 'block' }}>{this.state.formErrors["subject"]}</span> </div>
                                </div>

                                <div className="formButton">
                                    <div className="submitBtn">
                                        <div className="btnLoader btnContactUsLoader" id='btnLoader' style={{ display: 'none' }}>
                                            <span className="spinLoader"></span>
                                        </div>
                                        <button value="Submit" id='submit' className="btn" type="submit">Submit</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                        <div className="card contactDetail">
                            <ul>
                                <li className='address'>
                                    <label>Address</label>
                                    <p>
                                    816, Eagles Nest Dr.,<br></br> Hewitt, TX - 76643.<br></br>
                                    </p>
                                </li>
                                <li className="email">
                                    <label>Email</label>
                                    <p><a href="mailto:info@unimaple.inc">info@unimaple.inc</a></p>
                                </li>
                                <li className="phone">
                                    <label>Phone</label>
                                    <p><a href="tel:+91 98241-73668">+91 98241-73668</a></p>
                                </li>
                            </ul>
                        </div>
                    </section>

                </div>
            </>
        )
    }
}
export default ContactUsComponent;