import { ActionTypes } from '../constants/action-types';
const initialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems") || "[]"),
  error: '',
  isSuccess: false,
}
export const cartReducer = (state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.ADD_TO_CART_LOADER:
      return {
        ...state,
        error: '',
        isSuccess: false,
      };
    case ActionTypes.ADD_TO_CART:
      return {
        cartItems: payload.cartItems,
        loading: false,
        isSuccess: true
      };
    case ActionTypes.ADD_QUANTITY:
      return { cartItems: payload.cartItems };
    case ActionTypes.SUBTRACT_QUANTITY:
      return { cartItems: payload.cartItems };
    case ActionTypes.REMOVE_FROM_CART:
      return { cartItems: payload.cartItems };
    case ActionTypes.RESET_CARTITEM:
      return {
        ...initialState
      }
    case ActionTypes.ADD_TO_CART_RESET_SUCCESS:
      return { ...state, isSuccess: false }
    default:
      return state;
  }
};