import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { fetchProducts } from '../../redux/actions/productActions';
import Product from './product';
import SubHeader from '../Shared/subHeader';
import { getLastProductID } from './selector';
import { useLocation, useHistory } from 'react-router-dom'
import { IMAGE_ROOT } from '../../api-config';
import { Link } from 'react-router-dom';
// import $, { data } from 'jquery';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { groupBy } from 'lodash';
import { addToCart, AddToCartResetSuccess } from '../../redux/actions/addToCartAction';
import { AddOrRemoveFavoriteProduct } from '../../redux/actions/favoriteProductAction';
import LoginComponent from '../login/LoginComponent';
import { resources } from '../../resource';
import { toggleFilterPanel } from '../Shared/custom';

const Productdata = (
    {
        fetchProducts, addToCart,
        fetchProductPageData,
        objLastProductID,
        productDisplayCount,
        loading,
        Categoryid,
        fetchAllProductCategoryPageData, isSuccessAddToCart, AddToCartResetSuccess, isLoggedIn, AddOrRemoveFavoriteProduct, isProductAddOrRemoveToFavorites


    }) => {

    const CategoryTypeWiseData = groupBy(fetchAllProductCategoryPageData, function (n) {
        return n.CategoryTypeName;
    });
    const user = JSON.parse(localStorage.getItem('user'));
    const [openModal, setOpenModal] = useState(false);
    const [CategoryFilterCount, setCategoryFilterCount] = useState(0);
    const history = useHistory();
    const [pageSize, setPageSize] = useState(48);
    const [categoryIds, setCategoryIds] = useState([]);
    const [res, setRes] = useState([(history.location.state != undefined && history.location.state.CategoryID > 0) && true]);
    const [isNewSearchFilter, setIsNewSearchFilter] = useState(true);
    // const prevScrollY = useRef(100);
    const [goingUp, setGoingUp] = useState(false);
    // const [Categoryid,setCategoryid] = useState(" ")
    // let location = useLocation();

    // start use for categorymenu wise data
    // Categoryid = history.location.state != null ? history.location.state.CategoryID : " ";

    // setCategoryIds(Categoryid)
    // end use for categorymenu wise data

    // start reset useHistory when page reload
    // useEffect(() => {
    //     history.replace({ state: null })
    // }, []);
    window.history.replaceState(null, '')

    // End reset useHistory when page reload

    useEffect(() => {
        if (history.location.state != undefined && history.location.state.CategoryID > 0) {
            let stateCategoryId = [history.location.state.CategoryID];
            categoryIds.splice(0, categoryIds.length);
            if (!categoryIds.includes(history.location.state.CategoryID.toString())) {
                setCategoryIds([...categoryIds, history.location.state.CategoryID.toString()]);
                window.history.replaceState(null, '');
                history.replace({ state: null });
                // setCategoryIds(history.location.state.CategoryID.toString());
                fetchProducts(stateCategoryId, 0, pageSize, isNewSearchFilter, user != null ? user.UserID : null);
            }

        }
        else {
            window.scrollTo(0, 0)
            fetchProducts(categoryIds, 0, pageSize, isNewSearchFilter, user != null ? user.UserID : null);
        }
        // fetchProducts(categoryIds, 0, pageSize, isNewSearchFilter);
    }, [categoryIds, history.location.state]);

    useEffect(() => {
        const handleScroll = (e) => {
            if ((e.target.documentElement.scrollTop >= e.target.documentElement.scrollHeight - window.innerHeight - 600) && !goingUp) {
                setGoingUp(true);

                if (productDisplayCount != null && productDisplayCount < 8) {
                    return;
                }
                if (objLastProductID > 0 && loading != true) {
                    fetchProducts(categoryIds.toString(), objLastProductID, pageSize, false, user != null ? user.UserID : null);
                }

            }
            else {
                setGoingUp(false);
            }
        };
        window.addEventListener("scroll", handleScroll, { behavior: "smooth" });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [goingUp]);




    function SearchByproductCategory(e) {
        // if (history.location.state != undefined && history.location.state.CategoryID > 0) {
        //    setCategoryIds([...categoryIds, history.location.state.CategoryID.toString()]);
        //     window.history.replaceState(null, '');
        //     history.replace({ state: null });

        // }
        // window.history.replaceState(null, '');
        // history.replace({ state: null });

        setCategoryFilterCount(document.querySelectorAll('input[name=CategoryCheckbox]:checked').length)
        setIsNewSearchFilter(true);
        if (e.target.checked) {
            setCategoryIds([e.target.value]);
            // setCategoryIds([...categoryIds, e.target.value]);
        } else {
            setCategoryIds(categoryIds.filter((id) => id !== e.target.value));
        }
        if (window.innerWidth <= 768)
            toggleFilterPanel();
    }

    // function toggleFilterPanel(e) {
    //     $("body").toggleClass("lock");
    //     $(".productFilter").toggleClass("activeView");
    //     $(".responsiveFilterTabOverlay").toggle();
    // }
    const addToCartClick = (id, e) => {

        e.currentTarget.classList.add('addToCart');

        const defualtParameterValue = {};
        const result = fetchProductPageData.filter(x => x.ProductID == id);
        setTimeout(() => {
            document.getElementsByClassName('addToCart')[0].getElementsByClassName('btn-product-cart-loader')[0].style.display = 'block';
        }, 500);
        setTimeout(() => {
            addToCart(result[0], defualtParameterValue)
        }, 3000);

    }

    const makeFavourite = (ProductId) => {
        // alert(isfavorites)
        if (isLoggedIn) {
            AddOrRemoveFavoriteProduct(ProductId, user != null ? user.UserID : null);
        } else {
            setOpenModal(true);

        }
    }
    return (
        <>
            <LoginComponent trigger={openModal} setTrigger={setOpenModal} openModal></LoginComponent>
            <SubHeader CurrentPageName={'Products'} />
            {/* {
                fetchProductPageData != null ? <Product fetchProductPageData={fetchProductPageData} loading={loading} fetchAllProductCategoryPageData={fetchAllProductCategoryPageData} /> : null
            } */}
            {(() => {
                return (
                    <>
                        <section className="productListingPage">
                            <section className="wrapper">
                                <div className="responsiveFilterTab" style={{ display: "none" }}>
                                    {/* <a href="#" className="productsFilterToggleFilterPanel">Filter</a> */}
                                    <Link onClick={toggleFilterPanel}>
                                        Filter
                                    </Link>
                                </div>
                                <div className="responsiveFilterTabOverlay overlay" style={{ display: "none" }} onClick={toggleFilterPanel}></div>
                                <section className="productFilter">
                                    <div className="filterWrap">
                                        {/* <h2>Filter <Link onClick={onClearClick}>Clear</Link></h2> */}
                                        <h2>Filter</h2>

                                        <ul>
                                            {/* <li className={categoryIds.length > 0 ? "active" : ""}> */}
                                            {/* <h3 onClick={onItemClick}>Products <span>  {CategoryFilterCount == 0 ? " " : '(' + CategoryFilterCount + ')'}</span><Link ></Link></h3> */}
                                            {/* <div className={categoryIds.length > 0 ? "filterValues slideToggle" : "filterValues"}> */}
                                            {
                                                (fetchAllProductCategoryPageData != null && fetchAllProductCategoryPageData.length > 0) &&
                                                Object.entries(CategoryTypeWiseData).map(([key, data]) => {
                                                    return (
                                                        <>
                                                            <li className={categoryIds.length > 0 ? "active" : ""}>
                                                                <h3 style={{ color: '#6E0010' }}>{key}<span></span></h3>
                                                                <ul style={{ marginLeft: '35px' }}>
                                                                    <li>
                                                                        {
                                                                            (data.length >= 1) &&
                                                                            Object.entries(data).map(([key, item]) => {
                                                                                return (
                                                                                    <>
                                                                                        {/* <ul>
                                                                                            <li style={{ border: "none", marginLeft: '35px' }}> */}
                                                                                        {/* <input id={item.CategoryID} value={item.CategoryID} type="checkbox" checked={((categoryIds.includes(item.CategoryID)) || (history.location.state != undefined && history.location.state.CategoryID != " " && history.location.state.CategoryID > 0 && item.CategoryID == history.location.state.CategoryID)) ? "checked" : ""} onChange={SearchByproductCategory} /> &nbsp;&nbsp; */}
                                                                                        {/* <input id={item.CategoryID} value={item.CategoryID} type="checkbox" checked={item.CategoryID == categoryIds[0] ? "checked" : ""} onChange={SearchByproductCategory} /> &nbsp;&nbsp; */}
                                                                                        {/* <input id={item.CategoryID} value={item.CategoryID} type="checkbox" checked={item.CategoryID == categoryIds} onChange={SearchByproductCategory} /> &nbsp;&nbsp; */}
                                                                                        {/* <input id={item.CategoryID} value={item.CategoryID} type="checkbox" onChange={SearchByproductCategory} /> &nbsp;&nbsp; */}
                                                                                        <label className="checkbox">
                                                                                            <input id={item.CategoryID} value={item.CategoryID} type="checkbox" checked={(categoryIds.includes(item.CategoryID.toString())) ? "checked" : ""} onChange={SearchByproductCategory} name="CategoryCheckbox" /> &nbsp;&nbsp;
                                                                                            <span className='checkmark' for={item.CategoryID}> </span>{item.CategoryName}
                                                                                        </label>
                                                                                        {/* </li>
                                                                                        </ul> */}
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </>
                                                    )
                                                })

                                            }
                                            {/* </div> */}
                                            {/* </li> */}


                                        </ul>
                                    </div>

                                </section>

                                {/* Product List */}
                                <section className="productShowcase">
                                    {(() => {
                                        if ((fetchProductPageData != null && fetchProductPageData.length > 0) && loading != true) {
                                            return (
                                                <>
                                                    <div className="productLoader" style={{ display: "block" }}>
                                                        <div class="procommerce">
                                                            <ul class="productList products --skin-cards">
                                                                {
                                                                    (fetchProductPageData != null && fetchProductPageData.length > 0) &&
                                                                    fetchProductPageData.map((data, key) => {

                                                                        var imgpath = IMAGE_ROOT + resources.PRODUCT_IMG_URL + data.ImageName;
                                                                        return (
                                                                            <li key={Math.random()} id={key} class="product wc-skin--cards is-animated is-animated-entry --animated-in">
                                                                                <Link to={`/products/${data.Slug}`}>
                                                                                    <div class="product-card">
                                                                                        <div class="product-img">
                                                                                            {/* <img src={imgpath} data-product={key} style={{ display: "inline" }}></img> */}
                                                                                            <img
                                                                                                sizes="(max-width: 1440px) 100vw, 1440px"
                                                                                                srcset={`${IMAGE_ROOT}${resources.PRODUCT_IMG_URL}${resources.IMAGEVERSIONS.THUMBNAILIMAGERESIZER + data.ImageName} 200w,
                                                                                                ${IMAGE_ROOT}${resources.PRODUCT_IMG_URL}${resources.IMAGEVERSIONS.SMALLIMAGERESIZER + data.ImageName} 768w,
                                                                                                ${IMAGE_ROOT}${resources.PRODUCT_IMG_URL}${resources.IMAGEVERSIONS.MEDIUMIMAGERESIZER + data.ImageName} 1024w,
                                                                                                ${IMAGE_ROOT}${resources.PRODUCT_IMG_URL}${resources.IMAGEVERSIONS.LARGEIMAGERESIZER + data.ImageName} 1440w`}
                                                                                                src={`${IMAGE_ROOT}${resources.PRODUCT_IMG_URL}${data.ImageName}`}
                                                                                                alt="" data-product={key} style={{ display: "inline" }}>
                                                                                            </img>
                                                                                            <div class="favorite-wrap">
                                                                                                <div class="favorite">
                                                                                                    <Link onClick={() => { makeFavourite(data.ProductID) }}>

                                                                                                        {(() => {

                                                                                                            let favId = isProductAddOrRemoveToFavorites != null && isProductAddOrRemoveToFavorites.filter(x => x.ProductID == data.ProductID)
                                                                                                            if (favId.length > 0) {
                                                                                                                return (
                                                                                                                    favId.map(x => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                {(() => {
                                                                                                                                    if (x.ProductID == data.ProductID) {
                                                                                                                                        return (
                                                                                                                                            <img src="/assets/images/heart_fill.svg" height={20} width={20} />
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                })()}
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })
                                                                                                                )
                                                                                                            } else if (data.IsFavorites == true && isProductAddOrRemoveToFavorites == null) {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <img src="/assets/images/heart_fill.svg" height={20} width={20} />
                                                                                                                    </>
                                                                                                                )
                                                                                                            } else if (data.IsFavorites == false && isProductAddOrRemoveToFavorites == null) {
                                                                                                                return (
                                                                                                                    <img src="/assets/images/heart.svg" height={20} width={20} />
                                                                                                                )
                                                                                                            }

                                                                                                            else {
                                                                                                                return (
                                                                                                                    <img src="/assets/images/heart.svg" height={20} width={20} />
                                                                                                                )
                                                                                                            }


                                                                                                        })()}

                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="product-desc-wrap">
                                                                                        <div class="product-desc">
                                                                                            <p class="product-name">{data.ProductName}</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                                {/* <div className='productInner'>
                                                                                    <div className="thumb">
                                                                                        <Link to={`/products/${data.Slug}`}>
                                                                                            <img src={imgpath} data-product={key} style={{ display: "inline" }}></img>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="productDesc">
                                                                                        <h2 className="procommerce-loop-product__title">
                                                                                            <Link to={`/products/${data.Slug}`} className='productIntro' style={{ marginTop: '15px' }}>
                                                                                                <label title={data.ProductName}>{data.ProductName}</label>
                                                                                            </Link>
                                                                                        </h2>
                                                                                        <div class="rey-productLoop-footer">

                                                                                            <div className="rey-productFooter-item rey-productFooter-item--addtocart" id='btnRequestQuote'>
                                                                                                <div className="rey-productFooter-inner">
                                                                                                    <a onClick={(e) => { addToCartClick(data.ProductID, e) }} data-quantity="1" className="button rey-btn--under">
                                                                                                        <span class="__text product-cart-add" style={{ marginRight: '10px' }}>Add to cart</span>
                                                                                                        <span class="btn-loading">
                                                                                                            <span className="btn-product-cart-loader"></span>
                                                                                                        </span>
                                                                                                        <span class="product-cart-added">Added</span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="rey-productFooter-item rey-productFooter-item--whishlist">
                                                                                                <div className="rey-productFooter-inner">
                                                                                                    <a href="javascript:void(0);" onClick={() => { makeFavourite(data.ProductID) }}>

                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div> */}
                                                                            </li>
                                                                        )
                                                                    })

                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (fetchProductPageData != null && fetchProductPageData.length == 0) {
                                            return (
                                                <>
                                                    <div className="noRecord" style={{ display: "block" }}>
                                                        <picture>
                                                            <source srcset="/assets/images/no_search_result.webp" type="image/webp" />
                                                            <img src="/assets/images/no_search_result.png" />
                                                        </picture>
                                                        <label>No search result found!</label>
                                                        <p>We couldn't find any result.</p>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    <div className="productLoader" style={{ display: "block" }}>
                                                        <ul className="productList">

                                                            {
                                                                [...Array(8)].map((ignore, index) => {
                                                                    return (

                                                                        <li key={index}>
                                                                            <Skeleton height={295} width={329}></Skeleton>
                                                                            {/* <div style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '5px' }}>
                                                                                <Skeleton style={{ display: 'block', width: '20px', height: '20px', border: '2px solid #fff', borderRadius: '100%', boxShadow: '0.8px 3px 4px 0px rgb(0 0 0 / 36%)' }} >
                                                                                </Skeleton>
                                                                            </div> */}
                                                                            <a href="#0" className="productIntro">
                                                                                <Skeleton style={{ width: "70%" }} ></Skeleton>
                                                                            </a>
                                                                            {/* <a href="#" style={{ width: '100%' }}>
                                                                                <img src='/assets/images/product_listing_skeleton.svg' ></img>
                                                                            </a> */}
                                                                        </li>

                                                                    )
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                </section>
                            </section>
                        </section>
                    </>
                )

            })()
            }
        </>
    )
}
export default connect((state, props) => (
    {
        fetchProductPageData: state.allProductReducer.ProductReducer.allProductData != null ? state.allProductReducer.ProductReducer.allProductData.products : null,
        productDisplayCount: state.allProductReducer.ProductReducer.allProductData != null ? state.allProductReducer.ProductReducer.allProductData.productDisplayCount : null,
        objLastProductID: getLastProductID(state, props),
        loading: state.allProductReducer.ProductReducer.loading,
        fetchAllProductCategoryPageData: state.allHeader != null ? state.allHeader.Header : null,
        isSuccessAddToCart: state.cart.isSuccess,
        isLoggedIn: state.Login.isLoggedIn,
        isProductAddOrRemoveToFavorites: state.favoriteProduct.payload != null ? state.favoriteProduct.payload : null,
    }),
    { fetchProducts, addToCart, AddToCartResetSuccess, AddOrRemoveFavoriteProduct }
)(Productdata)
