import { ActionTypes } from '../constants/action-types';

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { isLoggedIn: true, user, message: null } : { isLoggedIn: false, user: null, message: null };
export const LoginReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                message: null,
            };
        case ActionTypes.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message: null,

            };
        case ActionTypes.OTP_EXPIRED_MESSAGE:
            return {
                message: payload
            };
        case ActionTypes.USER_LOGIN_STATE_RESET:
            return {
                state: undefined,
                isLoggedIn: false,
                user: null,
                message: null,

            };
        case ActionTypes.LOGOUT:
            return {
                state: undefined,
                isLoggedIn: false,
                user: null,
                message: null,
            };

        default:
            return state;
    }
}