import { ActionTypes } from "../redux/constants/action-types";

// export default function HandleAuthResponse(response, history) {
//   if (response && (response.status == 401 || response.status == 403)) {
//     logout();
//     history.push("/");
//   }
// }
export const HandleAuthResponse = (response, history) => (dispatch) => {
  if (response && (response.status == 401 || response.status == 403)) {
    localStorage.removeItem("user");
    dispatch({
      type: ActionTypes.LOGOUT,
    });
    history.push("/");
  }
};
