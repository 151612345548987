import api from '../../api-config';
import { ActionTypes } from '../constants/action-types';

export const fetchHelpsupport = (

    ) => async dispatch => {
        try{
            const resultObject = await api.get("CMS/GetCMSWeb?slug=help-support");
            dispatch({
                type: ActionTypes.FETCH_HELPSUPPORT_DATA,
                payload: resultObject.data.ResultObject
            })
        }
        catch(e) {
            dispatch({
                type: ActionTypes.FETCH_HELPSUPPORT_DATA,
                homeExceptionLogObject: []
            })
        }
    }