import { ActionTypes } from '../../constants/action-types';
const initialState = {
    loading: false,
    error: ''
}
export const ProductReducer = (state = initialState, { type, allProductData }) => {
    switch (type) {
        case ActionTypes.LOADER_START:
            return {
                ...state,
                loading: true,
                error:''
            };
        case ActionTypes.FETCH_AllPRODUCTSPAGEDATA:
            if (allProductData != undefined && allProductData.isNew == true) {
                return {
                    ...state,
                    allProductData,
                    loading: false,
                }
            }
            else {
                var res;
                if (state.allProductData && state.allProductData.products) {
                    res = state.allProductData.products.concat(allProductData.products);
                    allProductData.products = res;
                    console.log(allProductData.products + "res")
                }
                return {
                    ...state,
                    allProductData,
                    loading: false,
                }
            }
        default:
            return state;
    }
}
