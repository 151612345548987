import { ActionTypes } from "../../constants/action-types"

const initialState = {
    loading: false,
    error: ''
}
export const ProjectsReducer = (state = initialState, { type, allProjects, projectImages }) => {
    switch (type) {
        case ActionTypes.LOADER_START:
            return {
                ...state,
                loading: true
            };
        case ActionTypes.FETCH_PROJECTS:
            if (allProjects != undefined) {
                return {
                    ...state,
                    allProjects,
                    loading: false
                }
            } else {
                var res;
                if (state.allProjects && state.allProjects.projects) {
                    res = state.allProjects.projects.concat(allProjects.projects);
                    allProjects.projects = res;
                    console.log(allProjects.projects + "res")
                }
                return {
                    ...state,
                    allProjects,
                    loading: false,
                }
            }
        case ActionTypes.FETCH_PROJECTS_IMAGE:
            return {
                ...state,
                projectImages,
                loading: false
            };
        default:
            return state;
    }
}