import React, { useState, useEffect } from 'react';
import RegisterForm from './registerForm';
import validate from './registerValidateInfo';
import { AccountRegister, RegisterStateReset } from '../../redux/actions/loginAction';
import HandleFloatingInputBlur from '../Shared/custom';
import { connect, useDispatch } from "react-redux";
import { ActionTypes } from '../../redux/constants/action-types';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';


const RegisterComponet = (props) => {
    // const { handleChange, values, handleSubmit, errors,btnLoader } = RegisterForm();
    const dispatch = useDispatch();
    const initialState = {
        firstName: '',
        lastName: '',
        contactNo: '',
        email: '',
        password: '',
        confirmpassword: '',
        userName: ''
    }
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        // contactNo: '',
        email: '',
        password: '',
        confirmpassword: '',
        userName: ''
    })
    const [errors, setErrors] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const [contactNo, setContactNo] = useState();
    const [countryCode, setCountryCode] = useState("US");
    const registerValidateInfo = (values, contactNo) => {

        let errors = {};
        let formIsValid = true;
        setBtnLoader(true);
        //firstName
        if (!values.firstName.trim()) {
            errors.firstName = 'First name is required';
            formIsValid = false
        }

        //lastName
        if (!values.lastName.trim()) {
            errors.lastName = 'Last name is required';
            formIsValid = false;
        }

        //Contact No
        if (!contactNo) {
            errors.contactNo = 'Contact no is required';
            formIsValid = false;
        } else if (!/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm.test(contactNo)) {
            errors.contactNo = 'Contact no is invalid';
            formIsValid = false;
        }

        //email
        if (!values.email) {
            errors.email = 'Email is required';
            formIsValid = false;
        } else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/i.test(values.email)) {
            errors.email = 'Email address is invalid';
            formIsValid = false;
        }

        //password
        if (!values.password) {
            errors.password = 'Password is required';
            formIsValid = false;
        } else if (values.password.length < 6) {
            errors.password = 'Password needs to be 6 characters or more';
            formIsValid = false;
        }

        //confirmpassword
        if (!values.confirmpassword) {
            errors.confirmpassword = 'Confirm password is required';
            formIsValid = false;
        } else if (values.confirmpassword !== values.password) {
            errors.confirmpassword = 'Passwords do not match';
            formIsValid = false;
        }

        //userName
        if (!values.userName) {
            errors.userName = 'Username is required';
            formIsValid = false;
        } else if (values.userName.length < 4) {
            errors.userName = 'Username needs to be 4 characters or more';
            formIsValid = false;
        }
        setErrors(errors);
        setBtnLoader(false);
        return formIsValid;
    }


    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (registerValidateInfo(values, contactNo)) {
            setBtnLoader(true);
            dispatch(AccountRegister(values.userName, values.confirmpassword, contactNo, values.email, values.firstName, values.lastName, countryCode)).then(() => {
                setBtnLoader(false);
            })
        }
    }
    useEffect(() => {
        if (props.isSuccessfullyRegistere == true) {
            setValues({
                firstName: '',
                lastName: '',
                // contactNo: '',
                email: '',
                password: '',
                confirmpassword: '',
                userName: ''
            });
            setContactNo();
            // setTimeout(() => dispatch(ActionTypes.REGISTER_STATE_RESET), document.getElementById("isSuccessfullyRegistereMsg").style.display = "none", 1000);
            setTimeout(() => props.RegisterStateReset(), 2000);

        } else if (props.isSuccessfullyRegistere == false) {
            document.getElementById("isSuccessfullyRegistereMsg").style.display = "none";
        }
    }, [props.isSuccessfullyRegistere]);

    const selectElement = document.querySelector('.PhoneInputCountrySelect');
    if (selectElement != null) {

        selectElement.addEventListener('change', (e) => {
            //   upperCase();
            e.stopPropagation();
            setCountryCode(e.target.value.toString())
        });

    }

    return (props.openRegister) ? (
        <>
            <form className='form' onSubmit={handleSubmit} id="registerFrm">
                <div class="overlay" id="accessModelOverlay" style={{ display: 'block' }}></div>
                <div class="model userAccessModel" id="registerModal" style={{ display: 'block' }}>
                    <div class="modelWrapper" style={{ maxWidth: '1000px' }}>

                        <div class="accessImage changeBackground">
                            {/* <img src="../assets/images/model_chair.png" /> */}
                        </div>

                        <div class="accessForm">
                            <a href="javascript:void(0)" className="close" onClick={() => {
                                let registerModal = document.getElementById('registerModal');
                                registerModal.classList.add('modal-close')
                                setTimeout(() => {
                                    props.setOpenRegister(false);
                                }, 400);
                                // dispatch(ActionTypes.REGISTER_STATE_RESET);
                                props.RegisterStateReset();

                                let body = document.getElementsByTagName("body")[0];
                                body.classList.add("overflowauto");
                            }}></a>
                            <div id="registerFrom" style={{ display: 'block' }}>
                                <h3>Create Account</h3>
                                <p>Enter your detail to register.</p>

                                {/* <!-- Success Message --> */}
                                <div className="alert alert-success" style={{ display: props.isSuccessfullyRegistere === true ? 'block' : 'none' }} id="isSuccessfullyRegistereMsg">
                                    <img src="/assets/images/success-tik.svg" />
                                    <strong>Success! </strong>
                                      Your account successfully register!
                                    <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                </div>
                                {/* <!-- Error Message --> */}
                                {props.errMessage && (
                                    <div className="alert alert-error" style={{ display: 'block' }} id='ErrorMessage'>
                                        <img src="/assets/images/error_x.svg" />
                                        <strong>Error! </strong> 
                                          {props.errMessage}
                                        <button type="button" className="close"><span><i className="fa fa-times"></i></span></button>
                                    </div>
                                )}

                                <div class="split">

                                    <div class="fromRow">
                                        <div class="floatingLabelInput hasIcon">
                                            <input type="text" className='input' name='firstName' value={values.firstName} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                            <label>First Name</label>
                                            <i class="ico user"></i>
                                        </div>
                                        {/* <div class="validation"><span style={{ display: 'block' }}>Validation message in this span</span> </div> */}
                                        {errors.firstName && <div class="validation"><span style={{ display: 'block' }}>{errors.firstName}</span> </div>}
                                    </div>

                                    <div class="fromRow">
                                        <div class="floatingLabelInput">
                                            <input type="text" className='input' name='lastName' value={values.lastName} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                            <label>Last Name</label>
                                        </div>
                                        {/* <div class="validation"><span style={{ display: 'block' }}>Validation message in this span</span> </div> */}
                                        {errors.lastName && <div class="validation"><span style={{ display: 'block' }}>{errors.lastName}</span> </div>}
                                    </div>

                                </div>

                                <div class="fromRow">
                                    <div class="floatingLabelInput hasIcon">
                                        <input type="email" className='input' name='email' value={values.email} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                        <label>Email</label>
                                        <i class="ico email"></i>
                                    </div>
                                    {/* <div class="validation"><span style={{ display: 'block' }}>Validation message in this span</span> </div> */}
                                    {errors.email && <div class="validation"><span style={{ display: 'block' }}>{errors.email}</span> </div>}
                                </div>
                                <div class="fromRow">
                                    <div class="floatingLabelInput hasIcon">
                                        <input type="text" className='input' name='userName' value={values.userName} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                        <label>Username</label>
                                        <i class="ico user"></i>
                                    </div>
                                    {/* <div class="validation"><span style={{ display: 'block' }}>Validation message in this span</span> </div> */}
                                    {errors.userName && <div class="validation"><span style={{ display: 'block' }}>{errors.userName}</span> </div>}
                                </div>
                                <div class="fromRow">
                                    <div class="floatingLabelInput">
                                        <div className=' floatingLabelInput hasIcon'>
                                            <label className='activeLabel'>Contact No</label>
                                        </div>
                                        <PhoneInput
                                            className='input' name='contactNo'
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry={countryCode}
                                            value={contactNo} onChange={setContactNo}
                                            style={{ paddingLeft: '5px 5px !important' }}

                                        />
                                        {/* <input type="text" className='input' name='contactNo' value={values.contactNo} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} /> */}
                                        {/* <i class="ico phone"></i> */}
                                    </div>
                                    {/* <div class="validation"><span style={{ display: 'block' }}>Validation message in this span</span> </div> */}
                                    {errors.contactNo && <div class="validation"><span style={{ display: 'block' }}>{errors.contactNo}</span> </div>}
                                </div>

                                <div class="fromRow">
                                    <div class="floatingLabelInput hasIcon">
                                        <input type="password" className='input' name='password' value={values.password} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                        <label>Password</label>
                                        <i class="ico password"></i>
                                    </div>
                                    {/* <div class="validation"><span style={{ display: 'block' }}>Validation message in this span</span> </div> */}
                                    {errors.password && <div class="validation"><span style={{ display: 'block' }}>{errors.password}</span> </div>}
                                </div>

                                <div class="fromRow">
                                    <div class="floatingLabelInput hasIcon">
                                        <input type="password" className='input' name='confirmpassword' value={values.confirmpassword} onChange={handleChange} onBlur={HandleFloatingInputBlur.bind(this)} />
                                        <label>Confirm Password</label>
                                        <i class="ico password"></i>
                                    </div>
                                    {/* <div class="validation"><span style={{ display: 'block' }}>Validation message in this span</span> </div> */}
                                    {errors.confirmpassword && <div class="validation"><span style={{ display: 'block' }}>{errors.confirmpassword}</span> </div>}
                                </div>


                                <div class="formButton">
                                    <div class="submitBtn">
                                        <div class="btnLoader btnRegister" style={{ display: btnLoader === true ? 'block' : 'none' }}>
                                            <span class="spinLoader"></span>
                                        </div>
                                        {/* <input value="Register" class="btn" type="button" /> */}
                                        <button value="submit" id='sunmit' class='btn' type="submit">Submit</button>
                                    </div>
                                </div>

                                <div class="note">
                                    Already have an account? <a href="javascript:void(0);" onClick={() => {
                                        props.setOpenRegister(false);
                                        props.openLoginModal(true);
                                        
                                        let body = document.getElementsByTagName("body")[0];
                                        body.classList.add("overflowhidden");
                                    }}>Login Now</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>


        </>
    ) : ""


}
export default connect((state, props) => (
    {
        errMessage: state.register != null ? state.register.message : "",
        isSuccessfullyRegistere: state.register.isSuccessfullyRegistere
    }),
    { RegisterStateReset }
)(RegisterComponet)

// export default RegisterComponet;
