import { ActionTypes } from '../constants/action-types';
const initialState = {
    message: null,
    isSuccessfullyRegistere : false,
}
export const RegisterReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case ActionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                message: null,
                isSuccessfullyRegistere : true
            };
        case ActionTypes.REGISTER_FAILURE:
            return {
                ...state,
                message: payload,
                isSuccessfullyRegistere : false
            };
            case ActionTypes.REGISTER_STATE_RESET:
                return {
                    state : undefined,
                    message: null,
                    isSuccessfullyRegistere : false
                };
        default:
            return state;
    }
}